import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ContentBundle} from '../../content-bundle';
import {ContentElement} from '../../../campaigns/models/content-element';

@Component({
  selector: 'app-content-bundle-preview',
  templateUrl: './content-bundle-preview.component.html',
  styleUrls: ['./content-bundle-preview.component.scss']
})
export class ContentBundlePreviewComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  @Input()
  previewType: 'mail' | 'app' = 'mail';

  @Input()
  previewTypeSwitchable = true;

  @Input()
  contentBundle: ContentBundle;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
  }

  trackBy(index, item) {
    return item.id ? item.id : item.local_uuid;
  }

  contentElementsSorted(): ContentElement[] {
    return this.contentBundle.content_elements.sort((a, b) => a.position - b.position);
  }
}
