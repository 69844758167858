import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpecialPointsEventPreviewComponent} from './special-points-event-preview.component';
import {RoundPipeModule} from '../../shared/pipes/round-pipe/round-pipe.module';
import { ReferralPointsEventPreviewComponent } from './referral-points-event-preview/referral-points-event-preview.component';


@NgModule({
  declarations: [SpecialPointsEventPreviewComponent, ReferralPointsEventPreviewComponent],
  exports: [SpecialPointsEventPreviewComponent, ReferralPointsEventPreviewComponent],
  imports: [
    CommonModule,
    RoundPipeModule
  ]
})
export class SpecialPointsEventPreviewModule { }
