import {Deal} from '../deals/deal';
import {SpecialPointsEvent} from '../special-points-events/special-points-event';

export class ReferralEvent {
  public static readonly DEFAULT_CAMPAIGN_TITLE = 'Empfehle uns weiter und belohne dich mit Extrapunkten';
  public static readonly DEFAULT_CAMPAIGN_TEXT = 'Empfiehl uns weiter und belohne dich mit Extrapunkten!<br>' +
    '<br>' +
    'Wie funktioniert’s?<br>' +
    '<ol>' +
    '<li>Klicke auf den Button unten und hinterlege die E-Mail-Adresse deiner Freunde.<br>' +
    '<strong>Tipp:</strong> Frage deine Freunde vorher, welche E-Mail-Adresse ihnen am liebsten ist. Die Gleiche sollten sie auch für die Registrierung verwenden.</li>' +
    '<li>Deine Freunde erhalten deine Empfehlung für unser Geschäft</li>' +
    '<li>Deine Freunde kaufen in unserem Geschäft das erste Mal ein</li>' +
    '<li>Ihr erhaltet Extrapunkte auf euer Punktekonto</li>' +
    '</ol>' +
    'Mehr Infos zu den Bedingungen der Punktevergabe unter:<br>' +
    'https://mankido.de/kundenwerbenkunden';

  public id: number;
  public blacklisted_business_ids: number[];
  public deal: Deal;
  public special_points_event: SpecialPointsEvent;
  public inviter_special_points_event: SpecialPointsEvent;
  public invitee_special_points_event: SpecialPointsEvent;

  constructor(v: ReferralEvent) {
    this.id = v.id;
    this.blacklisted_business_ids = v.blacklisted_business_ids;
    if (v.special_points_event) {
      this.special_points_event = new SpecialPointsEvent(v.special_points_event);
    }
    if (v.inviter_special_points_event) {
      this.inviter_special_points_event = new SpecialPointsEvent(v.inviter_special_points_event);
    }
    if (v.invitee_special_points_event) {
      this.invitee_special_points_event = new SpecialPointsEvent(v.invitee_special_points_event);
    }
    if (v.deal) {
      this.deal = new Deal(v.deal);
    }
  }
}
