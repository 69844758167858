import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'round'})
export class RoundPipe implements PipeTransform {

  constructor() {
  }

  transform(value: any): string {
    return Math.round(value).toString();
  }

}
