import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpecialPointsEventListComponent} from './special-points-event-list.component';
import {HeadcontentModule} from '../../shared/head-content/headcontent.module';
import {CardsModule} from '../../shared/cards/cards.module';
import {SpecialPointsEventPreviewModule} from '../special-points-event-preview/special-points-event-preview.module';
import {UiSwitchModule} from 'ngx-ui-switch';
import {RouterModule} from '@angular/router';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    SpecialPointsEventListComponent
  ],
  exports: [
    SpecialPointsEventListComponent
  ],
  imports: [
    CommonModule,
    HeadcontentModule,
    CardsModule,
    SpecialPointsEventPreviewModule,
    UiSwitchModule,
    RouterModule,
    NgbDropdownModule
  ]
})
export class SpecialPointsEventListModule {
}
