import {ThirdPartyBusiness} from '../third-party-access/models/third-party-business';

export class ObtainReviewEvent {
  id: number;
  new_review_url: string;
  third_party_businesses: ThirdPartyBusiness[];

  public constructor(o: Partial<ObtainReviewEvent> = {}) {
    this.id = o.id;
    this.new_review_url = o.new_review_url;

    if (o.third_party_businesses) {
      this.third_party_businesses = o.third_party_businesses.map(tpb => new ThirdPartyBusiness(tpb));
    }
  }
}
