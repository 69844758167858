import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpecialPointsEventEditorComponent} from './special-points-event-editor.component';
import {FormsModule} from '@angular/forms';
import {NgbButtonsModule} from '@ng-bootstrap/ng-bootstrap';
import {DateTimePickerModule} from '../../shared/date-time-picker/date-time-picker.module';
import {RecurringEventPickerModule} from '../recurring-event-picker/recurring-event-picker.module';


@NgModule({
  declarations: [SpecialPointsEventEditorComponent],
  exports: [SpecialPointsEventEditorComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbButtonsModule,
    DateTimePickerModule,
    RecurringEventPickerModule
  ]
})
export class SpecialPointsEventEditorModule {
}
