import {Injectable} from '@angular/core';

export enum BetaFeatureKeys {
  ThirdPartyAccess = 'third-party-access',
  Referral = 'referral'
}

@Injectable({
  providedIn: 'root'
})
export class BetaService {

  beta;
  features: BetaFeatureKeys[] = [];

  constructor() {
    this.beta = localStorage.getItem('beta') === 'true';
    const betaFeatures = localStorage.getItem('beta.features');
    if (betaFeatures) {
      this.features = betaFeatures.split(',') as BetaFeatureKeys[];
    }
  }

  public isBeta() {
    return this.beta;
  }

  public enableBeta() {
    this.beta = true;
    localStorage.setItem('beta', 'true');
  }

  public disableBeta() {
    this.beta = false;
    localStorage.setItem('beta', 'false');
  }

  public isFeatureEnabled(key: BetaFeatureKeys): boolean {
    return this.features.includes(key);
  }

  public changeFeatureStatus(key: BetaFeatureKeys, enable: boolean) {
    if (!this.features.includes(key) && enable) {
      this.features.push(key);
    }
    if (this.features.includes(key) && !enable) {
      this.features.splice(this.features.indexOf(key), 1);
    }
    localStorage.setItem('beta.features', this.features.join(','));
  }
}
