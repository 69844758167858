import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {NotificationsService} from '../../shared/notifications/notifications.service';
import {MerchantService} from '../../merchant/merchant.service';

@Component({
  selector: 'logout',
  template: ''
})
export class Logout {
  constructor(private router: Router, private merchantService: MerchantService, private auth: AuthService, private notifications: NotificationsService) {
    this.router.navigate(['/login']).then(success => {
      this.auth.logout();
      this.notifications.success('Erfolgreich abgemeldet.');
    });
  }
}
