import {Component, OnDestroy, OnInit} from '@angular/core';
import {Partner} from '../../partner/partner';
import {PartnerService} from '../../partner/partner.service';
import {Merchant} from '../../merchant/merchant';
import {MerchantService} from '../../merchant/merchant.service';
import {BetaService} from '../../beta/beta.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  partner: Partner;
  merchant: Merchant;
  context: any = {};
  title: string;

  available_contexts = [];
  webshops = [];
  navbarCollapsed = true;

  subs = new Subscription();

  constructor(public router: Router,
              public partnerService: PartnerService,
              public merchantService: MerchantService,
              public betaService: BetaService) {
  }

  ngOnInit(): void {
    this.subs.add(
      this.partnerService.currentPartner.subscribe(partner => {
        this.partner = partner;
      })
    );
    this.subs.add(
      this.merchantService.currentMerchant.subscribe(merchant => {
        this.merchant = merchant;
        this.available_contexts = this.merchant.available_contexts.filter(x => !x.is_webshop);
        this.webshops = this.merchant.available_contexts.filter(x => x.is_webshop);
      })
    );
    this.subs.add(
      this.merchantService.currentContext.subscribe(context => {
        this.context = context;
      })
    );
  }

  changeContext(context) {
    this.merchantService.setCurrentContext(context);
    const url = this.router.url;

    if (url.startsWith('/loyalty/appearance')) {
      this.router.navigate(['/loyalty/appearance']);
    } else if (url.startsWith('/loyalty/rewards')) {
      this.router.navigate(['/loyalty/rewards']);
    } else if (url.startsWith('/loyalty')) {
      this.router.navigate(['/loyalty']);
    } else if (url.startsWith('/campaigns/new')) {
      this.router.navigate(['/campaigns/new']);
    } else if (url.startsWith('/campaigns')) {
      this.router.navigate(['/campaigns']);
    } else {
      this.router.navigate((['/dashboard']));
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

