import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrizeEditorComponent} from './prize-editor.component';
import {WheelOfLuckModule} from '../../wheel-of-luck/wheel-of-luck.module';
import {FormsModule} from '@angular/forms';
import {NgbButtonsModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [PrizeEditorComponent],
  exports: [PrizeEditorComponent],
  imports: [
    CommonModule,
    WheelOfLuckModule,
    FormsModule,
    NgbButtonsModule
  ]
})
export class PrizeEditorModule { }
