<ng-container *ngIf="type != types.special_points_event">
  <ng-container *ngIf="!present">
    <div class="d-flex justify-content-around">
      <button class="btn btn-primary" [routerLink]="'/pos_experiences/' + type">Jetzt anlegen</button>
    </div>
  </ng-container>
  <app-status-switch [present]="true" [active]="active" [link]="'/pos_experiences/' + type" [disabled]="false" (change)="onChange($event)" *ngIf="present"></app-status-switch>
</ng-container>

<ng-container *ngIf="type == types.special_points_event">
  <div class="d-flex justify-content-between align-items-center" *ngIf="active_count > 0 || draft_count > 0">
    <span class="text-muted">{{active_count}} Aktiv</span>
    <button class="btn btn-primary" routerLink="/pos_experiences/special_points_event">Verwalten</button>
  </div>
  <div class="d-flex justify-content-around" *ngIf="active_count == 0 && draft_count == 0">
    <button class="btn btn-primary" routerLink="/pos_experiences/special_points_event">Jetzt anlegen</button>
  </div>
</ng-container>
