import {BehaviorSubject, Observable, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {Campaign} from '../models/campaign';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  CAMPAIGNS_URL: string = environment.api_base_url + 'campaigns';

  constructor(private http: HttpClient) {
  }

  fetchAll(page = 1): Observable<{ sent: Campaign[], drafts: Campaign[], total_sent: number }> {
    const params = new HttpParams().set('page', page.toString());
    return this.http.get(`${this.CAMPAIGNS_URL}`, {params})
      .pipe(
        map((data: { sent: Campaign[], drafts: Campaign[], total_sent: number }) => {
          data.sent = data.sent.map(campaign => new Campaign(campaign));
          data.drafts = data.drafts.map(campaign => new Campaign(campaign));
          return data;
        }),
        catchError(this.handleError)
      );
  }

  fetch(id: number): Observable<Campaign> {
    return this.http.get(`${this.CAMPAIGNS_URL}/${id}`)
      .pipe(
        map(res => new Campaign(res)),
        catchError(this.handleError)
      );
  }

  create(campaign: Campaign) {
    return this.http.post(`${this.CAMPAIGNS_URL}.json`, { campaign: campaign.asRequest()})
      .pipe(
        map(res => new Campaign(res)),
        catchError(this.handleError)
      );
  }

  update(campaign: Campaign) {
    return this.http.put(`${this.CAMPAIGNS_URL}/${campaign.id}.json`, { campaign: campaign.asRequest()})
      .pipe(
        map(res => new Campaign(res)),
        catchError(this.handleError)
      );
  }

  destroy(campaign: Campaign) {
    return this.http.delete(`${this.CAMPAIGNS_URL}/${campaign.id}.json`).pipe(
      catchError(this.handleError)
    );
  }

  copy(campaign: Campaign) {
    return this.http.post(`${this.CAMPAIGNS_URL}/${campaign.id}/copy.json`, {})
      .pipe(
        map(res => new Campaign(res)),
        catchError(this.handleError)
      );
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return observableThrowError(error);
  }

}
