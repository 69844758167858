export class PosExperienceStatus {
  wheel_of_luck: { present: boolean, active: boolean };
  advent_calendar: { present: boolean, active: boolean };
  special_points_event: { active_count: number, draft_count: number };

  constructor(data: Partial<PosExperienceStatus>) {
    this.wheel_of_luck = data.wheel_of_luck;
    this.advent_calendar = data.advent_calendar;
    this.special_points_event = data.special_points_event;
  }
}
