import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScrollShadowDirective} from './scroll-shadow.directive';

@NgModule({
  declarations: [ScrollShadowDirective],
  exports: [ScrollShadowDirective],
  imports: [
    CommonModule
  ]
})
export class ScrollShadowModule {
}
