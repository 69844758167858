import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeadContentComponent} from './head-content.component';


@NgModule({
  declarations: [HeadContentComponent],
  exports: [HeadContentComponent],
  imports: [
    CommonModule
  ]
})
export class HeadcontentModule {
}
