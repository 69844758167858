import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CurrencyInputComponent} from './currency-input.directive';
import {FormsModule} from '@angular/forms';


@NgModule({
  declarations: [CurrencyInputComponent],
  exports: [CurrencyInputComponent],
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class CurrencyInputModule {
}
