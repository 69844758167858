<div class="spacer"></div>
<nav class="navbar fixed-bottom flex-row justify-content-between" *ngIf="campaign">
  <div class="mr-0 mr-md-auto">
    <button class="btn btn-block btn-primary" [disabled]="!previousEnabled()" (click)="goToPrevious()">
      <i class="fa fa-arrow-left"></i>
      Zurück
    </button>
  </div>
  <div class="nav mx-auto d-none d-md-flex">
    <div class="nav-link" [class.active]="state === 'target'" (click)="goToTarget()" *ngIf="campaign?.obtain_review_event == null">
      Zielgruppe
    </div>
    <div class="nav-link" [class.active]="state === 'event'" (click)="goToEvent()" *ngIf="campaign?.special_points_event != null">
      Event
    </div>
    <div class="nav-link" [class.active]="state === 'content'" (click)="goToContent()">
      Inhalt
    </div>
    <div class="nav-link" [class.active]="state === 'verify'" (click)="goToVerify()">
      Prüfen
    </div>
  </div>
  <div class="ml-0 ml-md-auto d-flex flex-grow-1 flex-md-grow-0">
    <button class="btn btn-link font-weight-bold flex-grow-1" (click)="saveAndExit()" [disabled]="state === 'verify' && (!nextEnabled() || campaign.status === 'pristine')">
      Speichern <span class="d-none d-md-inline">& Schließen</span>
    </button>
    <button class="btn btn-block btn-primary flex-grow-0" style="max-width: 125px" [disabled]="!nextEnabled()" (click)="goToNext()" *ngIf="state !== 'verify'">
      Weiter
      <i class="fa fa-arrow-right"></i>
    </button>
    <button class="btn btn-block btn-success flex-grow-0 flex-shrink-1" style="max-width: 125px" [disabled]="!nextEnabled() || state === 'verify' && campaign?.status === 'pristine'" *ngIf="state === 'verify'" (click)="send()">
      <ng-container  *ngIf="!campaign || campaign?.status === 'draft'">
        Senden
        <i class="fa fa-paper-plane"></i>
      </ng-container>
      <ng-container  *ngIf="campaign?.status === 'pristine'">
        Wird gesendet
        <i class="fa fa-spinner fa-spin"></i>
      </ng-container>
    </button>
  </div>
</nav>
