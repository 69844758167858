import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HelpCardComponent} from './help-card.component';


@NgModule({
  declarations: [HelpCardComponent],
  exports: [HelpCardComponent],
  imports: [
    CommonModule
  ]
})
export class HelpCardModule {
}
