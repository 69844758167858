<div class="row" *ngIf="previewTypeSwitchable">
  <div class="col-md-3"></div>
  <div class="col-md-6 text-center">
    <p class="preview-menu">
      <button><span [class.active]="previewType == 'mail'" (click)="previewType='mail'">email</span></button>
      |
      <button><span [class.active]="previewType == 'app'" (click)="previewType='app'">mobile</span></button>
    </p>
  </div>
</div>


<div class="wrap" [class.mail-container]="previewType == 'mail'" [class.app-container]="previewType == 'app'">
  <div class="content" [class.mail]="previewType == 'mail'" [class.app]="previewType == 'app'">
    <div *ngIf="previewType === 'mail'" class="full-width-element">
      <div style="width: 100%; height: 15px"></div>
      <h3 class="partner-name">
        <img [src]="partner?.logo" alt="" class="rounded-circle mx-1" style="width: 40px; height: 40px; border: 1px solid #ddd;">
        {{partner?.name}}
      </h3>
      <hr>
    </div>

    <ng-content></ng-content>
  </div>
</div>
