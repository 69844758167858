import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UploaderPlusComponent} from './uploader-plus.component';
import {DndUploaderModule} from '../dnd-uploader/dnd-uploader.module';
import {ImageContainerModule} from '../image-container/image-container.module';


@NgModule({
  declarations: [UploaderPlusComponent],
  exports: [UploaderPlusComponent],
  imports: [
    CommonModule,
    DndUploaderModule,
    ImageContainerModule
  ]
})
export class UploaderPlusModule {
}
