import {RecurringEvent} from './recurring-event';
import {Deal} from '../deals/deal';
import {Partner} from '../partner/partner';

export class SpecialPointsEvent {
  id: number;
  partner_id: number;
  business_id: number;
  type: 'points_multiplier_event' | 'extra_points_event' | 'referral_points_event';
  requires_reservation: boolean;
  multi_usable: boolean;
  multiplier: number;
  extra_points: number;
  extra_points_threshold: number;
  status: 'draft' | 'active';
  partner: Pick<Partner, 'logo'>;

  recurring_events: Partial<RecurringEvent>[];
  deal: Deal;

  constructor(v: Partial<SpecialPointsEvent> = {}) {
    this.id = v.id;
    this.partner_id = v.partner_id;
    this.business_id = v.business_id;
    this.type = v.type;
    this.requires_reservation = v.requires_reservation;
    this.multi_usable = v.multi_usable;
    this.multiplier = v.multiplier;
    this.extra_points = v.extra_points;
    this.extra_points_threshold = v.extra_points_threshold;
    this.status = v.status;

    if (v.recurring_events != null) {
      this.recurring_events = v.recurring_events.map((e) => new RecurringEvent(e));
    }
    if (v.deal != null) {
      this.deal = new Deal(v.deal);
    }
  }

  valid(): boolean {
    return (this.extra_points != null || this.multiplier != null)
      && this.requires_reservation != null
      && !!this.recurring_events && this.recurring_events.length > 0
      && this.recurring_events[0].starts_at.getTime() < this.recurring_events[0].ends_at.getTime()
      && (this.recurring_events[0].rule !== 'singular' ||
        (this.recurring_events[0].starts_at.getTime() > new Date().getTime()
        && this.recurring_events[0].ends_at.getTime() > new Date().getTime()));
  }

  get active(): boolean {
    return this.status === 'active';
  }

  set active(isActive: boolean) {
    if (isActive) {
      this.status = 'active';
    } else {
      this.status = 'draft';
    }
  }

  public asRequest(): Partial<SpecialPointsEvent> | any {
    return {
      id: this.id,
      type: this.type,
      extra_points: this.extra_points,
      extra_points_threshold: this.extra_points_threshold,
      multiplier: this.multiplier,
      multi_usable: this.multi_usable,
      requires_reservation: this.requires_reservation,
      recurring_events_attributes: this.recurring_events.map(re => re.asRequest()),
    };
  }
}
