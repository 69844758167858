import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PosExperiencesRoutingModule} from './pos-experiences-routing.module';
import {PosExperiencesComponent} from './pos-experiences.component';
import {PosExperienceEditComponent} from './pos-experience-edit/pos-experience-edit.component';
import {CardsModule} from '../shared/cards/cards.module';
import {AdventCalendarModule} from './pos-experience-edit/advent-calendar/advent-calendar.module';
import {SpecialPointsEventListModule} from '../special-points-events/special-points-event-list/special-points-event-list.module';
import {WheelOfLuckEditorModule} from '../wheel-of-luck-editor/wheel-of-luck-editor.module';
import {SpecialPointsEventEditorModule} from '../special-points-events/special-points-event-editor/special-points-event-editor.module';
import {WheelOfLuckInfoModule} from '../wheel-of-luck-editor/wheel-of-luck-info/wheel-of-luck-info.module';
import {PosExperienceSwitchComponent} from './pos-experience-switch/pos-experience-switch.component';
import {StatusSwitchModule} from '../shared/status-switch/status-switch.module';
import {AdventCalendarPromotionComponent} from './advent-calendar-promotion/advent-calendar-promotion.component';
import {ObtainReviewModule} from '../obtain-review/obtain-review.module';
import {ReferralModule} from '../referral/referral.module';


@NgModule({
  declarations: [
    PosExperiencesComponent,
    PosExperienceEditComponent,
    PosExperienceSwitchComponent,
    AdventCalendarPromotionComponent
  ],
  exports: [
    AdventCalendarPromotionComponent
  ],
  imports: [
    CommonModule,
    PosExperiencesRoutingModule,
    CardsModule,
    AdventCalendarModule,
    SpecialPointsEventListModule,
    WheelOfLuckEditorModule,
    SpecialPointsEventEditorModule,
    WheelOfLuckInfoModule,
    StatusSwitchModule,
    ObtainReviewModule,
    ReferralModule
  ]
})
export class PosExperiencesModule {
}
