import {Easings} from './easings';

export class PreviewAnimation {

  public static createTurnAnimations(pieceIndices: number[], wheelAngle) {

    let rotationDistance = 360;
    for (const index of pieceIndices) {
      const currentDistance = (360 - (wheelAngle % 360) + (8 - index) * 45) % 360;
      if (currentDistance < rotationDistance) {
        rotationDistance = currentDistance;
      }
    }

    return [
      {duration: 0.5 + (rotationDistance / 360) * 1 , distance: rotationDistance, easing: Easings.easeOutCubic}
    ];
  }
}
