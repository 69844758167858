import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddonPromotionComponent } from './addon-promotion/addon-promotion.component';
import {ObtainReviewModule} from '../obtain-review/obtain-review.module';
import { AddonUnsubscribeComponent } from './addon-unsubscribe/addon-unsubscribe.component';
import { AddonPromotionRecurringComponent } from './addon-promotion-recurring/addon-promotion-recurring.component';
import { IfSubscribedToDirective } from './if-subscribed-to.directive';
import {ReferralModule} from '../referral/referral.module';



@NgModule({
  declarations: [AddonPromotionComponent, AddonUnsubscribeComponent, AddonPromotionRecurringComponent, IfSubscribedToDirective],
  imports: [
    CommonModule,
    ObtainReviewModule,
    ReferralModule
  ],
    exports: [AddonPromotionComponent, AddonUnsubscribeComponent, AddonPromotionRecurringComponent, IfSubscribedToDirective]
})
export class AddonsModule { }
