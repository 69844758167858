import {SpecialPointsEvent} from './special-points-event';
import {RecurringEventFactory} from './recurring-event-factory';
import {RecurringEvent} from './recurring-event';

export class SpecialPointsEventFactory {
  public static buildForCampaign(): SpecialPointsEvent {
    return new SpecialPointsEvent({
      type: 'extra_points_event',
      extra_points: 25,
      multiplier: 1.25,
      multi_usable: true,
      requires_reservation: false,
      recurring_events: [RecurringEventFactory.buildSingularRule()]
    });
  }

  public static buildForPosExperience(): SpecialPointsEvent {
    return new SpecialPointsEvent({
      type: 'points_multiplier_event',
      multiplier: 2,
      requires_reservation: false,
      multi_usable: true,
      status: 'draft',
      extra_points: 25,
      extra_points_threshold: 0,
      recurring_events: [RecurringEventFactory.buildDailyRule()]
    });
  }
}
