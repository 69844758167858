import {Component, Input} from '@angular/core';
import {AudienceDefinition} from '../../../../models/audience-definition';
import {Business} from '../../../../../loyalty/businesses/business';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-campaign-summary',
  templateUrl: './campaign-summary.component.html',
  styleUrls: ['./campaign-summary.component.scss']
})
export class CampaignSummaryComponent {
  @Input() businesses: Business[] = [];
  @Input() audienceDefinition: AudienceDefinition = {} as any;

  datePipe: DatePipe = new DatePipe('de-DE');

  constructor() {
  }

  isAdvancedMode(): boolean {
    if (!this.audienceDefinition) {
      return false;
    }
    return this.audienceDefinition.audience_type === 'advanced';
  }

  get targetName(): string {
    if (!this.audienceDefinition) {
      return null;
    }
    if (this.audienceDefinition.business_ids == null || this.audienceDefinition.business_ids.length === 0) {
      return 'Alle Filialen';
    } else {
      const targetNames = [];
      for (const business of this.businesses) {
        if (this.audienceDefinition.business_ids.includes(business.id)) {
          targetNames.push(business.name);
        }
      }
      return targetNames.join(', ');
    }
  }

  get segmentName(): string {
    if (!this.audienceDefinition) {
      return null;
    }
    return AudienceDefinition.segments[this.audienceDefinition.segment];
  }

  get frequencyRange(): string {
    if (!this.audienceDefinition) {
      return null;
    }
    return `${this.audienceDefinition.purchase_count_total_min} - ${this.audienceDefinition.purchase_count_total_max}`;
  }

  get volumeRange(): string {
    if (!this.audienceDefinition) {
      return null;
    }
    return `${Math.round(this.audienceDefinition.purchase_volume_sum_total_min / 100)} - ${Math.round(this.audienceDefinition.purchase_volume_sum_total_max / 100)} Euro`;
  }

  get membershipCreatedRange(): string {
    if (!this.audienceDefinition) {
      return null;
    }
    return `${this.datePipe.transform(this.audienceDefinition.membership_created_at_after, 'fullDate')} - ${this.datePipe.transform(this.audienceDefinition.membership_created_at_before, 'fullDate')}`;
  }

  get audienceSize(): string {
    if (!this.audienceDefinition) {
      return null;
    }
    return this.audienceDefinition.size.toString();
  }
}
