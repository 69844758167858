import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Deal} from '../../../../deals/deal';

@Pipe({
  name: 'dealDuration'
})
export class DealDurationPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {
  }

  transform(value: Deal): string {
    if (!value.ends_at && !value.duration_in_days) {
      return '';
    }

    if (value.duration_in_days) {
      return `${value.duration_in_days} Tage gültig`;
    }

    if (this.isSingleDay(value.starts_at, value.ends_at)) {
      if (this.hasRelevantStartDay(value.starts_at)) {
        return `Gültig am ${this.datePipe.transform(value.starts_at, 'EEEE, d. MMM yyyy')}\n`
          + `von ${this.datePipe.transform(value.starts_at, 'H:mm')} bis ${this.datePipe.transform(value.ends_at, 'H:mm')}`;
      } else {
        return `Gültig Heute bis ${this.datePipe.transform(value.ends_at, 'H:mm')}`;
      }
    }

    if (this.hasRelevantStartDay(value.starts_at)) {
      return `Gültig ab ${this.datePipe.transform(value.starts_at, 'EEEE, d. MMM yyyy')}\n`
        + `bis ${this.datePipe.transform(value.ends_at, 'EEEE, d. MMM yyyy')}`;
    } else {
      return `Gültig bis ${this.datePipe.transform(value.ends_at, 'EEEE, d. MMM yyyy')}`;
    }
  }


  private isSingleDay(start: Date, end: Date) {
    return start.getFullYear() === end.getFullYear()
      && start.getMonth() === end.getMonth()
      && start.getDate() === end.getDate();
  }

  hasRelevantStartDay(start) {
    return start.getTime() > new Date().getTime();
  }
}
