import {WheelConfiguration} from '../wheel-of-luck/models/wheel-configuration';
import {SpecialPointsEvent} from '../special-points-events/special-points-event';
import {Reward} from '../loyalty/rewards/reward';
import {PrizeLevel} from '../wheel-of-luck/models/prize';
import {RecurringEvent} from '../special-points-events/recurring-event';

export class PosExperience {
  id: number;
  type: string;
  state: 'active' | 'inactive';
  config: any;
  relations: PosExperienceRelation[];
  recurring_events: RecurringEvent[];

  constructor(values: any = {}) {
    this.id = values.id ? values.id : undefined;
    this.type = values.type ? values.type : PosExperienceType.advent_calendar;
    this.state = values.state;
    this.relations = values.relations ? values.relations : [];
    this.config = values.config != null ? values.config : this.setDefaultConfig();

    if (values.recurring_events != null) {
      this.recurring_events = values.recurring_events.map((e) => new RecurringEvent(e));
    }

    if (this.type === PosExperienceType.advent_calendar) {
      const xmas_start = new Date(new Date().getFullYear(), 11, 1, 0, 0, 0);
      const xmas_end = new Date(new Date().getFullYear(), 11, 24, 24, 0, 0);

      if (values.recurring_events == null || values.recurring_events.length === 0) {
        this.recurring_events = [
          new RecurringEvent({rule: 'singular', starts_at: xmas_start, ends_at: xmas_end})
        ];
      } else {
        this.recurring_events[0].starts_at = xmas_start;
        this.recurring_events[0].ends_at = xmas_end;
      }
    }
  }

  valid() {
    const enabledRecurringEvents = this.recurring_events.filter(re => !re._destroy);
    if (enabledRecurringEvents.length === 0 && this.state === 'active') {
      return false;
    }
    if (this.type === PosExperienceType.wheel_of_luck) {
      for (const level of ['jackpot', 'second', 'third', 'consolation'] as PrizeLevel[]) {
        if (this.config[level].type === 'extra_points_event') {
          const rel = WheelConfiguration.getExtraPointsEvent(level, this.relations);
          if (!(rel && (rel.object as SpecialPointsEvent).extra_points && (rel.object as SpecialPointsEvent).extra_points > 0)) {
            return false;
          }
        }
        if (this.config[level].type === 'points_multiplier_event') {
          const rel = WheelConfiguration.getPointsMultiplierEvent(level, this.relations);
          if (!(rel && (rel.object as SpecialPointsEvent).multiplier && (rel.object as SpecialPointsEvent).multiplier > 0)) {
            return false;
          }
        }
        if (this.config[level].type === 'reward') {
          const rel = WheelConfiguration.getReward(level, this.relations);
          if (!(rel && (rel.object as Reward).title && (rel.object as Reward).title.length > 0)) {
            return false;
          }
        }
      }
    }
    return true;
  }

  private setDefaultConfig() {
    switch (this.type) {
      case PosExperienceType.advent_calendar: {
        const a = [];
        for (let i = 1; i <= 24; i++) {
          a.push(new AdventCalendarConfig());
        }
        return a;
      }
      case PosExperienceType.wheel_of_luck: {
        this.recurring_events = [new RecurringEvent({rule: 'permanent', starts_at: new Date(), ends_at: null})];
        this.relations = [
          new PosExperienceRelation({relation_hint: 'jackpot', object_type: 'Reward', object: {title: ''}}),
          new PosExperienceRelation({
            relation_hint: 'second',
            object_type: 'SpecialPointsEvent',
            object: {type: 'points_multiplier_event', multiplier: 3}
          }),
          new PosExperienceRelation({
            relation_hint: 'third',
            object_type: 'SpecialPointsEvent',
            object: {type: 'extra_points_event', extra_points: 50}
          }),
          new PosExperienceRelation({
            relation_hint: 'consolation',
            object_type: 'SpecialPointsEvent',
            object: {type: 'extra_points_event', extra_points: 10}
          })
        ];
        return new WheelConfiguration({
          jackpot: {level: 'jackpot', type: 'reward', odds: 0.5},
          second: {level: 'second', type: 'points_multiplier_event', odds: 2},
          third: {level: 'third', type: 'extra_points_event', odds: 5},
          consolation: {level: 'consolation', type: 'extra_points_event', odds: 92.5},
          theme: 'light-theme'
        });
      }
    }
  }
}

export class AdventCalendar extends PosExperience {
  config: AdventCalendarConfig[];
}

class AdventCalendarConfig {
  name: string;
  surprise_type: SurpriseType = SurpriseType.points;
  text: string;
  points: number;
}

export enum PosExperienceType {
  advent_calendar = 'advent_calendar',
  wheel_of_luck = 'wheel_of_luck',
  special_points_event = 'special_points_event'
}

enum SurpriseType {
  free_text = 'free_text',
  points = 'points',
}

export class PosExperienceRelation {
  relation_hint: string;
  object_type: 'SpecialPointsEvent' | 'Reward';
  object: SpecialPointsEvent | Reward;

  constructor(values: any = {}) {
    Object.assign(this, values);
    if (this.object_type === 'Reward') {
      this.object = new Reward(values.object);
    } else if (this.object_type === 'SpecialPointsEvent') {
      this.object = new SpecialPointsEvent(values.object);
    }
  }
}
