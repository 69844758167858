import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import {NeedleAnimator} from './needle-animator';
import {Animation} from '../models/animation';
import {Prize} from '../models/prize';
import {PosExperience} from '../../pos-experiences/pos_experience';


@Component({
  selector: 'app-wheel-of-luck',
  templateUrl: './wheel-of-luck.component.html',
  styleUrls: ['./wheel-of-luck.component.scss']
})
export class WheelOfLuckComponent implements OnChanges, AfterViewInit {

  @ViewChild('needleRotation', {static: true}) needle: ElementRef;
  @ViewChild('wheelRotation', {static: true}) wheel: ElementRef;

  @Input() posExperience: PosExperience;
  @Input() logo: string;

  wheelAngle = 0;

  @Input()
  set animations(animations: Animation[]) {
    this._animations = animations;

    this.startAnimations();
  }

  @Output()
  animationFinished = new EventEmitter<{ wheelAngle: number, needleAngle: number }>();

  @Output()
  onPrizeClick = new EventEmitter<Prize>();

  _animations: Animation[];

  animationStartAngle: number;
  startOfAnimation: number;

  needleAnimator: NeedleAnimator = new NeedleAnimator();

  constructor(private cdr: ChangeDetectorRef, private ngZone: NgZone) {
  }

  ngOnChanges(): void {
  }

  ngAfterViewInit() {
    this.setAngles(this.wheelAngle);
  }

  public getWheelAngle(): number {
    return this.wheelAngle;
  }

  private startAnimations() {
    if (this._animations == null || this._animations.length === 0) {
      return;
    }
    this.animationStartAngle = this.wheelAngle;
    this.startOfAnimation = null;

    this.ngZone.runOutsideAngular(() => {
      window.requestAnimationFrame((timestamp) => this.animationLoop(timestamp));
    });
  }

  animationLoop(timestamp) {
    if (this.startOfAnimation === null) {
      this.startOfAnimation = timestamp;
      this.cdr.detach();
    }

    const elapsedTime = (timestamp - this.startOfAnimation) / 1000;
    let currentAnimation: Animation = null;
    if (this._animations != null && this._animations.length > 0) {
      currentAnimation = this._animations[0];
    }

    if (currentAnimation === null) { // no animation left
      this.cdr.reattach();
      this.ngZone.run(() => {
        this.animationFinished.next({wheelAngle: this.wheelAngle, needleAngle: this.needleAnimator.getNeedleAngle()});
      });
      return;
    }

    if (currentAnimation.duration < elapsedTime) { // go to next animation
      this.wheelAngle = this.animationStartAngle + currentAnimation.distance;
      this.startOfAnimation = this.startOfAnimation + currentAnimation.duration * 1000;
      this.animationStartAngle += currentAnimation.distance;
      if (currentAnimation.loop == null || !currentAnimation.loop) {
        this._animations = this._animations.slice(1);
      }
      return this.animationLoop(timestamp);
    }

    const progress = elapsedTime / currentAnimation.duration;

    this.wheelAngle = this.animationStartAngle + currentAnimation.easing(progress) * currentAnimation.distance;
    this.setAngles(this.wheelAngle);

    window.requestAnimationFrame((timestamp) => this.animationLoop(timestamp));
  }

  private setAngles(wheelAngle) {
    if (this.wheel != null) {
      this.wheel.nativeElement.style.transform = 'rotate(' + wheelAngle + 'deg)';
    }
    if (this.needle != null) {
      this.needle.nativeElement.style.transform = `rotate(${this.needleAnimator.handleWheelAngle(wheelAngle)}deg)`;
    }
  }
}
