import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Campaign} from '../../../models/campaign';
import {MerchantService} from '../../../../merchant/merchant.service';
import {first, takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Merchant} from '../../../../merchant/merchant';
import {CampaignService} from '../../../services/campaign.service';
import {CampaignWizardStoreService} from '../../services/campaign-wizard-store.service';

@Component({
  selector: 'app-campaign-verify',
  templateUrl: './campaign-verify.component.html',
  styleUrls: ['./campaign-verify.component.scss']
})
export class CampaignVerifyComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  campaign: Campaign;
  merchant: Merchant;

  constructor(private campaignService: CampaignService,
              private campaignStore: CampaignWizardStoreService,
              private router: Router,
              private route: ActivatedRoute,
              private merchantService: MerchantService) {
  }

  ngOnInit() {
    this.route.params.pipe(first()).subscribe(params => {
      window.scrollTo(0, 0);
      this.campaignStore.get(params['id']).pipe(
        takeUntil(this.ngUnsubscribe),
        tap((campaign: Campaign) => this.campaign = campaign)
      ).subscribe(campaign => {
        if (this.campaign.special_points_event && !this.campaign.special_points_event.valid()) {
          this.router.navigate(['/campaigns', params['id'], 'wizard', 'event']);
        } else if (!this.campaign.contentValid()) {
          this.router.navigate(['/campaigns', params['id'], 'wizard', 'content']);
          return;
        }
        if (this.campaign.special_points_event != null || this.campaign.campaign_goal === 'deal') {
          this.checkDeliveryPeriodForSPEorDeal();
        } else {
          if (!this.campaign.delivery_period) {
            this.campaign.delivery_period = 'immediately';
          }
        }
      });
    });

    this.merchantService.currentMerchant.pipe(takeUntil(this.ngUnsubscribe)).subscribe(merchant => {
      this.merchant = merchant;
    });
  }

  checkDeliveryPeriodForSPEorDeal() {
    if (!this.campaign.delivery_period) {
      if (this.canScheduledsendBeActivated()) {
        this.campaign.delivery_period = 'immediately';
      } else {
        this.campaign.delivery_period = 'immediately';
      }
      const relevantDate = this.getRelevantStartDate();
      const fourDays = 4 * 24 * 60 * 60 * 1000;
      relevantDate.setHours(8, 0, 0, 0); // suggest 4 days earlier at 8
      this.campaign.scheduledsend_at = new Date(Math.max(new Date().getTime(), relevantDate.getTime() - fourDays));
    } else if (!this.canScheduledsendBeActivated() && this.campaign.delivery_period === 'scheduledsend') {
      this.campaign.delivery_period = 'immediately';
    }
  }

  getRelevantStartDate() {
    let relevantDate;
    if (this.campaign.special_points_event != null) {
      relevantDate = new Date(this.campaign.special_points_event.recurring_events[0].starts_at);
    } else {
      const deal = this.campaign.deal;
      relevantDate = new Date(deal.starts_at);

      // check if the deal starts immediately, if so use the ends_at date for suggestion
      if (relevantDate.getTime() < new Date().getTime()) {
        relevantDate = new Date(deal.ends_at);
      }
    }
    return relevantDate;
  }

  canSendImmediately() {
    if (this.campaign.campaign_goal === 'deal' || this.campaign.special_points_event != null) {
      const relevantStartDate = this.getRelevantStartDate();
      return relevantStartDate.getTime() > Date.now();
    } else {
      return true;
    }
  }

  canScheduledsendBeActivated(): boolean {
    const inOneHour = new Date(new Date().getTime() + 60 * 60 * 1000);
    return this.campaign.isScheduledsendValid(inOneHour);
  }

  isScheduledsendInFuture() {
    return this.campaign.scheduledsend_at == null || new Date().getTime() < this.campaign.scheduledsend_at.getTime();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  persistChanges() {
    if (this.campaign.valid()) {
      this.campaignService.update(this.campaign).subscribe(campaign => this.campaignStore.update(campaign));
    }
  }
}
