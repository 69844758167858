import {Component, OnDestroy, OnInit} from '@angular/core';
import {SpecialPointsEvent} from '../special-points-event';
import {SpecialPointsEventService} from '../special-points-event.service';
import {Router} from '@angular/router';
import {PartnerService} from '../../partner/partner.service';
import {Partner} from '../../partner/partner';
import {RecurringEvent} from '../recurring-event';
import {Subscription} from 'rxjs';
import {SpecialPointsEventFactory} from '../special-points-event-factory';

@Component({
  selector: 'app-special-points-event-list',
  templateUrl: './special-points-event-list.component.html',
  styleUrls: ['./special-points-event-list.component.scss']
})
export class SpecialPointsEventListComponent implements OnInit, OnDestroy {

  currentPartner: Partner;
  events: SpecialPointsEvent[] = [];
  private subs = new Subscription();

  constructor(private specialPointsEventService: SpecialPointsEventService, private router: Router, private partnerService: PartnerService) {
  }

  ngOnInit() {
    this.specialPointsEventService.fetchAll().subscribe(specialPointsEvents => {
      this.events = specialPointsEvents;
    });

    this.subs.add(
        this.partnerService.currentPartner.subscribe(partner => {
          this.currentPartner = partner;
        })
    );
  }

  createNewEvent() {
    const specialPointsEvent = SpecialPointsEventFactory.buildForPosExperience();
    this.specialPointsEventService.create(specialPointsEvent)
        .subscribe(event => {
          this.router.navigate(['/special_points_events', event.id.toString()]);
        });
  }

  eventStatusChanged(event, status) {
    event.active = status;
    this.specialPointsEventService.update(event).subscribe(v => {
    });
  }

  destroyEvent(event) {
    this.specialPointsEventService.destroy(event).subscribe((success) => {
      this.specialPointsEventService.fetchAll().subscribe(specialPointsEvents => {
        this.events = specialPointsEvents;
      });
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
