import {Component} from '@angular/core';

import {NotificationsService} from './notifications.service';
import {Notification} from './notification';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.style.scss']
})
export class Notifications {
  notes: Notification[];

  constructor(private notifications: NotificationsService) {
    this.notes = new Array<Notification>();

    notifications.noteAdded.subscribe(note => {
      this.notes.push(note);

      if (note.timeout > 0) {
        setTimeout(() => {
          this.hide.bind(this)(note);
        }, note.timeout);
      }
    });
  }

  private hide(note) {
    const index = this.notes.indexOf(note);

    if (index >= 0) {
      this.notes.splice(index, 1);
    }
  }
}
