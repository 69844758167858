<h3>SEPA Mandate</h3>

<table class="table table-hover table-responsive">
  <thead>
  <tr>
    <th>Datum</th>
    <th style="width: 67%;">Name</th>
    <th>Download als PDF</th>
  </tr>
  </thead>
  <tbody>
  <tr
      *ngFor="let sepa_mandate of sepa_mandates | paginate: { id: 'sepa_mandates', itemsPerPage: per, currentPage: page, totalItems: count }">
    <td>{{sepa_mandate.created_at | date: 'dd.MM.yyyy'}}</td>
    <td style="width: 67%;">{{sepa_mandate.file_name}}</td>
    <td>
      <button class="btn btn-primary btn-sm" (click)="downloadPDF(sepa_mandate)">Download</button>
    </td>
  </tr>
  </tbody>
</table>
<div *ngIf="sepa_mandates == null || sepa_mandates.length == 0" class="alert alert-warning" role="alert"
     style="width: 40%; margin: auto;">
  Leider konnten keine SEPA-Mandate geladen werden. Bitte wende dich an den Mankido Support.
</div>
<pagination-controls [hidden]="sepa_mandates.length < 10" (pageChange)="onPage($event)" id="sepa_mandates"
                     style="text-align: center;"></pagination-controls>
