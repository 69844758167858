import {Component, Input, OnInit} from '@angular/core';
import {ContentElement} from '../../../campaigns/models/content-element';

@Component({
  selector: 'app-heading2',
  templateUrl: './heading2.component.html',
  styleUrls: ['./heading2.component.scss']
})
export class Heading2Component implements OnInit {

  @Input()
  ce: ContentElement;

  constructor() { }

  ngOnInit(): void {
  }

}
