import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {Observable, of} from 'rxjs';
import {first, tap} from 'rxjs/operators';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return of(this.auth.authenticated()).pipe(
        first(),
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['/login']);
          }
        })
    );
    // if (this.auth.authenticated()) {
    //   return true;
    // }
    //
    // this.router.navigate(['/login']);
    // return false;
  }
}
