import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StatusSwitchComponent} from './status-switch.component';
import {UiSwitchModule} from 'ngx-ui-switch';
import {RouterModule} from '@angular/router';



@NgModule({
  declarations: [
    StatusSwitchComponent
  ],
  imports: [
    CommonModule,
    UiSwitchModule,
    RouterModule
  ],
  exports: [
    StatusSwitchComponent
  ]
})
export class StatusSwitchModule { }
