import {Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {PosExperienceType} from './pos_experience';

@Component({
  selector: 'app-pos-experiences',
  templateUrl: './pos-experiences.component.html',
  styleUrls: ['./pos-experiences.component.scss']
})
export class PosExperiencesComponent implements OnInit {

  env = environment;
  types = PosExperienceType;

  constructor() { }

  ngOnInit() {
  }

}
