import {Component, OnInit} from '@angular/core';
import {SepaMandate} from '../sepa-mandate';
import {SepaMandateService} from '../sepa-mandate.service';

@Component({
  selector: 'sepa_mandate-list',
  templateUrl: './sepa-mandate-list.component.html'
})

export class SepaMandateListComponent implements OnInit {
  sepa_mandates: Array<SepaMandate> = [];
  count = 0;
  page = 1;
  per = 10;
  order_field = 'id';
  order_direction = 'desc';

  constructor(private sepaMandateService: SepaMandateService) {
  }

  ngOnInit() {
    this.onPage(1);
  }

  onPage(page: number) {
    this.page = page;
    this.refreshData();
  }

  onOrder(field: string) {
    if (this.order_field == field) {
      this.order_direction = this.order_direction == 'desc' ? 'asc' : 'desc';
    } else {
      this.order_field = field;
    }
    this.refreshData();
  }

  refreshData() {
    this.sepaMandateService.fetchPage(this.page, this.per, this.order_field, this.order_direction).subscribe(sepaMandateList => {
      this.sepa_mandates = sepaMandateList;
      this.count = sepaMandateList.length;
    });
  }

  downloadPDF(sepa_mandate) {
    this.sepaMandateService.downloadPDF(sepa_mandate).subscribe((blob) => {
      // Create a link element, hide it, direct
      // it towards the blob, and then 'click' it programatically
      const a = document.createElement('a');
      // a.style = 'display: none';
      document.body.appendChild(a);
      // Create a DOMString representing the blob
      // and point the link element towards it
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = sepa_mandate.file_name;
      // programatically click the link to trigger the download
      a.click();
      // release the reference to the file by revoking the Object URL
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }
}
