import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Business} from '../../../loyalty/businesses/business';
import {takeUntil, tap} from 'rxjs/operators';
import {MerchantService} from '../../../merchant/merchant.service';
import {Subject} from 'rxjs';
import {Partner} from '../../../partner/partner';
import {ReferralEvent} from '../../../referral/referral-event';
import {ContentElement} from '../../../campaigns/models/content-element';

@Component({
  selector: 'app-referral-event-preview',
  templateUrl: './referral-event-preview.component.html',
  styleUrls: ['./referral-event-preview.component.scss']
})
export class ReferralEventPreviewComponent implements OnInit, OnDestroy {

  @Input()
  ce: ContentElement;

  @Input()
  sender: Partner;

  @Input()
  event: ReferralEvent;

  private ngUnsubscribe = new Subject();
  private businesses: Business[] = [];

  constructor(private merchantService: MerchantService) {
  }

  ngOnInit(): void {
    this.merchantService.currentMerchant.pipe(
      takeUntil(this.ngUnsubscribe),
      tap(merchant => {
        this.businesses = merchant.available_contexts.filter(ac => ac.partner_id);
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  blacklistedBusinessNames(referralEvent) {
    const businessNames = referralEvent.blacklisted_business_ids.map(bid => this.businesses.find((b) => b.id === bid).name);
    if (businessNames.length >= 2) {
      return businessNames.slice(0, -1).join(', ') + ' und ' + businessNames[businessNames.length - 1];
    } else {
      return businessNames[0];
    }
  }

  getTitle() {
    if (this.ce) {
      return this.ce.data.title;
    } else {
      return this.event.inviter_special_points_event.deal?.title;
    }
  }

  getDescription() {
    if (this.ce) {
      return this.ce.data.text;
    } else {
      return this.event.inviter_special_points_event.deal?.description;
    }
  }

  getReferralEvent(): ReferralEvent {
    return this.ce ? this.ce.referral_event : this.event;
  }
}
