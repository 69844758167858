import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ContentElement} from '../../../campaigns/models/content-element';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TextComponent implements OnInit {

  @Input()
  ce: ContentElement;

  constructor() {
  }

  ngOnInit(): void {
  }

}
