import {Component, Input, OnInit} from '@angular/core';
import {ContentElement} from '../../../campaigns/models/content-element';

@Component({
  selector: 'app-spacer',
  templateUrl: './spacer.component.html',
  styleUrls: ['./spacer.component.scss']
})
export class SpacerComponent implements OnInit {

  @Input()
  ce: ContentElement;

  @Input()
  size: number;

  constructor() { }

  ngOnInit(): void {
  }

  getHeight(): number {
    if (this.ce) {
      return this.ce.data['size'];
    } else {
      return this.size;
    }
  }

}
