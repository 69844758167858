<app-content-bundle-preview-container [previewType]="previewType" [previewTypeSwitchable]="previewTypeSwitchable">
  <ng-container *ngFor="let ce of contentElementsSorted(); trackBy: trackBy" [ngSwitch]="ce.element_type">
    <div [hidden]="ce._destroy">
      <app-heading1 class="content-padding" [ce]="ce" *ngSwitchCase="'heading1'"></app-heading1>
      <app-heading2 class="content-padding" [ce]="ce" *ngSwitchCase="'heading2'"></app-heading2>
      <app-heading3 class="content-padding" [ce]="ce" *ngSwitchCase="'heading3'"></app-heading3>
      <app-asset [ce]="ce" *ngSwitchCase="'asset'"></app-asset>
      <app-text class="content-padding" [ce]="ce" *ngSwitchCase="'text'"></app-text>
      <app-button class="content-padding" [ce]="ce" *ngSwitchCase="'button'"></app-button>
      <app-spacer [ce]="ce" *ngSwitchCase="'spacer'"></app-spacer>

      <app-deal-preview [ce]="ce" [contentBundle]="contentBundle" *ngSwitchCase="'deal'"></app-deal-preview>

      <app-special-points-event-preview *ngSwitchCase="'special_points_event'"
                                        [specialPointsEvent]="ce.special_points_event"></app-special-points-event-preview>

      <app-referral-event-preview [ce]="ce" *ngSwitchCase="'referral_event'" [sender]="contentBundle.author"></app-referral-event-preview>

      <app-obtain-review-event-preview class="content-padding" [ce]="ce" *ngSwitchCase="'obtain_review_event'"></app-obtain-review-event-preview>
    </div>
  </ng-container>
</app-content-bundle-preview-container>
