import {Router} from '@angular/router';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Campaign} from '../../models/campaign';
import {PartnerService} from '../../../partner/partner.service';
import {Partner} from '../../../partner/partner';
import {CampaignService} from '../../services/campaign.service';
import {environment} from '../../../../environments/environment';
import {Subject} from 'rxjs';
import {MerchantService} from '../../../merchant/merchant.service';
import {Business} from '../../../loyalty/businesses/business';
import {AddonSubscriptionService} from '../../../addons/addon-subscription.service';
import {AddonTypes} from '../../../addons/addon-types';
import {takeUntil, tap} from 'rxjs/operators';
import {ReferralEventService} from '../../../referral-settings/referral-event.service';
import {CampaignFactoryService} from '../../services/campaign-factory.service';
import {ContentBundleService} from '../../../content-bundle/services/content-bundle.service';
import {SpecialPointsEventCampaignFactory} from '../../services/special-points-event-campaign-factory';

@Component({
  selector: 'app-campaign-new',
  templateUrl: './campaign-new.component.html',
  styleUrls: ['./campaign-new.component.scss']
})
export class CampaignNewComponent implements OnInit, OnDestroy {

  env = environment;

  campaign: Campaign;
  currentPartner: Partner;
  context: Partner | Business;

  hasActiveObtainReviewSubscription: boolean;
  hasActiveReferralSubscription: boolean;
  referralFeatureIsConfigured = true;

  private ngUnsubscribe = new Subject();

  constructor(private router: Router,
              private partnerService: PartnerService,
              private merchantService: MerchantService,
              private campaignService: CampaignService,
              private contentBundleService: ContentBundleService,
              public addonSubscriptionService: AddonSubscriptionService,
              public referralEventService: ReferralEventService,
              private campaignFactoryService: CampaignFactoryService) {
  }

  ngOnInit() {
    this.partnerService.currentPartner.pipe(
      takeUntil(this.ngUnsubscribe),
      tap<Partner>(partner => this.currentPartner = partner)
    ).subscribe();

    this.merchantService.currentContext.pipe(
      takeUntil(this.ngUnsubscribe),
      tap<Partner | Business>(context => this.context = context)
    ).subscribe();

    this.addonSubscriptionService.hasActiveSubscriptionFor(AddonTypes.OBTAIN_REVIEW).pipe(
      takeUntil(this.ngUnsubscribe),
      tap<boolean>(hasActive => this.hasActiveObtainReviewSubscription = hasActive)
    ).subscribe();

    this.addonSubscriptionService.hasActiveSubscriptionFor(AddonTypes.REFERRAL).pipe(
      takeUntil(this.ngUnsubscribe),
      tap<boolean>(hasActive => this.hasActiveReferralSubscription = hasActive)
    ).subscribe();

    this.referralEventService.fetch().pipe(
      tap(
        referralEvent => this.referralFeatureIsConfigured = referralEvent != null,
        _ => this.referralFeatureIsConfigured = false
      )
    ).subscribe();
  }

  createDeal() {
    this.persistAndContinueToNextStep(CampaignFactoryService.buildDealCampaign());
  }

  createObtainReviewEvent() {
    this.persistAndContinueToNextStep(CampaignFactoryService.buildObtainReviewCampaign());
  }

  createReferralEventCampaign() {
    this.persistAndContinueToNextStep(CampaignFactoryService.buildReferralCampaign());
  }

  createInformation() {
    this.persistAndContinueToNextStep(CampaignFactoryService.buildInformationCampaign());
  }

  createSpecialPointsEvent() {
    const campaign = CampaignFactoryService.buildInformationWithSpecialPointsEventCampaign();
    campaign.content_bundle = SpecialPointsEventCampaignFactory.updateText(campaign.content_bundle, this.currentPartner.name);
    this.persistAndContinueToNextStep(campaign);
  }

  persistAndContinueToNextStep(campaign) {
    this.campaignFactoryService.create(campaign).pipe(
      tap(createdCampaign => {
        this.campaign = createdCampaign;
        if (this.campaign.obtain_review_event) {
          this.router.navigate(['campaigns', this.campaign.id, 'wizard', 'content']);
        } else {
          this.router.navigate(['campaigns', this.campaign.id, 'wizard', 'target']);
        }
      }),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  hasThirdPartyBusinesses(): boolean {
    return (this.context as Business)?.has_third_party_businesses;
  }
}
