import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProgressRingComponent} from './progress-ring.component';


@NgModule({
  declarations: [ProgressRingComponent],
  exports: [ProgressRingComponent],
  imports: [
    CommonModule
  ]
})
export class ProgressRingModule { }
