import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DndUploaderComponent} from './dnd-uploader.component';
import {DndModule} from 'ngx-drag-drop';


@NgModule({
  declarations: [DndUploaderComponent],
  exports: [DndUploaderComponent],
  imports: [
    CommonModule,
    DndModule
  ]
})
export class DndUploaderModule {
}
