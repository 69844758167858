import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SepaMandateListComponent} from './sepa-mandate-list/sepa-mandate-list.component';

const routes: Routes = [
  {
    path: '',
    component: SepaMandateListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SepaMandatesRoutingModule {
}
