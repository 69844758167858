import {Observable} from 'rxjs';
import {first, flatMap, tap} from 'rxjs/operators';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from '../../auth';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('authservice', this.authService);
    // console.log('intercept', req, next);
    return of(this.authService.authenticated()).pipe(first(), flatMap(authenticated => {
      if (authenticated) {
        const token = localStorage.getItem('jwt');
        const context = JSON.parse(localStorage.getItem('context'));
        // TODO why doesn't this header get appended correctly?
        // console.log('context on request', context);
        // if (!!context) {
        //   console.log('append', context);
        //   req.headers.append('MerchantContext', `{"type": "${context.partner_id ? 'business' : 'partner'}", "id": "${context.id}"}`);
        // }

        // workaround for above failure
        let cloned;
        if (!!context) {
          cloned = req.clone({
            headers: req.headers
                .set('Authorization', 'Bearer ' + token)
                .set('Accept', 'application/json')
                .set('MerchantContext', `{"type": "${context.partner_id ? 'business' : 'partner'}", "id": "${context.id}"}`)
          });
        } else {
          cloned = req.clone({
            headers: req.headers
                .set('Authorization', 'Bearer ' + token)
                .set('Accept', 'application/json')
          });
        }
        return next.handle(cloned).pipe(tap(event => {
          // console.log('tap');
          // if (event instanceof HttpResponse && event.status === 401) {
          //   // console.log('unauthorized');
          //   this.router.navigate(['/unauthorized']);
          // }
        }));
      } else {
        // console.log('authenticated fail, next.handle()');
        return next.handle(req);
      }
    }));
  }
}
