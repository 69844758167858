import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {AddonSubscription} from './addon-subscription';
import {map, tap, throttleTime} from 'rxjs/operators';
import {AddonTypes} from './addon-types';
import {ReplaySubject, Subject} from 'rxjs';
import {MerchantService} from '../merchant/merchant.service';

@Injectable({
  providedIn: 'root'
})
export class AddonSubscriptionService {
  private ADDON_SUBSCRIPTION_URL: string = environment.api_base_url + 'addon_subscriptions';

  public activeSubscriptions$: ReplaySubject<AddonSubscription[]> = new ReplaySubject<AddonSubscription[]>(1);
  private refreshList = new Subject<void>();

  public constructor(private http: HttpClient, private merchantService: MerchantService) {
    this.refreshList.pipe(
      throttleTime(1000),
      tap(_ => this.fetchActive().subscribe(res => this.activeSubscriptions$.next(res)))
    ).subscribe();

    this.merchantService.currentMerchant.pipe(tap(_ => this.refreshActiveAddonSubscriptions())).subscribe();
  }

  public refreshActiveAddonSubscriptions(): void {
    this.refreshList.next(null);
  }

  private fetchActive(): Observable<AddonSubscription[]> {
    return this.http.get<{ addon_subscriptions: AddonSubscription[] }>(`${this.ADDON_SUBSCRIPTION_URL}?active=true`).pipe(
      map((response: { addon_subscriptions: AddonSubscription[] }) => response.addon_subscriptions.map((el) => new AddonSubscription(el)))
    );
  }

  public subscribe(addonType: AddonTypes): Observable<AddonSubscription> {
    return this.http.put<AddonSubscription>(`${this.ADDON_SUBSCRIPTION_URL}/subscribe?addon_type=${addonType}`, {}).pipe(
      map(response => new AddonSubscription(response))
    );
  }

  public unsubscribe(addonType: AddonTypes): Observable<AddonSubscription> {
    return this.http.delete<AddonSubscription>(`${this.ADDON_SUBSCRIPTION_URL}/unsubscribe?addon_type=${addonType}`).pipe(
      map(response => new AddonSubscription(response))
    );
  }

  public activeSubscriptionsFor(addonType: AddonTypes): Observable<AddonSubscription[]> {
    return this.activeSubscriptions$.pipe(
      map(addonSubscriptions => {
        return addonSubscriptions.filter(addonSubscription => addonSubscription.addon_type === addonType);
      })
    );
  }

  public hasActiveSubscriptionFor(addonType: AddonTypes): Observable<boolean> {
    return this.activeSubscriptions$.pipe(
      map(addonSubscriptions => {
        return addonSubscriptions.filter(subscription => subscription.canUse(addonType)).length > 0;
      })
    );
  }
}
