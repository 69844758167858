import {Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {CampaignService} from '../../services/campaign.service';
import {Campaign} from '../../models/campaign';
import {concatMap, first} from 'rxjs/operators';
import {MerchantService} from '../../../merchant/merchant.service';
import {CampaignWizardStoreService} from '../../campaign-wizard/services/campaign-wizard-store.service';

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.scss']
})
export class CampaignListComponent implements OnInit {
  campaigns: Campaign[];
  drafts: Campaign[];
  total_sent: number;
  page = 1;

  loadingDraftId = null;
  creatingDraftId = null;

  constructor(private router: Router,
              private merchantService: MerchantService,
              private campaignService: CampaignService,
              private campaignStore: CampaignWizardStoreService) {
  }

  ngOnInit(): void {
    this.refreshCampaignList();
  }

  refreshCampaignList() {
    this.merchantService.currentContext.pipe(
      concatMap(() => {
        return this.campaignService.fetchAll(this.page);
      })
    ).subscribe((data) => {
      this.campaigns = data.sent;
      this.drafts = data.drafts;
      this.total_sent = data.total_sent;
    });
  }

  getCampaignGoalTitle(draft) {
    if (draft.content_bundle.content_elements?.some(ce => {
      return ce.element_type === 'special_points_event' || ce.deal && ce.deal.deal_type === 'special_points_event';
    })) {
      return 'Spezial-Punkte';
    } else if (draft.content_bundle.content_elements?.some(ce => ce.element_type === 'obtain_review_event')) {
      return 'Google Bewertung Kampagne';
    }
    return Campaign.campaign_goals[draft.campaign_goal];
  }

  useAsTemplate(campaign: Campaign) {
    this.creatingDraftId = campaign.id;
    this.campaignService.copy(campaign).subscribe(newCampaign => {
      if (newCampaign.obtain_review_event) {
        this.router.navigate(['/campaigns', newCampaign.id, 'wizard', 'verify']);
      } else {
        this.router.navigate(['/campaigns', newCampaign.id, 'wizard', 'target']);
      }
    });
  }

  destroy(campaign: Campaign) {
    this.campaignService.destroy(campaign).subscribe(_ => {
      this.refreshCampaignList();
    });
  }

  navigateToDraft(draft: Campaign) {
    this.loadingDraftId = draft.id;
    this.campaignStore.get(draft.id)
      .pipe(first())
      .subscribe((campaign: Campaign) => {
        if (campaign.contentValid()) {
          return this.router.navigate(['/campaigns', campaign.id, 'wizard', 'verify']);
        } else if (campaign.targetValid()) {
          return this.router.navigate(['/campaigns', campaign.id, 'wizard', 'content']);
        } else if (campaign.audience_definition.audience_type === 'advanced') {
          return this.router.navigate(['/campaign', campaign.id, 'wizard', 'target', 'experts']);
        } else {
          return this.router.navigate(['/campaigns', campaign.id, 'wizard', 'target']);
        }
      });
  }
}
