import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DateTimePickerComponent} from './date-time-picker.component';
import {NgbTimepickerModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {DatepickerModule} from '../datepicker/datepicker.module';
import {FormsModule} from '@angular/forms';


@NgModule({
  declarations: [DateTimePickerComponent],
  exports: [DateTimePickerComponent],
  imports: [
    CommonModule,
    NgbTimepickerModule,
    NgbTooltipModule,
    DatepickerModule,
    FormsModule
  ]
})
export class DateTimePickerModule { }
