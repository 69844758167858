import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReleaseMonitorService} from './release-monitor.service';


@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule
  ]
})
export class ReleaseMonitorModule {
}
