import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Partner} from '../../partner/partner';
import {PartnerService} from '../../partner/partner.service';
import {PosExperience, PosExperienceType} from '../../pos-experiences/pos_experience';
import {GameAnimation} from '../../wheel-of-luck/animations/game-animation';
import {WheelOfLuckComponent} from '../../wheel-of-luck/wheel-of-luck/wheel-of-luck.component';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-wheel-of-luck-info',
  templateUrl: './wheel-of-luck-info.component.html',
  styleUrls: ['./wheel-of-luck-info.component.scss']
})
export class WheelOfLuckInfoComponent implements OnInit, OnDestroy {

  @Output() start = new EventEmitter();
  @ViewChild('wheel', {static: true}) wheelComponent: WheelOfLuckComponent;

  currentPartner: Partner;
  posExperience = new PosExperience({
    type: PosExperienceType.wheel_of_luck
  });

  posExperience2: PosExperience = JSON.parse(JSON.stringify(this.posExperience));

  animations = [];

  GameAnimation = GameAnimation;

  private subs = new Subscription();

  constructor(private partnerService: PartnerService) {
    this.posExperience2.config.theme = 'dark-theme';
  }

  ngOnInit() {
    this.subs.add(
        this.partnerService.currentPartner.subscribe(partner => {
          this.currentPartner = partner;
        })
    );

    setTimeout(() => {
      this.startDemoAnimation();
      this.subs.add(
          this.wheelComponent.animationFinished.subscribe(finished => {
            setTimeout(() => this.startDemoAnimation(), 2000);
          })
      );
    }, 1000);
  }

  private startDemoAnimation() {
    const rand = Math.floor(Math.random() * 4);
    this.wheelComponent.animations = GameAnimation.createTurnAnimations(rand, this.wheelComponent.getWheelAngle());
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
