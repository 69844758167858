import {ContentElement} from '../campaigns/models/content-element';
import {Partner} from '../partner/partner';
import {SpecialPointsEvent} from '../special-points-events/special-points-event';
import {ObtainReviewEvent} from '../obtain-review/obtain-review-event';
import {Deal} from '../deals/deal';

export class ContentBundle {
  id: number;
  author: Partner;
  parent_id: number;
  parent_type: string;
  created_at?: Date;
  updated_at?: Date;

  content_elements: ContentElement[] = [];

  constructor(values: Partial<ContentBundle>) {
    this.id = values.id;
    this.parent_id = values.parent_id;
    this.parent_type = values.parent_type;

    this.author = new Partner(values.author);

    this.created_at = values.created_at ? new Date(values.created_at) : null;
    this.updated_at = values.updated_at ? new Date(values.updated_at) : null;

    if (values.content_elements) {
      this.content_elements = values.content_elements.map(ce => new ContentElement(ce));
    }
  }

  public hasObtainReviewEvent(): boolean {
    return this.content_elements.some(ce => ce.obtain_review_event != null);
  }

  public hasAsset(): boolean {
    return this.content_elements.some(ce => ce.element_type === 'asset');
  }

  public get special_points_event(): SpecialPointsEvent | null {
    return this.content_elements.find(ce => ce.element_type === 'special_points_event')?.special_points_event || null;
  }

  public asRequest(): Partial<ContentBundle> | any {
    return {
      id: this.id,
      content_elements_attributes: this.content_elements.map(ce => ce.asRequest())
    };
  }
}
