export class AudienceDefinition {
  id: number;
  audience_type: 'segment' | 'advanced';
  // scope: string; // wird vom Server gesetzt
  // partner_id: number; // wird vom Server gesetzt
  segment: 'partner_all' | 'partner_new' | 'partner_walk_in' | 'partner_loyal' | 'partner_vip' | 'business_all' | 'business_new' | 'business_walk_in' | 'business_loyal' | 'business_vip' | 'partner_obtain_reviews' | 'business_obtain_reviews';
  delivery_channel: 'both_mobile_and_email' | 'mobile_only' | 'email_only';
  purchase_volume_sum_total_min: number;
  purchase_volume_sum_total_max: number;
  purchase_count_total_min: number;
  purchase_count_total_max: number;
  membership_created_at_after: Date;
  membership_created_at_before: Date;
  last_transaction_date_after: Date;
  last_transaction_date_before: Date;
  size: number;
  business_ids: number[];

  static audidence_types: Object = {
    segment: 'Segment',
    advanced: 'Erweitert'
  };

  // static scopes: Object = {
  //   partner: 'Partner',
  //   mankido: 'Mankido'
  // };

  static segments: Object = {
    partner_all: 'Alle Kunden',
    partner_new: 'Neukunden',
    partner_walk_in: 'Gelegenheitskunden',
    partner_loyal: 'Treue Kunden',
    partner_vip: 'VIP Kunden',
    business_all: 'Alle Kunden',
    business_new: 'Neukunden',
    business_walk_in: 'Gelegenheitskunden',
    business_loyal: 'Treue Kunden',
    business_vip: 'VIP Kunden',
    partner_obtain_reviews: '',
    business_obtain_reviews: ''
    // mankido_all: 'Alle',
    // mankido_app: 'App-Nutzer',
    // mankido_noapp: 'Karten-Nutzer'
  };

  constructor(values: Partial<AudienceDefinition> = {}) {
    this.id = values.id;
    this.audience_type = values.audience_type;
    this.segment = values.segment;
    this.delivery_channel = values.delivery_channel;
    this.purchase_volume_sum_total_min = values.purchase_volume_sum_total_min;
    this.purchase_volume_sum_total_max = values.purchase_volume_sum_total_max;
    this.purchase_count_total_min = values.purchase_count_total_min;
    this.purchase_count_total_max = values.purchase_count_total_max;
    this.membership_created_at_after = values.membership_created_at_after;
    this.membership_created_at_before = values.membership_created_at_before;
    this.last_transaction_date_after = values.last_transaction_date_after;
    this.last_transaction_date_before = values.last_transaction_date_before;
    this.size = values.size;
    this.business_ids = values.business_ids;
  }

  valid(): boolean {
    const validType = AudienceDefinition.audidence_types.hasOwnProperty(this.audience_type);
    let validSettings = false;
    if (this.audience_type === 'segment') {
      validSettings = AudienceDefinition.segments.hasOwnProperty(this.segment);
      if (this.segment?.startsWith('business')) {
        validSettings = validSettings && this.business_ids.length > 0;
      }
    } else if (this.audience_type === 'advanced') {
      validSettings = true;
    } else {
      validSettings = false;
    }

    return validType && validSettings;
  }

  humanAudienceDefinition(): string {
    return AudienceDefinition.segments[this.segment];
  }

  public asRequest(): Partial<AudienceDefinition> | any {
    return {
      id: this.id,
      audience_type: this.audience_type,
      segment: this.segment,
      delivery_channel: this.delivery_channel,
      purchase_volume_sum_total_min: this.purchase_volume_sum_total_min,
      purchase_volume_sum_total_max: this.purchase_volume_sum_total_max,
      purchase_count_total_min: this.purchase_count_total_min,
      purchase_count_total_max: this.purchase_count_total_max,
      membership_created_at_after: this.membership_created_at_after,
      membership_created_at_before: this.membership_created_at_before,
      last_transaction_date_after: this.last_transaction_date_after,
      last_transaction_date_before: this.last_transaction_date_before,
      business_ids: this.business_ids
    }
  }
}

export class AudienceSize {
  size: number;
  total: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
