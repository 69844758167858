import {Component} from '@angular/core';

import {Modal} from './modal';
import {ModalService} from './modal.service';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html'
})
export class Modals {
  modal: Modal;
  visible = false;

  constructor(private modalService: ModalService) {
    this.modal = new Modal();

    modalService.newModal.subscribe(modal => {
      this.modal = modal;
      this.visible = true;
    });
  }

  close() {
    this.visible = false;
    this.modal.onClose();
  }

  ok() {
    this.visible = false;
    this.modal.onOk();
  }
}
