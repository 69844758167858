<div class="datepicker">
  <input (click)="onInputClick()" [(ngModel)]="inputText" [disabled]="disabled" [placeholder]="placeholder"
         class="form-control" readonly="true">
  <div *ngIf="showCalendar" class="datepicker_calendar">
    <div class="datepicker_calendar_nav">
      <div (click)="onArrowClick('left')" class="datepicker_calendar_nav_arrow">
        <svg class="datepicker_calendar_nav_chevron" viewBox="0 0 50 50" x="0px" y="0px">
          <g>
            <path d="M39.7,7.1c0.5,0.5,0.5,1.2,0,1.7L29,19.6c-0.5,0.5-1.2,1.2-1.7,1.7L16.5,32.1c-0.5,0.5-1.2,0.5-1.7,0l-2.3-2.3
                    c-0.5-0.5-1.2-1.2-1.7-1.7l-2.3-2.3c-0.5-0.5-0.5-1.2,0-1.7l10.8-10.8c0.5-0.5,1.2-1.2,1.7-1.7L31.7,0.8c0.5-0.5,1.2-0.5,1.7,0
                    l2.3,2.3c0.5,0.5,1.2,1.2,1.7,1.7L39.7,7.1z"/>
          </g>
          <g>
            <path d="M33.4,49c-0.5,0.5-1.2,0.5-1.7,0L20.9,38.2c-0.5-0.5-1.2-1.2-1.7-1.7L8.4,25.7c-0.5-0.5-0.5-1.2,0-1.7l2.3-2.3
                    c0.5-0.5,1.2-1.2,1.7-1.7l2.3-2.3c0.5-0.5,1.2-0.5,1.7,0l10.8,10.8c0.5,0.5,1.2,1.2,1.7,1.7l10.8,10.8c0.5,0.5,0.5,1.2,0,1.7
                    L37.4,45c-0.5,0.5-1.2,1.2-1.7,1.7L33.4,49z"/>
          </g>
        </svg>
      </div>
      <div class="datepicker_calendar_nav_header">
        <span>{{ currentMonth }}</span>
      </div>
      <div (click)="onArrowClick('right')" class="datepicker_calendar_nav_arrow">
        <svg class="datepicker_calendar_nav_chevron" viewBox="0 0 50 50" x="0px" y="0px">
          <g>
            <path d="M8.4,7.1c-0.5,0.5-0.5,1.2,0,1.7l10.8,10.8c0.5,0.5,1.2,1.2,1.7,1.7l10.8,10.8c0.5,0.5,1.2,0.5,1.7,0l2.3-2.3
                    c0.5-0.5,1.2-1.2,1.7-1.7l2.3-2.3c0.5-0.5,0.5-1.2,0-1.7L29,13.2c-0.5-0.5-1.2-1.2-1.7-1.7L16.5,0.8c-0.5-0.5-1.2-0.5-1.7,0
                    l-2.3,2.3c-0.5,0.5-1.2,1.2-1.7,1.7L8.4,7.1z"/>
          </g>
          <g>
            <path d="M14.8,49c0.5,0.5,1.2,0.5,1.7,0l10.8-10.8c0.5-0.5,1.2-1.2,1.7-1.7l10.8-10.8c0.5-0.5,0.5-1.2,0-1.7l-2.3-2.3
                    c-0.5-0.5-1.2-1.2-1.7-1.7l-2.3-2.3c-0.5-0.5-1.2-0.5-1.7,0L20.9,28.5c-0.5,0.5-1.2,1.2-1.7,1.7L8.4,40.9c-0.5,0.5-0.5,1.2,0,1.7
                    l2.3,2.3c0.5,0.5,1.2,1.2,1.7,1.7L14.8,49z"/>
          </g>
        </svg>
      </div>
    </div>
    <div class="datepicker_calendar_content">
      <div class="datepicker_calendar_labels">
        <div *ngFor="let day of dayNames" class="datepicker_calendar_label">
          {{ day }}
        </div>
      </div>
      <div [@calendarAnimation]="animate" class="datepicker_calendar_month">
        <div (click)="onSelectDay(day)" (mouseenter)="hoveredDay = day"
             (mouseleave)="hoveredDay = null"
             *ngFor="let day of calendarDays" [ngStyle]="{'cursor': day == 0 ? 'initial' : 'pointer', 'background-color': getDayBackgroundColor(day), 'color': isHoveredDay(day) ? accentColor : getDayFontColor(day), 'pointer-events': day == 0 ? 'none' : ''}" class="datepicker_calendar_month_day">
              <span *ngIf="day != 0">
                {{ day.getDate() }}
              </span>
        </div>
      </div>
      <div (click)="onCancel()" class="datepicker_calendar_cancel">
        Abbrechen
      </div>
    </div>
  </div>
</div>
