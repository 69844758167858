import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Modal} from './modal';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  modals = new Subject<Modal>();

  newModal = this.modals.asObservable();

  public show(modal: Modal) {
    this.modals.next(modal);
  }

}
