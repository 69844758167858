import {Component, Input} from '@angular/core';
import {SpecialPointsEvent} from '../../special-points-event';
import {Partner} from '../../../partner/partner';

@Component({
  selector: 'app-referral-points-event-preview',
  templateUrl: './referral-points-event-preview.component.html',
  styleUrls: ['./referral-points-event-preview.component.scss']
})
export class ReferralPointsEventPreviewComponent {

  @Input()
  specialPointsEvent: SpecialPointsEvent;

  @Input()
  viewFor: 'inviter' | 'invitee' = 'inviter';

  constructor() {
  }
}
