export class Easings {
  public static easeInBack(x) {
    const c1 = 1.70158;
    const c3 = c1 + 1;

    return c3 * x * x * x - c1 * x * x;
  }

  public static easeOutCubic(x) {
    // https://easings.net/#easeOutCubic
    return 1 - Math.pow(1 - x, 3);
  }

  public static easeInOutSine(x) {
    return -(Math.cos(Math.PI * x) - 1) / 2;
  }
}
