<ng-container *ngIf="getReferralEvent()">
  <app-spacer [size]="30"></app-spacer>

  <app-heading1 class="content-padding" [text]="getTitle() || 'Überschrift'"></app-heading1>

  <app-spacer [size]="30"></app-spacer>

  <app-referral-points-event-preview [specialPointsEvent]="getReferralEvent().inviter_special_points_event"></app-referral-points-event-preview>

  <app-spacer [size]="30"></app-spacer>

  <app-trix-text class="content-padding" [text]="getDescription() || 'Hier steht der Text, den die Kunden per eMail oder App erhalten.'"></app-trix-text>

  <app-spacer [size]="30"></app-spacer>

  <app-button [label]="'Jetzt Freunde einladen!'"></app-button>

  <app-spacer [size]="30"></app-spacer>

  <div *ngIf="getReferralEvent().blacklisted_business_ids.length > 0" class="mail">
    <hr/>

    <app-spacer [size]="30"></app-spacer>

    <div class="content-padding">
      <strong>Nicht teilnehmende Standorte</strong>: {{blacklistedBusinessNames(getReferralEvent())}}
    </div>

    <app-spacer [size]="30"></app-spacer>
  </div>
</ng-container>
