import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Prize, PrizeLevel} from '../../wheel-of-luck/models/prize';
import {PosExperienceRelation} from '../../pos-experiences/pos_experience';
import {SpecialPointsEvent} from '../../special-points-events/special-points-event';
import {Reward} from '../../loyalty/rewards/reward';

@Component({
  selector: 'app-prize-editor',
  templateUrl: './prize-editor.component.html',
  styleUrls: ['./prize-editor.component.scss']
})
export class PrizeEditorComponent implements OnInit {
  maxTextLength = 35;

  @Input()
  title: string;

  @Input()
  invalidOdds;

  @Input()
  highlight: boolean;

  @Input()
  prize: Prize;

  @Input()
  relations: PosExperienceRelation[];

  @Output()
  relationsChange: EventEmitter<PosExperienceRelation[]> = new EventEmitter<PosExperienceRelation[]>();

  @Output()
  sliceChange: EventEmitter<Prize> = new EventEmitter<Prize>();

  @Output()
  onTurnRequest: EventEmitter<PrizeLevel> = new EventEmitter<PrizeLevel>();


  constructor() {
  }

  ngOnInit() {
  }

  onChange() {
    this.sliceChange.emit(this.prize);
    this.relationsChange.emit(this.relations);
  }

  get multiplierPointsEvent(): SpecialPointsEvent {
    let rel = this.relations.find(x => x.object_type === 'SpecialPointsEvent'
      && (x.object as SpecialPointsEvent).type === 'points_multiplier_event'
      && x.relation_hint === this.prize.level);
    if (rel == null) {
      rel = {
        relation_hint: this.prize.level as string,
        object_type: 'SpecialPointsEvent',
        object: {multiplier: 2, type: 'points_multiplier_event'}
      } as PosExperienceRelation;
      this.relations.push(rel);
    }
    return rel.object as SpecialPointsEvent;
  }

  get extraPointsEvent(): SpecialPointsEvent {
    let rel = this.relations.find(x => x.object_type === 'SpecialPointsEvent'
      && (x.object as SpecialPointsEvent).type === 'extra_points_event'
      && x.relation_hint === this.prize.level);
    if (rel == null) {
      rel = {
        relation_hint: this.prize.level as string,
        object_type: 'SpecialPointsEvent',
        object: {extra_points: 10, type: 'extra_points_event'}
      } as PosExperienceRelation;
      this.relations.push(rel);
    }
    return rel.object as SpecialPointsEvent;
  }

  get reward(): Reward {
    let rel = this.relations.find(x => x.object_type === 'Reward'
      && x.relation_hint === this.prize.level);
    if (rel == null) {
      rel = {
        relation_hint: this.prize.level as string,
        object_type: 'Reward',
        object: {title: ''}
      } as PosExperienceRelation;
      this.relations.push(rel);
    }
    return rel.object as Reward;
  }


}
