import {Component, Input, OnInit} from '@angular/core';
import {ContentElement} from '../../../campaigns/models/content-element';

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss']
})
export class AssetComponent implements OnInit {

  @Input()
  ce: ContentElement;

  @Input()
  url: string;

  constructor() { }

  ngOnInit(): void {
  }

  isAssetPresent() {
    return this.assetUrl() != null;
  }

  assetUrl() {
    if (this.ce) {
      return this.ce?.asset?.url_email;
    } else {
      return this.url;
    }
  }

}
