import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

import {Notification} from './notification';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  _notifications = new Subject<Notification>();

  noteAdded = this._notifications.asObservable();

  constructor(private toastrService: ToastrService) {
  }

  public success(text: string, title: string = null) {
    this.toastrService.success(text, title);
  }

  public error(text: string, title: string = null) {
    this.toastrService.error(text, title);
  }
}
