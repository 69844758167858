<div class="prize-description">
  <svg class="trophy" viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
    <defs></defs>
    <path d="M 129.65 35.35 C 129.65 46.779 121.536 56.34 110.791 58.523 L 110.791 58.337 L 110.791 24.256 L 129.65 24.256 L 129.65 35.35 Z M 75.002 84.915 C 60.416 84.915 48.547 72.992 48.547 58.337 L 48.547 19.569 L 48.547 17.882 L 101.453 17.882 L 101.453 19.569 L 101.453 58.337 C 101.453 72.992 89.588 84.915 75.002 84.915 L 75.002 84.915 Z M 39.213 58.523 C 28.468 56.34 20.354 46.779 20.354 35.35 L 20.354 24.256 L 39.213 24.256 L 39.213 58.337 L 39.213 58.523 Z M 134.319 14.878 L 110.791 14.878 L 110.791 13.191 C 110.791 10.601 108.7 8.5 106.122 8.5 L 43.882 8.5 C 41.304 8.5 39.213 10.601 39.213 13.191 L 39.213 14.878 L 15.685 14.878 C 13.107 14.878 11.016 16.975 11.016 19.569 L 11.016 35.35 C 11.016 52.438 24.006 66.536 40.582 68.205 C 44.448 81.792 56.086 92.117 70.333 93.993 L 70.333 107.809 C 70.333 110.399 72.424 112.5 75.002 112.5 C 77.58 112.5 79.671 110.399 79.671 107.809 L 79.671 93.993 C 93.919 92.117 105.557 81.792 109.422 68.205 C 125.999 66.536 138.984 52.438 138.984 35.35 L 138.984 19.569 C 138.984 16.975 136.897 14.878 134.319 14.878 L 134.319 14.878 Z" id="Fill-25" fill="#FEFEFE"></path>
    <path d="M 106.123 132.5 L 43.883 132.5 C 41.305 132.5 39.215 134.515 39.215 137 C 39.215 139.485 41.305 141.5 43.883 141.5 L 106.123 141.5 C 108.701 141.5 110.792 139.485 110.792 137 C 110.792 134.515 108.701 132.5 106.123 132.5" id="Fill-27" fill="#FEFEFE"></path>
    <path d="M 92.73 125.5 C 95.308 125.5 97.395 123.261 97.395 120.5 C 97.395 117.739 95.308 115.5 92.73 115.5 L 57.276 115.5 C 54.698 115.5 52.607 117.739 52.607 120.5 C 52.607 123.261 54.698 125.5 57.276 125.5 L 92.73 125.5 Z" id="Fill-29" fill="#FEFEFE"></path>
    <path d="M 75.002 32.5 L 71.478 41.988 L 61.952 45.502 L 71.478 49.012 L 75.002 58.5 L 78.362 49.462 C 78.469 49.175 78.693 48.952 78.977 48.845 L 88.055 45.502 L 78.529 41.988 L 75.002 32.5 Z" id="Fill-31" fill="#FEFEFE"></path>
  </svg>

  <p>
    <ng-container *ngIf="type === 'reward'">
      {{object.title}}
    </ng-container>
    <ng-container *ngIf="type === 'extra_points_event'">
      +{{object.extra_points}} Punkte
    </ng-container>
    <ng-container *ngIf="type === 'points_multiplier_event'">
      {{object.multiplier}}-Fach Punkte
    </ng-container>
  </p>
</div>
