<div class="ratio-container">
  <div class="overlay" *ngIf="winningRelation" [@overlayAnimation]>
    <app-wheel-of-luck-overlay [theme]="posExperience.config.theme" [winning]="winningRelation" [nextSubject]="nextClicked"></app-wheel-of-luck-overlay>
  </div>
    <div class="left-side">
      <div class="heading">
        <div class="brand">
          <img src="assets/img/logo.svg" />
          <h6>Mankido</h6>
        </div>
          <h1>Glücksrad</h1>
      </div>

      <div class="sidebar">
        <prize-description [type]="posExperience.config?.jackpot.type" [object]="getRelationObject(posExperience.config?.jackpot.type)"></prize-description>
      </div>

      <div class="play-button">
        <button class="btn btn-primary" (click)="onClick()"><img style="height: 0.75em; vertical-align: baseline;" [src]="'assets/img/play.svg'" /> Play</button>
      </div>
    </div>

    <div class="right-side">
      <div class="wheel">
        <ng-content></ng-content>
      </div>
      <div class="wheel-subtext">
        <p>Glücksrad ist Simulation</p>
        <p>Gewinnchance</p>
        <img style="height: 1em;" [src]="'assets/img/info.svg'" />
      </div>
    </div>
</div>
