import {Injectable} from '@angular/core';
import {Campaign} from '../../models/campaign';
import {filter, take, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {CampaignService} from '../../services/campaign.service';
import {ContentBundle} from '../../../content-bundle/content-bundle';

@Injectable({
  providedIn: 'root'
})
export class CampaignWizardStoreService {

  private campaignId: number;
  private campaign$ = new BehaviorSubject<Campaign>(null);

  constructor(private campaignService: CampaignService) {
  }

  get(id: string | number): Observable<Campaign> {
    const campaignId = parseInt(id.toString(), 10);

    if (this.campaignId == null || this.campaignId !== campaignId) {
      this.fetchCampaign(campaignId);
    }

    return this.getFilteredObservable(campaignId);
  }

  update(campaign: Campaign) {
    this.campaign$.next(campaign);
    this.campaignId = campaign.id;
  }

  updateContentBundle(contentBundle: ContentBundle) {
    if (contentBundle.parent_type !== 'Campaign') {
      console.error('Parent is not of type Campaign');
      return;
    }
    this.get(contentBundle.parent_id).pipe(
      take(1),
      tap((campaign: Campaign) => {
        campaign.content_bundle = contentBundle;
        this.update(campaign);
      })
    ).subscribe();
  }

  get latest(): Observable<Campaign> {
    return this.campaign$.asObservable();
  }

  private fetchCampaign(campaignId: number): void {
    this.campaignService.fetch(campaignId).subscribe(campaign => {
      this.update(campaign);
    });
  }

  private getFilteredObservable(campaignId: number): Observable<Campaign> {
    return this.campaign$
      .asObservable()
      .pipe(filter(campaign => campaign != null && campaign.id === campaignId));
  }
}
