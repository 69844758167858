import {ContentBundle} from '../../content-bundle/content-bundle';
import {SpecialPointsEvent} from '../../special-points-events/special-points-event';
import {ContentBundleFactory} from '../../content-bundle/services/content-bundle-factory';
import {ContentElementData} from '../models/content-element';

export class SpecialPointsEventCampaignFactory {

  public static buildInformationWithSpecialPointsEventCampaign(original: ContentBundle): ContentBundle {
    const contentBundle = original ? original : new ContentBundle({});

    this.setDestroyOnContentElements(contentBundle);
    const text = this.getTitleAndDescription(contentBundle);
    const specialPointsEvent = this.getSpecialPointsEvent(contentBundle);

    contentBundle.content_elements = contentBundle.content_elements.concat(
      ContentBundleFactory.buildForInformationWithSpecialPointsEventCampaign(text.title, text.description, specialPointsEvent)
        .content_elements
    );

    return contentBundle;
  }

  public static buildDealWithSpecialPointsEventCampaign(original: ContentBundle): ContentBundle {
    const contentBundle = original ? original : new ContentBundle({});

    this.setDestroyOnContentElements(contentBundle);
    const text = this.getTitleAndDescription(contentBundle);
    const specialPointsEvent = this.getSpecialPointsEvent(contentBundle);

    contentBundle.content_elements = contentBundle.content_elements.concat(
      ContentBundleFactory.buildForDealWithSpecialPointsEvent(text.title, text.description, specialPointsEvent).content_elements
    );

    return contentBundle;
  }

  public static updateText(contentBundle: ContentBundle, senderName: string): ContentBundle {
    const specialPointsEvent = contentBundle.content_elements.find(ce => ce.special_points_event).special_points_event;
    for (const contentElement of contentBundle.content_elements) {
      if (contentElement.element_type === 'heading2') {
        contentElement.data = new ContentElementData({
          text: this.updateTitle(contentElement.data.text, specialPointsEvent)
        });
      }
      if (contentElement.element_type === 'text') {
        contentElement.data = new ContentElementData({
          text: this.updateDescription(contentElement.data.text, specialPointsEvent, senderName)
        });
      }
      if (contentElement.element_type === 'deal') {
        contentElement.deal.description = this.updateDescription(contentElement.deal.description, specialPointsEvent, senderName);
        contentElement.deal.title = this.updateTitle(contentElement.deal.title, specialPointsEvent);
      }
    }
    return contentBundle;
  }

  private static getTitleAndDescription(contentBundle: ContentBundle): { title: string, description: string } {

    let title = '';
    let description = '';
    for (const contentElement of contentBundle.content_elements) {
      if (contentElement.element_type === 'heading2') {
        title = contentElement.data.text;
      }
      if (contentElement.element_type === 'text') {
        description = contentElement.data.text;
      }
      if (contentElement.element_type === 'deal') {
        title = contentElement.deal.title;
        description = contentElement.deal.description;
      }
    }

    return {title, description};
  }

  private static getSpecialPointsEvent(contentBundle: ContentBundle): SpecialPointsEvent {
    let specialPointsEvent = contentBundle.content_elements.find(ce => ce.special_points_event)?.special_points_event;
    if (!specialPointsEvent) {
      specialPointsEvent = new SpecialPointsEvent({});
    }
    delete specialPointsEvent.id;
    for (const recurringEvent of specialPointsEvent.recurring_events) {
      delete recurringEvent.id;
    }
    return specialPointsEvent;
  }

  private static setDestroyOnContentElements(contentBundle: ContentBundle): void {
    for (const contentElement of contentBundle.content_elements) {
      contentElement._destroy = true;
    }
  }

  private static updateTitle(title: string, specialPointsEvent: SpecialPointsEvent): string {
    const headingRegex = /(^$)|^(([0-9]{1,4} Extra-Punkte)|([0-9]{1,2}-Fach Punkte)|(\+[0-9]{2}% Punkte))( auf deinen Einkauf(| ab [0-9]{1,4}€))$/;
    if (title && title !== '' && !headingRegex.test(title)) {
      return title;
    }
    if (specialPointsEvent.type === 'extra_points_event') {
      if (specialPointsEvent.extra_points_threshold === 0) {
        return `${specialPointsEvent.extra_points} Extra-Punkte auf deinen Einkauf`;
      } else {
        return `${specialPointsEvent.extra_points} Extra-Punkte auf deinen Einkauf ab ${specialPointsEvent.extra_points_threshold}€`;
      }
    } else if (specialPointsEvent.type === 'points_multiplier_event') {
      if (specialPointsEvent.multiplier >= 2) {
        return `${specialPointsEvent.multiplier}-Fach Punkte auf deinen Einkauf`;
      } else {
        return `+${Math.round((specialPointsEvent.multiplier - 1) * 100)}% Punkte auf deinen Einkauf`;
      }
    } else {
      return '';
    }
  }

  private static updateDescription(description: string, specialPointsEvent: SpecialPointsEvent, senderName: string): string {
    const contentRegex = /(^$)|^((Hol dir [0-9]{1,4} Extra-Punkte)|(Hol dir [0-9]{1,2}-Fach Punkte)|(Hol dir \+[0-9]{2}% Punkte))( auf deinen nächsten Einkauf(| ab [0-9]{1,4}€) bei uns.\nWir freuen uns auf deinen Besuch.\nDein .* Team)$/m;
    if (description && description !== '' && !contentRegex.test(description)) {
      return description;
    }
    if (specialPointsEvent.type === 'extra_points_event') {
      if (specialPointsEvent.extra_points_threshold === 0) {
        return `Hol dir ${specialPointsEvent.extra_points} Extra-Punkte auf deinen nächsten Einkauf bei uns.`
          + `\nWir freuen uns auf deinen Besuch.\nDein ${senderName} Team`;
      } else {
        return `Hol dir ${specialPointsEvent.extra_points} Extra-Punkte auf deinen nächsten Einkauf ab ${specialPointsEvent.extra_points_threshold}€ bei uns.`
          + `\nWir freuen uns auf deinen Besuch.\nDein ${senderName} Team`;
      }
    } else if (specialPointsEvent.type === 'points_multiplier_event') {
      if (specialPointsEvent.multiplier >= 2) {
        return `Hol dir ${specialPointsEvent.multiplier}-Fach Punkte auf deinen nächsten Einkauf bei uns.`
          + `\nWir freuen uns auf deinen Besuch.\nDein ${senderName} Team`;
      } else {
        return `Hol dir +${Math.round((specialPointsEvent.multiplier - 1) * 100)}% Punkte auf deinen nächsten Einkauf bei uns.`
          + `\nWir freuen uns auf deinen Besuch.\nDein ${senderName} Team`;
      }
    } else {
      return '';
    }
  }


}
