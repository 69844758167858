<div>
  <ng-container *ngIf="event">
    <div class="spacer content-padding" [style.height.px]="30"></div>

    <h1 class="content-padding">Hat dir dein Besuch bei uns gefallen?</h1>

    <div class="spacer" [style.height.px]="15"></div>

    <p class="content-padding">
      Wir schätzen dich sehr als Kunden und möchten dir immer das bestmögliche Kauferlebnis bieten. Bewerte uns jetzt auf Google und sag uns wie dir dein Einkauf gefallen
      hat!
    </p>

    <div class="spacer mb-1" [style.height.px]="30"></div>
  </ng-container>

  <ng-container *ngFor="let tpb of getEvent()?.third_party_businesses; let last = last">
    <app-button [label]="'Bewertung abgeben'" [url]="tpb.new_review_url"></app-button>
    <div class="mb-1" *ngIf="!last"></div>
  </ng-container>

  <div class="spacer" [style.height.px]="50" *ngIf="event"></div>
</div>
