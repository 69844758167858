import {AudienceDefinition} from './audience-definition';
import {MessageStat} from './message-stat';
import {ContentElement} from './content-element';
import {Partner} from '../../partner/partner';
import {Deal} from '../../deals/deal';
import {SpecialPointsEvent} from '../../special-points-events/special-points-event';
import {ObtainReviewEvent} from '../../obtain-review/obtain-review-event';
import {ContentBundle} from '../../content-bundle/content-bundle';
import {ReferralEvent} from '../../referral/referral-event';

export class Campaign {
  static campaign_goals: Object = {
    information: 'Nachricht',
    deal: 'Angebot',
    referral: 'Kunden werben Kunden'
  };

  static statuses: Object = {
    pristine: 'Neu',
    reviewing: 'In Review',
    reviewed: 'Freigegeben',
    sending: 'Sendet',
    sent: 'Fertig'
  };

  static delivery_channels: Object = {
    intelligent: 'Intelligent',
    email: 'Nur Email',
    app: 'Nur App'
  };

  static delivery_periods: Object = {
    intellisend: 'Intelligent',
    immediately: 'Sofort',
    scheduledsend: 'Geplant'
  };

  id: number;
  campaign_goal: string;
  status: 'draft' | 'pristine';
  subject_line: string;
  delivery_period: 'immediately' | 'scheduledsend';
  scheduledsend_at: Date;
  created_at: Date;
  sent_at: Date;
  revenues: { email: number, mobile: number, total: number, email_count: number, mobile_count: number };
  redeemers: number;
  reservers: number;
  total_redirects: number;
  total_obtained_reviews: number;
  stats: any;

  human_title: string;

  content_bundle_id: number;

  sender: Partner;
  audience_definition: AudienceDefinition;
  message_stat: MessageStat;

  content_bundle: ContentBundle;

  constructor(values: Partial<Campaign> = {}) {
    this.id = values.id;
    this.campaign_goal = values.campaign_goal;
    this.status = values.status;
    this.subject_line = values.subject_line;
    this.delivery_period = values.delivery_period;
    this.scheduledsend_at = values.scheduledsend_at ? new Date(values.scheduledsend_at) : null;
    this.created_at = values.created_at ? new Date(values.created_at) : null;
    this.sent_at = values.sent_at ? new Date(values.sent_at) : null;
    this.revenues = values.revenues;
    this.redeemers = values.redeemers;
    this.reservers = values.reservers;
    this.total_redirects = values.total_redirects;
    this.total_obtained_reviews = values.total_obtained_reviews;
    this.stats = values.stats;

    this.human_title = values.human_title;

    this.content_bundle_id = values.content_bundle_id;

    this.sender = values.sender ? new Partner(values.sender) : null;
    this.audience_definition = values.audience_definition ? new AudienceDefinition(values.audience_definition) : null;
    this.message_stat = values.message_stat ? new MessageStat(values.message_stat) : null;

    this.content_bundle = values.content_bundle ? new ContentBundle(values.content_bundle) : null;
  }

  get special_points_event(): SpecialPointsEvent {
    if (!this.content_bundle) {
      return null;
    }
    return this.content_bundle.content_elements.find(ce => ce.special_points_event)?.special_points_event;
  }

  set special_points_event(specialPointsEvent: SpecialPointsEvent) {
    const contentElement = this.content_bundle.content_elements.find(ce => ce.special_points_event);
    contentElement.special_points_event = specialPointsEvent;
  }

  get obtain_review_event(): ObtainReviewEvent {
    if (!this.content_bundle) {
      return null;
    }
    return this.content_bundle.content_elements.find(ce => ce.obtain_review_event)?.obtain_review_event;
  }

  get deal(): Deal {
    if (!this.content_bundle) {
      return null;
    }
    return this.content_bundle.content_elements.find(ce => ce.deal)?.deal;
  }

  get referral_event(): ReferralEvent {
    if (!this.content_bundle) {
      return null;
    }
    return this.content_bundle.content_elements.find(ce =>ce.element_type === 'referral_event')?.referral_event;
  }

  valid(): boolean {
    if (!this.targetValid() || (this.special_points_event && !this.special_points_event.valid()) || !this.contentValid()) {
      return false;
    }

    if (this.campaign_goal === 'information'
      && this.content_bundle.content_elements
        .filter(ce => !ce._destroy)
        .filter(ce => ce.element_type.startsWith('heading')).length === 0) {
      return false;
    }

    if (!Campaign.delivery_periods.hasOwnProperty(this.delivery_period)) {
      return false;
    }

    if (this.delivery_period === 'immediately') {
      return true;
    }

    if (this.delivery_period === 'scheduledsend') {
      return this.isScheduledsendValid();
    }
  }

  isScheduledsendValid(scheduledsend = null) {
    if (scheduledsend === null) {
      scheduledsend = this.scheduledsend_at;
      if (scheduledsend == null) {
        return false;
      }
    }
    const minTimeBefore = 24 * 60 * 60 * 1000;
    if (this.special_points_event != null) {
      const startsAt = this.special_points_event.recurring_events[0].starts_at;
      return startsAt.getTime() >= (scheduledsend.getTime() + minTimeBefore) && scheduledsend.getTime() > new Date().getTime();
    } else if (this.campaign_goal === 'deal') {
      const deal = this.deal;
      let relevantDate = deal.starts_at;

      // check if the deal starts immediately, if so use the ends_at date for validation
      if (relevantDate.getTime() < new Date().getTime()) {
        relevantDate = deal.ends_at;
      }
      return relevantDate.getTime() >= (scheduledsend.getTime() + minTimeBefore) && scheduledsend.getTime() > new Date().getTime();
    }
    return scheduledsend.getTime() > new Date().getTime();
  }

  goalValid(): boolean {
    return Campaign.campaign_goals.hasOwnProperty(this.campaign_goal);
  }

  targetValid(): boolean {
    return this.audience_definition && this.audience_definition.valid();
  }

  contentValid(): boolean {
    return this.content_bundle.content_elements != null
      && this.content_bundle.content_elements.length > 0
      && this.content_bundle.content_elements.every((ce: ContentElement) => ce.valid());
  }

  get emailOpenUniq(): number {
    return this.revenues.email_count;
  }

  get mobileReadUniq(): number {
    return this.revenues.mobile_count;
  }

  get uniqueRecipients(): number {
    return this.message_stat?.messages_successful_count || this.audience_definition.size;
  }

  get uniqueReadCount(): number {
    return this.message_stat?.messages_read_count ?? 0;
  }

  public asRequest(): Partial<Campaign> | any {
    return {
      campaign_goal: this.campaign_goal,
      delivery_period: this.delivery_period,
      scheduledsend_at: this.scheduledsend_at,
      status: this.status,
      audience_definition_attributes: this.audience_definition ? this.audience_definition.asRequest() : null,
    };
  }
}
