import {Observable, ReplaySubject, Subject, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {PosExperience} from './pos_experience';
import {catchError, map, switchMap, tap, throttleTime} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Params} from '@angular/router';
import {PosExperienceStatus} from './pos-experience-status';

@Injectable({
  providedIn: 'root'
})
export class PosExperienceService {
  public URL: string = environment.api_base_url + 'pos_experiences';

  status$ = new ReplaySubject<PosExperienceStatus>(1);
  fetchService$ = new Subject<void>();

  constructor(private http: HttpClient) {
    this.fetchService$.pipe(throttleTime(100), switchMap(() => this.fetchStatus())).subscribe();
  }

  fetch(type: string): Observable<PosExperience> {
    return this.http.get(`${this.URL}/${type}`)
        .pipe(
            map(res => new PosExperience({...res, type: type})),
            catchError(this.handleError)
        );
  }

  update(type: string, pos_experience: PosExperience): Observable<PosExperience> {
    return this.http.put(`${this.URL}/${type}`, this.prepareData(pos_experience))
      .pipe(
        map(res => new PosExperience({...res, type: type})),
        catchError(this.handleError)
      );
  }

  getStatus(): Observable<PosExperienceStatus> {
    this.fetchService$.next();
    return this.status$.asObservable();
  }

  fetchStatus(): Observable<any> {
    return this.http.get(`${this.URL}/status`).pipe(
      map((res: PosExperienceStatus) => new PosExperienceStatus(res)),
      tap(status => this.status$.next(status)),
      catchError(this.handleError)
    );
  }

  private prepareData(posExperience: PosExperience): object {
    const params: Params = {pos_experience: {...posExperience}};
    params.pos_experience.recurring_events_attributes = posExperience.recurring_events;
    delete params.pos_experience.recurring_events;
    return params;
  }

  private handleError(error: any) {
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return observableThrowError(errMsg);
  }
}
