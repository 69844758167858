<div class="container">
  <div class="row">
    <div class="col-sm-12">
      <div class="text-sm-center" style="margin-top: 100px">
        <h1>Zugriff verweigert</h1>
        <p class="lead">Die gewünschte Seite konnte leider nicht geladen werden, da du nicht über die notwendigen Rechte verfügst.</p>
      </div>
    </div>
  </div>
</div>
