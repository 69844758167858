import {Component, Input, OnInit} from '@angular/core';
import {PosExperienceService} from '../pos_experience.service';
import {tap} from 'rxjs/operators';
import {PosExperience, PosExperienceType} from '../pos_experience';

@Component({
  selector: 'app-pos-experience-switch',
  templateUrl: './pos-experience-switch.component.html',
  styleUrls: ['./pos-experience-switch.component.scss']
})
export class PosExperienceSwitchComponent implements OnInit {
  @Input()
  type: PosExperienceType;

  active: boolean;
  present: boolean;
  active_count = 0;
  draft_count = 0;

  types = PosExperienceType;

  constructor(private posExperienceService: PosExperienceService) {
  }

  ngOnInit(): void {
    this.posExperienceService.getStatus().pipe(
      tap(status => {
        if (this.type !== PosExperienceType.special_points_event) {
          this.active = status[this.type].active;
          this.present = status[this.type].present;
        } else {
          this.active_count = status.special_points_event.active_count;
          this.draft_count = status.special_points_event.draft_count;
        }
      })
    ).subscribe();
  }

  onChange($event) {
    this.posExperienceService.update(this.type, {state: $event ? 'active' : 'inactive'} as PosExperience).subscribe();
  }
}
