import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReferralPromotionComponent} from './referral-promotion/referral-promotion.component';



@NgModule({
  declarations: [ReferralPromotionComponent],
  exports: [
    ReferralPromotionComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ReferralModule { }
