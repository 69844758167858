export class RecurringEvent {
  id: number;
  rule: RecurringEventRule;
  starts_at: Date = null;
  ends_at: Date = null;
  _destroy: boolean;

  constructor(values: Partial<RecurringEvent> = {}) {
    this.id = values.id;
    this.rule = values.rule;
    if (values.starts_at != null) {
      this.starts_at = new Date(values.starts_at);
    }
    if (values.ends_at != null) {
      this.ends_at = new Date(values.ends_at);
    }
    this._destroy = values._destroy;
  }

  public asRequest(): Partial<RecurringEvent> {
    return {
      id: this.id,
      rule: this.rule,
      starts_at: this.starts_at,
      ends_at: this.ends_at,
      _destroy: this._destroy
    };

  }
}

export type RecurringEventRule = 'permanent' | 'singular' | 'daily' | 'weekly' | 'monthly' | 'yearly';
