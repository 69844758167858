import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ObtainReviewPromotionComponent} from './obtain-review-promotion/obtain-review-promotion.component';



@NgModule({
  declarations: [
    ObtainReviewPromotionComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [ObtainReviewPromotionComponent]
})
export class ObtainReviewModule { }
