<div class="wol-container" [ngClass]="posExperience.config.theme">
  <div class="wol-container-inside">
      <div class="ratio-11 pull-down">
        <div class="wol-wheel" style="" #wheelRotation>
          <svg height="100%" width="100%" viewBox="0 0 1000 1000"
               style="">
            <g>
              <g app-slice [prize]="posExperience.config.jackpot" [relations]="posExperience.relations" [attr.transform]="'rotate(' + 45 * 0 + ' 500 500)'" (click)="onPrizeClick.next(posExperience.config.jackpot)"></g>

              <g app-slice [prize]="posExperience.config.second" [relations]="posExperience.relations" [attr.transform]="'rotate(' + 45 * 4 + ' 500 500)'" (click)="onPrizeClick.next(posExperience.config.second)"></g>

              <g app-slice [prize]="posExperience.config.third" [relations]="posExperience.relations" [attr.transform]="'rotate(' + 45 * 2 + ' 500 500)'" (click)="onPrizeClick.next(posExperience.config.third)"></g>
              <g app-slice [prize]="posExperience.config.third" [relations]="posExperience.relations" [attr.transform]="'rotate(' + 45 * 6 + ' 500 500)'" (click)="onPrizeClick.next(posExperience.config.third)"></g>


              <g app-slice [prize]="posExperience.config.consolation" [relations]="posExperience.relations" [attr.transform]="'rotate(' + 45 * 1 + ' 500 500)'" (click)="onPrizeClick.next(posExperience.config.consolation)"></g>
              <g app-slice [prize]="posExperience.config.consolation" [relations]="posExperience.relations" [attr.transform]="'rotate(' + 45 * 3 + ' 500 500)'" (click)="onPrizeClick.next(posExperience.config.consolation)"></g>
              <g app-slice [prize]="posExperience.config.consolation" [relations]="posExperience.relations" [attr.transform]="'rotate(' + 45 * 5 + ' 500 500)'" (click)="onPrizeClick.next(posExperience.config.consolation)"></g>
              <g app-slice [prize]="posExperience.config.consolation" [relations]="posExperience.relations" [attr.transform]="'rotate(' + 45 * 7 + ' 500 500)'" (click)="onPrizeClick.next(posExperience.config.consolation)"></g>

            </g>

            <g *ngIf="logo">
              <mask id="circle-mask">
                <circle cx="500" cy="500" r="126" stroke="black" stroke-width="0" fill="white"/>
              </mask>
              <image  [attr.xlink:href]="logo" x="374" y="374" width="252" height="252" mask="url(#circle-mask)"/>
            </g>
          </svg>
        </div>
      </div>

      <div class="ratio-11 pull-up" style="pointer-events: none">
        <div class="wol-needle">
          <svg height="100%" width="100%" viewBox="0 0 1000 1000"
               style="will-change: transform; transform-origin: 50% 10%;"
               #needleRotation>
            <!-- Generator: Sketch 64 (93537) - https://sketch.com -->
            <title>Mankido_Rad-Pfeil-Pos500x100</title>
            <desc>Created with Sketch.</desc>
            <g id="Mankido_Rad-Pfeil-Pos500x100" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <path
                d="M482.925628,136.219149 L517.074372,136.219149 L502.977139,249.75582 C502.772984,251.400048 501.274573,252.567458 499.630345,252.363303 C498.266547,252.193968 497.192197,251.119617 497.022861,249.75582 L482.925628,136.219149 L482.925628,136.219149 Z"
                id="Path-2" fill="#FF5757"></path>
              <circle id="Oval" fill="#FFFFFF" cx="500" cy="100" r="45"></circle>
            </g>
          </svg>
        </div>
    </div>
  </div>
  <div class="wol-shadow ratio-11 pull-down"></div>
</div>
