import {AddonTypes} from './addon-types';

export class AddonSubscription {
  id: number;
  addon_type: AddonTypes;
  partner_id: number;
  subscribed_at: Date;
  unsubscribed_at: Date;

  public constructor(object: Partial<AddonSubscription> = {}) {
    this.id = object.id;
    this.partner_id = object.partner_id;

    this.addon_type = AddonTypes[object.addon_type.toUpperCase()];

    if (object.subscribed_at) {
      this.subscribed_at = new Date(object.subscribed_at);
    }
    if (object.unsubscribed_at) {
      this.unsubscribed_at = new Date(object.unsubscribed_at);
    }
  }

  public canUse(addonType: AddonTypes) {
    const at = new Date();
    return this.addon_type === addonType
      && this.subscribed_at <= at
      && (this.unsubscribed_at == null || this.unsubscribed_at >= at);
  }
}
