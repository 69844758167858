import {WebshopVoucherBatch} from '../../webshops/webshop_voucher_batch';

export class Reward {
  id: number;
  title = '';
  points: number;
  discontinued_at: Date = null;
  voucher_list_file_content: string;
  webshop_voucher_batch: WebshopVoucherBatch;

  constructor(values: Object = {}) {
    Object.assign(this, values);
    if ((values as any).webshop_voucher_batch) {
      this.webshop_voucher_batch = new WebshopVoucherBatch((values as any).webshop_voucher_batch);
    }
  }
}
