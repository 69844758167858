import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrixEditorComponent } from './trix-editor/trix-editor.component';
import {FormsModule} from '@angular/forms';



@NgModule({
  declarations: [TrixEditorComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    TrixEditorComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class TrixModule { }
