import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdventCalendarComponent} from './advent-calendar.component';
import {HeadcontentModule} from '../../../shared/head-content/headcontent.module';
import {FormsModule} from '@angular/forms';
import {UiSwitchModule} from 'ngx-ui-switch';


@NgModule({
  declarations: [
    AdventCalendarComponent
  ],
  exports: [
    AdventCalendarComponent
  ],
  imports: [
    CommonModule,
    HeadcontentModule,
    FormsModule,
    UiSwitchModule
  ]
})
export class AdventCalendarModule {
}
