import {Component, Input, OnInit} from '@angular/core';
import {ContentElement} from '../../../campaigns/models/content-element';

@Component({
  selector: 'app-heading1',
  templateUrl: './heading1.component.html',
  styleUrls: ['./heading1.component.scss']
})
export class Heading1Component implements OnInit {

  @Input()
  ce: ContentElement;

  @Input()
  text: string;

  constructor() { }

  ngOnInit(): void {
  }

  getText() {
    if (this.ce) {
      return this.ce.data.text;
    } else {
      return this.text;
    }
  }

}
