import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Campaign} from '../../../models/campaign';
import {Router} from '@angular/router';
import {NotificationsService} from '../../../../shared/notifications/notifications.service';
import {CampaignService} from '../../../services/campaign.service';
import {AddonSubscriptionService} from '../../../../addons/addon-subscription.service';
import {Subject} from 'rxjs';
import {AddonTypes} from '../../../../addons/addon-types';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-progressbar',
  templateUrl: 'campaign-progressbar.component.html',
  styleUrls: ['campaign-progressbar.component.scss']
})
export class CampaignProgressbarComponent implements OnInit, OnDestroy {
  @Input() campaign: Campaign;
  @Input() state: string;
  title: string;

  hasActiveObtainReviewSubscription = true;

  private ngUnsubscribe = new Subject();

  constructor(private router: Router,
              private notifications: NotificationsService,
              private campaignService: CampaignService,
              private addonSubscriptionService: AddonSubscriptionService) {
  }

  ngOnInit(): void {
    this.addonSubscriptionService.hasActiveSubscriptionFor(AddonTypes.OBTAIN_REVIEW).pipe(
      tap(hasActive => this.hasActiveObtainReviewSubscription = hasActive)
    ).subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  previousEnabled() {
    if (this.campaign.obtain_review_event) {
      return this.state === 'verify';
    } else {
      return true;
    }
  }

  goToPrevious(state = null) {
    if (state == null) {
      state = this.state;
    }
    switch (state) {
      case ('target') :
        return this.goToNew();
      case ('event') :
        return (this.isValidFor('target') ? this.goToTarget() : this.goToNew());
      case ('content') :
        if (this.campaign.special_points_event != null) {
          return (this.isValidFor('event') ? this.goToEvent() : this.goToPrevious('event'));
        } else {
          return (this.isValidFor('target') ? this.goToTarget() : this.goToNew());
        }
      case ('verify') :
        return (this.isValidFor('content') ? this.goToContent() : this.goToPrevious('content'));
        break;
      default:
    }
  }

  isValidFor(state: 'target' | 'event' | 'content' | 'verify') {
    if (this.campaign.obtain_review_event != null) {
      switch (state) {
        case('content'):
          return true;
        case('verify'):
          return this.campaign.targetValid();
      }
    }

    switch (state) {
      case('target'):
        return true;
      case('event'):
        return this.campaign.targetValid();
      case('content'):
        return (this.campaign.special_points_event != null ? this.campaign.special_points_event.valid() : this.campaign.targetValid());
      case('verify'):
        return this.campaign.contentValid();
    }
  }

  nextEnabled(): boolean {
    if (this.campaign.obtain_review_event != null) {
      if (!this.hasActiveObtainReviewSubscription) {
        return false;
      }
    }

    switch (this.state) {
      case ('target') :
        return this.campaign.targetValid();
      case ('event') :
        return this.campaign.special_points_event.valid();
      case ('content') :
        return this.campaign.contentValid();
      case ('verify') :
        return this.campaign.valid();
      default:
        return false;
    }
  }

  goToNext() {
    switch (this.state) {
      case ('target') :
        if (this.campaign.special_points_event != null) {
          return this.goToEvent();
        } else if (this.campaign.obtain_review_event != null) {
          return this.goToVerify();
        } else {
          return this.goToContent();
        }
      case ('event') :
        return this.goToContent();
      case ('content') :
        return this.goToVerify();
      case ('verify') :
      default:
    }
  }

  goToNew() {
    this.router.navigate(['/campaigns/new']);
  }

  goToTarget() {
    this.router.navigate(['/campaigns', this.campaign.id, 'wizard', 'target']);
  }

  goToEvent() {
    this.router.navigate(['/campaigns', this.campaign.id, 'wizard', 'event']);
  }

  goToContent() {
    if ((!this.campaign.special_points_event && this.campaign.targetValid())
      || (this.campaign.special_points_event && this.campaign.special_points_event.valid())) {
      this.router.navigate(['/campaigns', this.campaign.id, 'wizard', 'content']);
    } else {
      if (!this.campaign.special_points_event && this.campaign.targetValid()) {
        this.notifications.error('Bitte wähle eine Kundengruppe aus.');
      } else {
        this.notifications.error('Bitte fülle alle notwendigen Angaben aus.');
      }
    }
  }

  goToVerify() {
    if (this.campaign.contentValid()) {
      this.router.navigate(['/campaigns', this.campaign.id, 'wizard', 'verify']);
    } else {
      this.notifications.error('Bitte fülle alle notwendigen Angaben aus.');
    }
  }

  send() {
    this.campaign.status = 'pristine';
    this.campaignService.update(this.campaign).subscribe(success => {
      this.router.navigate(['/campaigns']);
      this.notifications.success('Deine Kampagne wurde eingereicht.');
    }, error => {
      this.campaign.status = 'draft';
      if (error.error?.referral_event) {
        this.notifications.error('Deine Kampagne konnte nicht gespeichert werden, da die Automatisierung "Kunden werben Kunden" dafür aktiviert sein muss.');
      } else {
        this.notifications.error('Deine Kampagne konnte nicht gespeichert werden.');
      }
    });
  }

  saveAndExit() {
    this.campaignService.update(this.campaign).subscribe(success => {
      this.router.navigate(['/campaigns']);
      this.notifications.success('Deine Kampagne wurde als Entwurf gespeichert.');
    }, error => {
      this.notifications.error('Deine Kampagne konnte nicht gespeichert werden.');
    });
  }
}
