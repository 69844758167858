import {RecurringEvent} from './recurring-event';

export class RecurringEventFactory {
  public static buildSingularRule(): RecurringEvent {
    // set minute and seconds to 0 to prevent 'expression has changed after it was checked' error
    // in SpecialPointsEventEditor component
    const now = new Date().setMinutes(0, 0, 0);
    const oneHourInMs = 1 * 60 * 60 * 1000;
    return new RecurringEvent({
      rule: 'singular',
      starts_at: new Date(now + oneHourInMs),
      ends_at: new Date(now + 2 * oneHourInMs)
    });
  }

  public static buildDailyRule(): RecurringEvent {
    return new RecurringEvent({
      rule: 'daily',
      starts_at: new Date(),
      ends_at: new Date(new Date().getTime() + 60 * 60 * 1000)
    })
  }
}
