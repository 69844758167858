<div class="custom-automation-switch"
     [class.justify-content-md-around]="!present && float === 'center'"
     [class.justify-content-md-end]="!present && float === 'right'">
  <ng-container *ngIf="present">
    <div class="switch-with-status">
      <ui-switch [(ngModel)]="active" (change)="onChange($event)" size="medium" [disabled]="disabled"></ui-switch>
      <span class="text-muted">{{active ? 'aktiv' : 'deaktiviert'}}</span>
    </div>
    <a [routerLink]="[link]"
       class="btn ml-1" [ngClass]="active ? 'btn-primary' : 'btn-secondary'">{{present ? "Bearbeiten" : "Jetzt anlegen"}}</a>
  </ng-container>
  <a [routerLink]="[link]" class="btn btn-primary" *ngIf="!present">Jetzt anlegen</a>
</div>
