import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {GameAnimation} from '../wheel-of-luck/animations/game-animation';
import {WheelOfLuckComponent} from '../wheel-of-luck/wheel-of-luck/wheel-of-luck.component';
import {debounceTime, take} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {WheelConfiguration} from '../wheel-of-luck/models/wheel-configuration';
import {Partner} from '../partner/partner';
import {PartnerService} from '../partner/partner.service';
import {PosExperience, PosExperienceType} from '../pos-experiences/pos_experience';
import {PosExperienceService} from '../pos-experiences/pos_experience.service';
import {Router} from '@angular/router';
import {Prize, PrizeLevel} from '../wheel-of-luck/models/prize';
import {PreviewAnimation} from '../wheel-of-luck/animations/preview-animation';
import {PrizeEditorComponent} from './prize-editor/prize-editor.component';
import {WheelOfLuckGameComponent} from '../wheel-of-luck/wheel-of-luck-game/wheel-of-luck-game.component';

@Component({
  selector: 'app-wheel-of-luck-editor',
  templateUrl: './wheel-of-luck-editor.component.html',
  styleUrls: ['./wheel-of-luck-editor.component.scss']
})
export class WheelOfLuckEditorComponent implements OnInit, OnDestroy {
  private debouncedEvent = new Subject<WheelConfiguration>();
  private configurationSaved = new Subject<PosExperience>();
  public firstVisibleEditor = new Subject<number[]>();
  highlightedForm: PrizeLevel = 'jackpot';

  currentPartner: Partner;

  @Input()
  posExperience: PosExperience;

  @ViewChild('wheel', {static: true}) wheel: WheelOfLuckComponent;

  @ViewChild('game', {static: true}) game: WheelOfLuckGameComponent;

  @ViewChildren(PrizeEditorComponent, {read: ElementRef})
  prizeEditors: QueryList<ElementRef>;

  animations = [];
  invalidOdds = false;

  spinResult: string;

  private subs = new Subscription();

  constructor(private partnerService: PartnerService, private posExperienceService: PosExperienceService, private router: Router) {
  }

  ngOnInit() {
    this.subs.add(
        this.debouncedEvent.pipe(debounceTime(500)).subscribe((wheelConfig) => {
          if (!this.posExperience.valid() || this.invalidOdds) {
            return;
          }
          this.posExperienceService.update(PosExperienceType.wheel_of_luck, this.prepareRequestObject()).subscribe((posExperience) => {
            this.posExperience = posExperience;
            this.configurationSaved.next(posExperience);
          });
        })
    );

    this.subs.add(
        this.partnerService.currentPartner.subscribe(partner => {
          this.currentPartner = partner;
        })
    );

    this.subs.add(
        this.firstVisibleEditor.pipe(debounceTime(200)).subscribe((index) => {
          this.game.reset();
          const animation = PreviewAnimation.createTurnAnimations(index, this.wheel.getWheelAngle());
          if (animation[0].distance !== 0) {
            if (animation[0].distance > 180) {
              animation[0].distance -= 360;
            }
            this.animations = animation;
          }
          this.highlightedForm = (index.indexOf(0) >= 0 ? 'jackpot' : this.highlightedForm);
          this.highlightedForm = (index.indexOf(1) >= 0 ? 'consolation' : this.highlightedForm);
          this.highlightedForm = (index.indexOf(2) >= 0 ? 'third' : this.highlightedForm);
          this.highlightedForm = (index.indexOf(4) >= 0 ? 'second' : this.highlightedForm);
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onChange() {
    this.onPrizeChange();
    this.debouncedEvent.next(this.posExperience.config);
  }

  onPrizeChange() {
    const cfg = this.posExperience.config;
    const consolationOdds = 100 - (cfg.jackpot.odds + cfg.second.odds + cfg.third.odds);
    this.invalidOdds = (consolationOdds < 0);
    this.posExperience.config.consolation.odds = consolationOdds;
  }

  onFinish() {
    this.animations = PreviewAnimation.createTurnAnimations([0], this.wheel.getWheelAngle());
    this.game.reset();
  }

  spinWheel() {
    // return this.animations = [{duration: 60, easing: (x) => x, distance: 360 * 22}];
    this.animations = GameAnimation.createTurnAnimations(1, this.wheel.getWheelAngle());
    this.wheel.animationFinished.pipe(take(1)).subscribe(($event) => {
      this.animations = GameAnimation.createNeedleEasingAnimation($event.wheelAngle, $event.needleAngle);
      if (this.animations == null || this.animations.length === 0) {
        this.spinResult = '🎉 Gewonnen!';
        setTimeout(() => this.spinResult = null, 2000);
      } else {
        this.wheel.animationFinished.pipe(take(1)).subscribe((event) => {
          this.spinResult = '🎉 Gewonnen!';
          setTimeout(() => this.spinResult = null, 2000);
        });
      }
    });
  }

  toggleTheme() {
    if (this.posExperience.config.theme === 'light-theme') {
      this.posExperience.config.theme = 'dark-theme';
    } else {
      this.posExperience.config.theme = 'light-theme';
    }
    this.onChange();
  }

  statusChanged($event) {
    this.posExperience.state = ($event ? 'active' : 'inactive');
    this.onChange();
  }

  isActive() {
    return this.posExperience.state === 'active';
  }

  saveAndExit() {
    this.onChange();
    this.subs.add(
        this.configurationSaved.pipe(take(1)).subscribe((posExperience) => {
          this.router.navigate(['pos_experiences']);
        })
    );
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    const childs = this.prizeEditors.map(pe => {
      const startAt = pe.nativeElement.offsetTop;
      const endAt = startAt + pe.nativeElement.children[0].offsetHeight;
      const offsetTop = (document.getElementsByClassName("prize-editors")[0] as HTMLElement).offsetTop;
      const currentPosition = (document.documentElement.scrollTop || document.body.scrollTop) - offsetTop;
      const isInView = currentPosition <= startAt;
      const isCompleteVisible = currentPosition > startAt && currentPosition <= endAt;
      return isInView;
    });
    for (let i = 0; i < childs.length; i++) {
      if (childs[i]) {
        if (i === 0) {
          this.firstVisibleEditor.next([0]);
        } else if (i === 1) {
          this.firstVisibleEditor.next([4]);
        } else if (i === 2) {
          this.firstVisibleEditor.next([2, 6]);
        } else if (i === 3) {
          this.firstVisibleEditor.next([1, 3, 5, 7]);
        }
        break;
      }
    }
  }

  public scrollIntoView(prize: Prize) {
    const offsetTop = (document.getElementsByClassName("prize-editors")[0] as HTMLElement).offsetTop;
    const pes = this.prizeEditors.toArray();
    let top = 0;
    if (prize.level === 'jackpot') {
      top = pes[0].nativeElement.offsetTop;
    } else if (prize.level === 'second') {
      top = pes[1].nativeElement.offsetTop;
    } else if (prize.level === 'third') {
      top = pes[2].nativeElement.offsetTop;
    } else if (prize.level === 'consolation') {
      top = pes[3].nativeElement.offsetTop;
    }
    window.scrollTo({top: top + offsetTop, behavior: 'smooth'});
  }

  private prepareRequestObject(): PosExperience {
    const request = JSON.parse(JSON.stringify(this.posExperience));
    request.relations_attributes = request.relations;
    delete request.relations;
    for (const relation of request.relations_attributes) {
      relation.object_attributes = {...relation.object};
      delete relation.object;
    }
    return request as PosExperience;
  }
}
