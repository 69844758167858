import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NouisliderComponent} from './nouislider';


@NgModule({
  declarations: [NouisliderComponent],
  exports: [NouisliderComponent],
  imports: [
    CommonModule
  ]
})
export class NouisliderModule { }
