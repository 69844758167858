import {Easings} from './easings';

export class GameAnimation {

  static setWheelInMotionDistance = 3 * 45;
  static baseRotationDistance = 3 * 360;


  public static createTurnAnimations(pieceIndex, wheelAngle) {


    const rotationStart = wheelAngle + this.setWheelInMotionDistance;
    const rotationDistance = (360 - (rotationStart % 360) + (8 - pieceIndex) * 45) + this.baseRotationDistance + Math.random() * 44 - 22;

    return [
      {duration: 1, distance: this.setWheelInMotionDistance, easing: Easings.easeInBack},
      {duration: 9, distance: rotationDistance, easing: Easings.easeOutCubic}
    ];
  }

  public static createNeedleEasingAnimation(wheelAngle, needleAngle) {
    if (!!needleAngle && needleAngle !== 0) {
      const normalizedAngle = (wheelAngle + 22.5) % 45;
      const wheelAngleStart = wheelAngle;
      const distance = (needleAngle / 30) * 22.5;

      const factor = Math.abs(distance / 22.5);

      return [{duration: 1 + 2 * factor, distance: distance, easing: Easings.easeInOutSine}];
    }
  }
}
