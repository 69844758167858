<div class="app" *ngIf="getDeal()">
  <ng-container [ngSwitch]="getDeal().deal_type">
    <app-asset [url]="getDeal().asset?.url_email" *ngSwitchDefault></app-asset>

    <app-special-points-event-preview [specialPointsEvent]="contentBundle.special_points_event"
                                      *ngSwitchCase="'special_points_event'"></app-special-points-event-preview>
  </ng-container>

  <div class="deal-value-container content-padding">
    <h3>Angebot</h3>
    <span class="deal-value" *ngIf="getDeal().deal_type !== 'special_points_event'">{{ getDeal() | dealvalue }}</span>
  </div>

  <h2 class="deal-title">{{getDeal().title || "Überschrift"}}</h2>

  <div *ngIf="getDeal().ends_at" class="deal-alarm-container content-padding">
    <img class="deal-alarm" src="assets/img/deal-alarm-ionic.svg" alt="">
    <h6 [innerHTML]="getDeal() | dealDuration | newline"></h6>
  </div>

  <div class="content-padding" style="overflow: hidden;">
    <app-trix-text [text]="getDeal().description"></app-trix-text>
  </div>

  <div class="deal-details content-padding">
    <h4>Einzelheiten</h4>

    <div class="detail-item">
      <div class="detail-item-icon">
        <img src="assets/icon/deal-terms-ionic.svg" alt="Deal Bedingungen">
      </div>
      <div class="detail-item-text">
        <h5>Bedingungen</h5>
        <span>{{ getDeal().terms || '-' }}</span>
      </div>
    </div>

    <div class="detail-item">
      <div class="detail-item-icon">
        <img src="assets/icon/deal-next-steps-ionic.svg" alt="Nächste Schritte">
      </div>
      <div class="detail-item-text">
        <h5>Nächste Schritte</h5>
        <span>Nutze Mankido bei {{ partnerName() }} und das Angebot wird automatisch eingelöst.</span>
      </div>
    </div>
  </div>

  <div class="deal-button-container">

    <div class="deal-button">
      <span>RESERVIEREN</span>
    </div>
  </div>
</div>

<div class="mail">
  <app-spacer [size]="30"></app-spacer>

  <h3 class="content-padding deal-title">{{ getDeal().title || "Überschrift" }}</h3>

  <app-spacer [size]="5"></app-spacer>

  <p class="content-padding deal-alarm-container" [innerHTML]="getDeal() | dealDuration | newline"></p>

  <app-spacer [size]="25"></app-spacer>

  <ng-container [ngSwitch]="getDeal().deal_type">
    <app-asset [url]="getDeal().asset?.url_email" *ngSwitchDefault></app-asset>

    <app-special-points-event-preview [specialPointsEvent]="ce.special_points_event"
                                      *ngSwitchCase="'special_points_event'"></app-special-points-event-preview>
  </ng-container>

  <app-spacer [size]="30"></app-spacer>

  <app-trix-text class="content-padding" [text]="getDeal().description"></app-trix-text>

  <app-spacer [size]="30"></app-spacer>

  <div class="deal-button-container">
    <a class="btn deal-button" target="_blank">
      Jetzt reservieren
    </a>
  </div>

  <app-spacer [size]="40"></app-spacer>

  <hr>
  <div class="deal-terms">
    <app-spacer [size]="15"></app-spacer>
    <div class="content-padding">
      <strong>Bedingungen: </strong>
      <span>{{ getDeal().terms }}</span>
    </div>
    <app-spacer [size]="15"></app-spacer>
  </div>
</div>
