import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChange} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';

import {Calendar} from './calendar';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {throttleTime} from 'rxjs/operators';
import {fromEvent} from 'rxjs';

type DateFormatFunction = (date: Date) => string;

interface ValidationResult {
  [key: string]: boolean;
}

@Component({
  selector: 'datepicker',
  animations: [
    trigger('calendarAnimation', [
      transition('* => left', [
        animate(180, keyframes([
          style({transform: 'translateX(105%)', offset: 0.5}),
          style({transform: 'translateX(-130%)', offset: 0.51}),
          style({transform: 'translateX(0)', offset: 1})
        ]))
      ]),
      transition('* => right', [
        animate(180, keyframes([
          style({transform: 'translateX(-105%)', offset: 0.5}),
          style({transform: 'translateX(130%)', offset: 0.51}),
          style({transform: 'translateX(0)', offset: 1})
        ]))
      ])
    ])
  ],
  styleUrls: ['./datepicker.component.scss'],
  templateUrl: './datepicker.component.html'
})
export class DatepickerComponent implements OnInit, OnChanges {
  dateVal: Date;
  // two way bindings
  @Output() dateChange = new EventEmitter<Date>();
  // api bindings
  @Input() disabled: boolean;
  @Input() accentColor: string;
  @Input() altInputStyle: boolean;
  @Input() fontFamily: string;
  @Input() rangeStart: Date;
  @Input() rangeEnd: Date;
  // data
  @Input() placeholder = 'Datum auswählen';
  @Input() inputText: string;
  // view logic
  @Input() showCalendar: boolean;
  // events
  @Output() onSelect = new EventEmitter<Date>();
  // time
  @Input() calendarDays: Array<number>;
  @Input() currentMonth: string;
  @Input() dayNames: Array<String>;
  @Input() hoveredDay: Date;
  calendar: Calendar;
  currentMonthNumber: number;
  currentYear: number;
  months: Array<string>;
  // animation
  animate: string;
  // colors
  colors: { [id: string]: string };
  // listeners
  clickListener: Function;
  // forms
  yearControl: FormControl;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    // subscribe to 'document:click' manually to 'throttle clicks'
    // (in chrome the event gets fired twice, the second with a wrong target element):
    fromEvent(document, 'click').pipe(throttleTime(0)).subscribe((event: MouseEvent) => this.clickOutside(event));

    // view logic
    this.showCalendar = false;
    // colors
    this.colors = {
      'black': '#333333',
      'blue': '#00a4e3',
      'lightGrey': '#f1f1f1',
      'white': '#ffffff'
    };
    this.accentColor = this.colors['blue'];
    this.altInputStyle = false;
    // time
    this.calendar = new Calendar(1);
    this.dayNames = ['M', 'D', 'M', 'D', 'F', 'S', 'S'];
    this.months = [
      'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli',
      'August', 'September', 'Oktober', 'November', ' Dezember'
    ];
    // listeners
    this.clickListener = renderer.listen(
        'document',
        'click',
        (event: MouseEvent) => this.handleGlobalClick(event)
    );
    // form controls
    this.yearControl = new FormControl('', Validators.compose([
      Validators.required,
      Validators.maxLength(4),
      this.yearValidator,
      this.inRangeValidator.bind(this)
    ]));
  }

  @Input() get date(): Date {
    return this.dateVal;
  }

  set date(val: Date) {
    this.dateVal = val;
    this.dateChange.emit(val);
  }

  clickOutside($event) {
    if (this.showCalendar) {
      try {
        let datepickerClicked = false;
        let node = $event.target;
        while (!datepickerClicked) {
          if (node == null) {
            break;
          }
          datepickerClicked = node.classList.contains('datepicker_calendar');
          node = node.parentElement;
        }
        if (!datepickerClicked) {
          this.closeCalendar();
        }
      } catch (e) {
        console.log('click outside could not be detected correctly');
      }
    }
  }

  ngOnInit() {
    this.syncVisualsWithDate();
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    if (changes['date']) {
      this.syncVisualsWithDate();
    }
  }

  ngOnDestroy() {
    this.clickListener();
  }

  // -------------------------------------------------------------------------------- //
  // -------------------------------- State Management ------------------------------ //
  // -------------------------------------------------------------------------------- //
  /**
   * Closes the calendar and syncs visual components with selected or current date.
   * This way if a user opens the calendar with this month, scrolls to two months from now,
   * closes the calendar, then reopens it, it will open with the current month
   * or month associated with the selected date
   */
  closeCalendar(): void {
    this.showCalendar = false;
    this.syncVisualsWithDate();
  }

  /**
   * Visually syncs calendar and input to selected date or current day
   */
  syncVisualsWithDate(): void {
    if (this.date) {
      let date: any = this.date;
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      this.setInputText(date);
      this.setCurrentValues(date);
    } else {
      this.inputText = '';
      this.setCurrentValues(new Date());
    }
  }

  /**
   * Sets the currentMonth and creates new calendar days for the given month
   */
  setCurrentMonth(monthNumber: number): void {
    this.currentMonth = this.months[monthNumber];
    const calendarArray = this.calendar.monthDays(this.currentYear, this.currentMonthNumber);
    this.calendarDays = [].concat.apply([], calendarArray);
  }

  /**
   * Sets the currentYear and FormControl value associated with the year
   */
  setCurrentYear(year: number): void {
    this.currentYear = year;
    this.yearControl.setValue(year);
  }

  /**
   * Sets the visible input text
   */
  setInputText(date: Date): void {
    let month: string = (date.getMonth() + 1).toString();
    // always prefixes one digit numbers with a 0
    if (month.length < 2) {
      month = `0${month}`;
    }
    let day: string = (date.getDate()).toString();
    if (day.length < 2) {
      day = `0${day}`;
    }
    // transforms input text into appropiate date format
    const inputText = `${day}.${month}.${date.getFullYear()}`;
    this.inputText = inputText;
  }

  // -------------------------------------------------------------------------------- //
  /**
   * Sets the date values associated with the calendar.
   * Triggers animation if the month changes
   */
  onArrowClick(direction: string): void {
    const currentMonth: number = this.currentMonthNumber;
    let newYear: number = this.currentYear;
    let newMonth: number;
    // sets the newMonth
    // changes newYear is necessary
    if (direction === 'left') {
      if (currentMonth === 0) {
        newYear = this.currentYear - 1;
        newMonth = 11;
      } else {
        newMonth = currentMonth - 1;
      }
    } else if (direction === 'right') {
      if (currentMonth === 11) {
        newYear = this.currentYear + 1;
        newMonth = 0;
      } else {
        newMonth = currentMonth + 1;
      }
    }
    // check if new date would be within range
    const newDate = new Date(newYear, newMonth);
    let newDateValid: boolean;
    if (direction === 'left') {
      newDateValid = !this.rangeStart || newDate.getTime() >= this.rangeStart.getTime();
    } else if (direction === 'right') {
      newDateValid = !this.rangeEnd || newDate.getTime() <= this.rangeEnd.getTime();
    }

    if (newDateValid) {
      this.setCurrentYear(newYear);
      this.currentMonthNumber = newMonth;
      this.setCurrentMonth(newMonth);
      this.triggerAnimation(direction);
    }
  }

  // -------------------------------------------------------------------------------- //
  // --------------------------------- Click Handlers ------------------------------- //

  /**
   * Closes the calendar when the cancel button is clicked
   */
  onCancel(): void {
    this.closeCalendar();
  }

  /**
   * Toggles the calendar when the date input is clicked
   */
  onInputClick(): void {
    setTimeout(() => this.showCalendar = !this.showCalendar, 0);
  }

  /**
   * Returns the font color for a day
   */
  onSelectDay(day: Date): void {
    this.date = day;
    this.onSelect.emit(day);
    this.showCalendar = !this.showCalendar;
  }

  /**
   * Sets the current year and current month if the year from
   * yearControl is valid
   */
  onYearSubmit(): void {
    if (this.yearControl.valid && +this.yearControl.value !== this.currentYear) {
      this.setCurrentYear(+this.yearControl.value);
      this.setCurrentMonth(this.currentMonthNumber);
    } else {
      this.yearControl.setValue(this.currentYear);
    }
  }

  // -------------------------------------------------------------------------------- //
  /**
   * Closes the calendar if a click is not within the datepicker component
   */
  handleGlobalClick(event: MouseEvent): void {
    const withinElement = this.elementRef.nativeElement.contains(event.target);
    // if (!this.elementRef.nativeElement.contains(event.target)) {
    //   this.closeCalendar();
    // }
  }

  // -------------------------------------------------------------------------------- //
  // ----------------------------------- Listeners ---------------------------------- //

  // -------------------------------------------------------------------------------- //
  /**
   * Returns the background color for a day
   */
  getDayBackgroundColor(day: Date): string {
    let color = this.colors['white'];
    if (this.isChosenDay(day)) {
      color = this.accentColor;
    } else if (this.isCurrentDay(day)) {
      color = this.colors['lightGrey'];
    }
    return color;
  }

  // -------------------------------------------------------------------------------- //
  // ----------------------------------- Helpers ------------------------------------ //

  /**
   * Returns the font color for a day
   */
  getDayFontColor(day: Date): string {
    let color = this.colors['black'];
    if (this.isChosenDay(day)) {
      color = this.colors['white'];
    }
    return color;
  }

  /**
   * Returns whether a day is the chosen day
   */
  isChosenDay(day: Date): boolean {
    if (day) {
      let date: any = this.date;
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      return date ? day.toDateString() === date.toDateString() : false;
    } else {
      return false;
    }
  }

  /**
   * Returns whether a day is the current calendar day
   */
  isCurrentDay(day: Date): boolean {
    if (day) {
      return day.toDateString() === new Date().toDateString();
    } else {
      return false;
    }
  }

  /**
   * Returns whether a day is the day currently being hovered
   */
  isHoveredDay(day: Date): boolean {
    return this.hoveredDay ? this.hoveredDay === day && !this.isChosenDay(day) : false;
  }

  /**
   * Triggers an animation and resets to initial state after the duration of the animation
   */
  triggerAnimation(direction: string): void {
    this.animate = direction;
    setTimeout(() => this.animate = 'reset', 185);
  }

  // -------------------------------------------------------------------------------- //
  /**
   * Validates that a value is within the 'rangeStart' and/or 'rangeEnd' if specified
   */
  inRangeValidator(control: FormControl): ValidationResult {
    const value = control.value;

    if (this.currentMonthNumber) {
      const tentativeDate = new Date(+value, this.currentMonthNumber);
      if (this.rangeStart && tentativeDate.getTime() < this.rangeStart.getTime()) {
        return {'yearBeforeRangeStart': true};
      }
      if (this.rangeEnd && tentativeDate.getTime() > this.rangeEnd.getTime()) {
        return {'yearAfterRangeEnd': true};
      }
      return null;
    }

    return {'currentMonthMissing': true};
  }

  // -------------------------------------------------------------------------------- //
  // ---------------------------------- Validators ---------------------------------- //

  /**
   * Validates that a value is a number greater than or equal to 1970
   */
  yearValidator(control: FormControl): ValidationResult {
    const value = control.value;
    const valid = !isNaN(value) && value >= 1970 && Math.floor(value) === +value;
    if (valid) {
      return null;
    }
    return {'invalidYear': true};
  }

  /**
   * Sets the date values associated with the ui
   */
  private setCurrentValues(date: Date) {
    this.currentMonthNumber = date.getMonth();
    this.currentMonth = this.months[this.currentMonthNumber];

    this.currentYear = date.getFullYear();
    this.yearControl.setValue(this.currentYear);

    const calendarArray = this.calendar.monthDays(this.currentYear, this.currentMonthNumber);
    this.calendarDays = [].concat.apply([], calendarArray);
  }
}
