import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PosExperienceService} from '../pos_experience.service';
import {PosExperience, PosExperienceType} from '../pos_experience';
import {NotificationsService} from '../../shared/notifications/notifications.service';

@Component({
  selector: 'app-pos-experience-edit',
  templateUrl: './pos-experience-edit.component.html',
  styleUrls: ['./pos-experience-edit.component.scss']
})
export class PosExperienceEditComponent implements OnInit {
  showEdit = false;
  valid = false;
  type: PosExperienceType;
  posExperience: PosExperience;
  PosExperienceType = PosExperienceType;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private notifications: NotificationsService,
              private posExperienceService: PosExperienceService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.type = params.type;
      if (this.type.toString() === PosExperienceType.special_points_event) {
        this.showEdit = true;
      } else {
        this.posExperience = new PosExperience({type: this.type});
        this.posExperienceService.fetch(this.type).subscribe(pose => {
          this.showEdit = true;
          this.posExperience = pose;
        });
      }
    });
  }

  onValid($event) {
    this.valid = $event;
  }

  onSave() {
    this.posExperienceService.update(this.type, this.posExperience).subscribe(
      () => {
        this.router.navigate(['/pos_experiences']);
        this.notifications.success('Änderungen gespeichert.');
      },
      (error) => {
        this.notifications.error('Leider ist ein Fehler beim Speichern aufgetreten. Der Mankido Support hilft gerne weiter.');
      }
    );
  }

  onStart() {
    this.showEdit = true;
  }
}
