<ng-container *ngIf="viewFor === 'inviter'">
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 1600 1002">
    <defs>
      <filter id="b" width="138.6%" height="194.3%" x="-19.3%" y="-34.7%" filterUnits="objectBoundingBox">
        <feOffset dy="44" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="48"/>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
      </filter>
      <filter id="d" width="206.4%" height="206.4%" x="-53.2%" y="-39.2%" filterUnits="objectBoundingBox">
        <feOffset dy="42" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="46"/>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
      </filter>
      <circle id="c" cx="149.424" cy="149.424" r="149.424"/>
      <circle id="e" cx="118.156" cy="118.156" r="118.156"/>
      <rect id="a" width="859.1" height="352" x="0" y="0" rx="60.5"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <rect width="1600" height="1002" fill="#F8F8F8"/>
      <g transform="translate(370.28 107.858)">
        <use xlink:href="#a" fill="#000" filter="url(#b)"/>
        <use xlink:href="#a" fill="#FFF"/>
        <ng-container *ngIf="specialPointsEvent.extra_points >= 100">
          <text font-family="Ubuntu-Regular, Ubuntu" font-size="234.907">
            <tspan x="93.226" y="258.279" fill="#54C97F">+</tspan>
            <tspan x="250.714" y="258.279" fill="#24282D" font-family="Ubuntu-Bold, Ubuntu" font-weight="bold">{{specialPointsEvent.extra_points}}</tspan>
          </text>
          <text fill="#24282D" font-family="Ubuntu-Bold, Ubuntu" font-size="110.247" font-weight="bold">
            <tspan x="674.628" y="173.279">P</tspan>
          </text>
        </ng-container>
        <ng-container *ngIf="specialPointsEvent.extra_points < 100 && specialPointsEvent.extra_points >= 10">
          <text font-family="Ubuntu-Regular, Ubuntu" font-size="234.907">
            <tspan x="150" y="258.279" fill="#54C97F">+</tspan>
            <tspan x="320" y="258.279" fill="#24282D" font-family="Ubuntu-Bold, Ubuntu" font-weight="bold">{{specialPointsEvent.extra_points}}</tspan>
          </text>
          <text fill="#24282D" font-family="Ubuntu-Bold, Ubuntu" font-size="110.247" font-weight="bold">
            <tspan x="610" y="173.279">P</tspan>
          </text>
        </ng-container>
        <ng-container *ngIf="specialPointsEvent.extra_points < 10">
          <text font-family="Ubuntu-Regular, Ubuntu" font-size="234.907">
            <tspan x="250" y="258.279" fill="#54C97F">+</tspan>
            <tspan x="420" y="258.279" fill="#24282D" font-family="Ubuntu-Bold, Ubuntu" font-weight="bold">{{specialPointsEvent.extra_points}}</tspan>
          </text>
          <text fill="#24282D" font-family="Ubuntu-Bold, Ubuntu" font-size="110.247" font-weight="bold">
            <tspan x="590.628" y="173.279">P</tspan>
          </text>
        </ng-container>
        <rect width="400" height="102.279" fill="#54C97F" rx="51.139" transform="translate(230 313)"/>
        <text fill="#FFF" font-family="Ubuntu-Bold, Ubuntu" font-size="50.907" font-weight="bold">
          <tspan x="278.143" y="382.5">Extrapunkte</tspan>
        </text>
      </g>

      <!-- Center -->
      <g transform="translate(650.407 590.417)">
        <mask id="partner-logo" fill="#fff">
          <circle cx="133" cy="133" r="133"/>
        </mask>
        <use xlink:href="#c" fill="#000" filter="url(#d)"/>
        <use xlink:href="#c" fill="#FFF"/>
        <image [attr.xlink:href]="specialPointsEvent?.partner?.logo" width="266" height="266" x="0" y="0" mask="url(#partner-logo)" transform="translate(16.5, 16.5)"/>
      </g>

      <!-- Right -->
      <g transform="translate(1142.905 621.684)">
        <mask id="h" fill="#fff">
          <use xlink:href="#e"/>
        </mask>
        <circle cx="118.156" cy="118.156" r="126.868" stroke="#54C97F" stroke-width="17.424"/>
        <circle cx="118.156" cy="118.156" r="123.493" stroke="#FFF" stroke-width="10.672"/>
        <image xlink:href="assets/img/referral/card/person-right.png" width="236.312" height="369.624" x="0" y="-92"/>
      </g>
      <!-- Left -->
      <g transform="translate(220.782 621.684)">
        <mask id="f" fill="#fff">
          <use xlink:href="#e"/>
        </mask>
        <circle cx="118.156" cy="118.156" r="126.868" stroke="#54C97F" stroke-width="17.424"/>
        <circle cx="118.156" cy="118.156" r="123.493" stroke="#FFF" stroke-width="10.672"/>
        <circle cx="118.156" cy="118.156" r="126.2" stroke="#54C97F" stroke-width="13" mask="url(#f)"/>
        <image href="assets/img/referral/card/person-left.png" width="236.312" height="369.624" x="0" y="-92"/>
      </g>
      <line x1="473.22" x2="650.407" y1="739.841" y2="739.841" stroke="#54C97F" stroke-width="6"/>
      <line x1="949.255" x2="1126.442" y1="739.841" y2="739.841" stroke="#54C97F" stroke-width="6"/>
    </g>
  </svg>
</ng-container>
<ng-container *ngIf="viewFor === 'invitee'">
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 1600 1002">
    <defs>
      <filter id="bb" width="138.7%" height="194.5%" x="-19.4%" y="-34.9%" filterUnits="objectBoundingBox">
        <feOffset dy="48" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="53"/>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
      </filter>
      <filter id="dd" width="206.2%" height="206.2%" x="-53.1%" y="-39.1%" filterUnits="objectBoundingBox">
        <feOffset dy="46" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="50.5"/>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
      </filter>
      <circle id="cc" cx="164.366" cy="164.366" r="164.366"/>
      <rect id="aa" width="945.01" height="387.2" x="240.291" y="249.399" rx="66.55"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <rect width="1600" height="1002" fill="#EAEAEA"/>
      <rect width="2010" height="2274" y="-173" fill="#F8F8F8"/>
      <circle cx="808" cy="-173" r="792" fill="#54C97F"/>

      <!-- Points Background -->
      <g transform="translate(87.204 124.34)">
        <path stroke="#2F8861" stroke-width="41.644"
              d="M0,475.983473 C89.3473487,113.182036 185.523961,-41.5670626 288.529838,11.7361775 C443.038653,91.6910376 429.506567,414.603975 528.284896,457.638877 C627.063226,500.67378 1326.74168,-89.6750736 1600.23079,11.7361775 C1782.55686,79.3436782 1871.2768,253.300103 1866.39061,533.605451"/>
        <use xlink:href="#aa" fill="#000" filter="url(#bb)"/>
        <use xlink:href="#aa" fill="#FFF"/>
        <rect width="440" height="112.507" fill="#54C97F" rx="56.253" transform="translate(493.291 593.699)"/>
        <text fill="#FFF" font-family="Ubuntu-Bold, Ubuntu" font-size="55.998" font-weight="bold">
          <tspan x="546.248" y="670.449">Extrapunkte</tspan>
        </text>
      </g>

      <!-- Partner Logo Circle -->
      <g transform="translate(635.634 112.454)">
        <mask id="partner-logo_invitee" fill="#fff">
          <circle cx="147.875" cy="147.875" r="147.875"/>
        </mask>
        <use xlink:href="#cc" fill="#000" filter="url(#dd)"/>
        <use xlink:href="#cc" fill="#FFF"/>
        <image [attr.xlink:href]="specialPointsEvent?.partner?.logo" width="295.75" height="295.75" x="0" y="0" mask="url(#partner-logo_invitee)" transform="translate(16.5, 16.5)"/>
      </g>

      <g fill="#3AB265" transform="translate(1113.288 830.546)">
        <ellipse cx="4.098" cy="4.1" rx="4.098" ry="4.1"/>
        <ellipse cx="4.098" cy="65.604" rx="4.098" ry="4.1"/>
        <ellipse cx="65.574" cy="4.1" rx="4.098" ry="4.1"/>
        <ellipse cx="65.574" cy="65.604" rx="4.098" ry="4.1"/>
        <ellipse cx="127.05" cy="4.1" rx="4.098" ry="4.1"/>
        <ellipse cx="127.05" cy="65.604" rx="4.098" ry="4.1"/>
        <ellipse cx="188.525" cy="4.1" rx="4.098" ry="4.1"/>
        <ellipse cx="188.525" cy="65.604" rx="4.098" ry="4.1"/>
        <ellipse cx="250.001" cy="4.1" rx="4.098" ry="4.1"/>
        <ellipse cx="250.001" cy="65.604" rx="4.098" ry="4.1"/>
        <ellipse cx="311.477" cy="4.1" rx="4.098" ry="4.1"/>
        <ellipse cx="311.477" cy="65.604" rx="4.098" ry="4.1"/>
        <ellipse cx="372.952" cy="4.1" rx="4.098" ry="4.1"/>
        <ellipse cx="372.952" cy="65.604" rx="4.098" ry="4.1"/>
        <ellipse cx="434.428" cy="4.1" rx="4.098" ry="4.1"/>
        <ellipse cx="434.428" cy="65.604" rx="4.098" ry="4.1"/>
        <ellipse cx="4.098" cy="127.108" rx="4.098" ry="4.1"/>
        <ellipse cx="65.574" cy="127.108" rx="4.098" ry="4.1"/>
        <ellipse cx="127.05" cy="127.108" rx="4.098" ry="4.1"/>
        <ellipse cx="188.525" cy="127.108" rx="4.098" ry="4.1"/>
        <ellipse cx="250.001" cy="127.108" rx="4.098" ry="4.1"/>
        <ellipse cx="311.477" cy="127.108" rx="4.098" ry="4.1"/>
        <ellipse cx="372.952" cy="127.108" rx="4.098" ry="4.1"/>
        <ellipse cx="434.428" cy="127.108" rx="4.098" ry="4.1"/>
      </g>
      <ng-container *ngIf="specialPointsEvent.extra_points > 99">
        <polygon fill="#54C97F" fill-rule="nonzero"
                 points="0 47.289 42.931 47.289 42.931 0 55.57 0 55.57 47.289 98.501 47.289 98.501 59.493 55.57 59.493 55.57 107 42.931 107 42.931 59.493 0 59.493"
                 transform="translate(432.408 519.398)"/>
        <text fill="#24282D" font-family="Ubuntu-Bold, Ubuntu" font-size="258.398" font-weight="bold">
          <tspan x="579.845" y="660.046">{{specialPointsEvent.extra_points}}</tspan>
        </text>
        <text fill="#24282D" font-family="Ubuntu-Bold, Ubuntu" font-size="121.272" font-weight="bold">
          <tspan x="1069.586" y="564.046">P</tspan>
        </text>
      </ng-container>
      <ng-container *ngIf="specialPointsEvent.extra_points < 100 && specialPointsEvent.extra_points > 9">
        <polygon fill="#54C97F" fill-rule="nonzero"
                 points="0 47.289 42.931 47.289 42.931 0 55.57 0 55.57 47.289 98.501 47.289 98.501 59.493 55.57 59.493 55.57 107 42.931 107 42.931 59.493 0 59.493"
                 transform="translate(515 519.398)"/>
        <text fill="#24282D" font-family="Ubuntu-Bold, Ubuntu" font-size="258.398" font-weight="bold">
          <tspan x="660" y="660.046">{{specialPointsEvent.extra_points}}</tspan>
        </text>
        <text fill="#24282D" font-family="Ubuntu-Bold, Ubuntu" font-size="121.272" font-weight="bold">
          <tspan x="1000" y="564.046">P</tspan>
        </text>
      </ng-container>
      <ng-container *ngIf="specialPointsEvent.extra_points < 10">
        <polygon fill="#54C97F" fill-rule="nonzero"
                 points="0 47.289 42.931 47.289 42.931 0 55.57 0 55.57 47.289 98.501 47.289 98.501 59.493 55.57 59.493 55.57 107 42.931 107 42.931 59.493 0 59.493"
                 transform="translate(580 519.398)"/>
        <text fill="#24282D" font-family="Ubuntu-Bold, Ubuntu" font-size="258.398" font-weight="bold">
          <tspan x="725" y="660.046">{{specialPointsEvent.extra_points}}</tspan>
        </text>
        <text fill="#24282D" font-family="Ubuntu-Bold, Ubuntu" font-size="121.272" font-weight="bold">
          <tspan x="925" y="564.046">P</tspan>
        </text>
      </ng-container>
    </g>
  </svg>
</ng-container>
