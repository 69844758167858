import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RecurringEventPickerComponent} from './recurring-event-picker.component';
import {NgbButtonsModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import {DateTimePickerModule} from '../../shared/date-time-picker/date-time-picker.module';


@NgModule({
  declarations: [RecurringEventPickerComponent],
  exports: [RecurringEventPickerComponent],
  imports: [
    CommonModule,
    NgbButtonsModule,
    FormsModule,
    DateTimePickerModule
  ]
})
export class RecurringEventPickerModule {
}
