import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-wheel-image',
  templateUrl: './wheel-image.component.html',
  styleUrls: ['./wheel-image.component.scss']
})
export class WheelImageComponent implements OnChanges {

  @Input()
  highlight: 'jackpot' | 'second' | 'third' | 'consolation';

  sliceClasses = ['jackpot', 'consolation', 'third', 'consolation', 'second', 'consolation', 'third', 'consolation'];

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.sliceClasses = ['even', 'odd', 'even', 'odd', 'even', 'odd', 'even', 'odd'];
    if (this.highlight == 'jackpot') {
      this.sliceClasses[0] = 'jackpot';
    } else if (this.highlight == 'second') {
      this.sliceClasses[4] = 'second';
    } else if (this.highlight == 'third') {
      this.sliceClasses[2] = 'third';
      this.sliceClasses[6] = 'third';
    } else if (this.highlight == 'consolation') {
      this.sliceClasses[1] = 'consolation';
      this.sliceClasses[3] = 'consolation';
      this.sliceClasses[5] = 'consolation';
      this.sliceClasses[7] = 'consolation';
    }
  }

  getLeftPoint(radius = 500) {
    const angle = (270 - 45 / 2) * (Math.PI / 180);
    const point = [
      radius * Math.cos(angle) + 500,
      radius * Math.sin(angle) + 500
    ];
    return `${point[0]} ${point[1]}`;
  }

  getRightPoint(radius = 500) {
    const angle = (270 + 45 / 2) * (Math.PI / 180);
    const point = [
      radius * Math.cos(angle) + 500,
      radius * Math.sin(angle) + 500
    ];
    return `${point[0]} ${point[1]}`;
  }

}
