import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ContractsRoutingModule} from './contracts-routing.module';
import {ContractListComponent} from './contract-list/contract-list.component';
import {NgxPaginationModule} from 'ngx-pagination';


@NgModule({
  declarations: [ContractListComponent],
  exports: [ContractListComponent],
  imports: [
    CommonModule,
    ContractsRoutingModule,
    NgxPaginationModule
  ]
})
export class ContractsModule { }
