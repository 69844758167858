import {Injectable} from '@angular/core';
import {PartnerService} from '../partner/partner.service';
import {Partner} from '../partner/partner';
import {MerchantService} from '../merchant/merchant.service';
import {Merchant} from '../merchant/merchant';
import {environment} from '../../environments/environment';
import {JwtHelper} from './jwt/jwthelper';
import {first, map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class AuthService {
  LOGIN_URL: string = environment.api_base_url + 'authenticate';
  RECOVER_URL: string = environment.api_base_url + 'recover_password';

  contentHeader: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
  error: string;
  jwtHelper: JwtHelper = new JwtHelper();

  constructor(private http: HttpClient,
              private partnerService: PartnerService,
              private merchantService: MerchantService) {
  }

  init() {
    if (localStorage.hasOwnProperty('jwt')) {
      this.loadCurrentMerchant();
    }
  }

  public authenticated(): boolean {
    const token: string = localStorage.getItem('jwt');
    return !!token && !this.jwtHelper.isTokenExpired(token);
  }

  public login(credentials) {
    return this.http.post(this.LOGIN_URL, {auth: credentials}, {headers: this.contentHeader})
      .pipe(map((res) => this.authSuccess(res)));
  }

  public logout() {
    localStorage.removeItem('jwt');
    localStorage.removeItem('context');
    localStorage.removeItem('promotion-timestamps');
    localStorage.removeItem('beta.features');
    this.partnerService.setCurrentPartner(new Partner());
    this.merchantService.setCurrentMerchant(new Merchant());
  }

  public tokenLogin(jwt: string) {
    localStorage.setItem('jwt', jwt);
    localStorage.removeItem('context');
    this.setMerchantFromToken(jwt);
  }

  public recoverPassword(username: string) {
    return this.http.post(this.RECOVER_URL, {auth: {username}}, {headers: this.contentHeader});
  }

  private authSuccess(res) {
    this.error = null;
    localStorage.setItem('jwt', res.jwt);
    localStorage.removeItem('context');
    this.setMerchantFromToken(res.jwt);
    return res;
  }

  private loadCurrentMerchant() {
    const token: string = localStorage.getItem('jwt');
    if (token) {
      this.setMerchantFromToken(token);
    }
  }

  private setMerchantFromToken(token: string) {
    const tokenObj = this.jwtHelper.decodeToken(token);
    if (tokenObj.class !== 'Merchant') {
      localStorage.removeItem('jwt');
      localStorage.removeItem('context');
      return;
    }
    const merchantId: number = tokenObj.sub;
    this.merchantService.fetch(merchantId).subscribe(merchant => {
      const context: any = JSON.parse(localStorage.getItem('context')) || merchant.default_context;

      this.merchantService.setCurrentMerchant(merchant);
      this.merchantService.setCurrentContext(context);

      this.partnerService.fetch(context.partner_id || context.id).pipe(first()).subscribe(partner => {
        this.partnerService.setCurrentPartner(partner);
      });
    });
  }
}
