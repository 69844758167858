import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WheelOfLuckEditorComponent} from './wheel-of-luck-editor.component';
import {HeadcontentModule} from '../shared/head-content/headcontent.module';
import {UiSwitchModule} from 'ngx-ui-switch';
import {PrizeEditorModule} from './prize-editor/prize-editor.module';
import {IpadPreviewContainerModule} from '../ipad-preview-container/ipad-preview-container.module';
import {WheelOfLuckModule} from '../wheel-of-luck/wheel-of-luck.module';
import {RecurringEventPickerModule} from '../special-points-events/recurring-event-picker/recurring-event-picker.module';


@NgModule({
  declarations: [
    WheelOfLuckEditorComponent
  ],
  exports: [
    WheelOfLuckEditorComponent
  ],
    imports: [
        CommonModule,
        HeadcontentModule,
        UiSwitchModule,
        PrizeEditorModule,
        IpadPreviewContainerModule,
        WheelOfLuckModule,
        RecurringEventPickerModule
    ]
})
export class WheelOfLuckEditorModule {
}
