import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CampaignListComponent} from './campaign-list/campaign-list/campaign-list.component';
import {CampaignNewComponent} from './campaign-new/campaign-new/campaign-new.component';
import {CampaignVerifyComponent} from './campaign-wizard/campaign-verify/campaign-verify/campaign-verify.component';
import {environment} from '../../environments/environment';

const routes: Routes = [
  {
    path: '',
    component: CampaignListComponent
  },
  {
    path: 'new',
    component: CampaignNewComponent
  },
  {
    path: 'verify',
    component: CampaignVerifyComponent
  },
  {
    path: ':id/wizard',
    loadChildren: () => import('./campaign-wizard/campaign-wizard.module').then(m => m.CampaignWizardModule)
  },
  {
    path: ':id',
    loadChildren: () => import('./campaign-show/campaign-show.module').then(m => m.CampaignShowModule)
  }
];

if (!environment.production) {
  // include this route only in non-production environments,
  // and before :id/wizard and :id routes
  routes.splice(3, 0, {
    path: 'dev',
    loadChildren: () => import('./campaign-dev/campaign-dev.module').then(m => m.CampaignDevModule)
  });
}

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CampaignsRoutingModule {
}
