<div class="row">
  <div class="col" style="font-weight: 600">
    <dl>
      <dt>Zielgruppe:</dt>
      <dd>{{targetName}}</dd>

      <ng-container *ngIf="!isAdvancedMode()">
        <dt>Empfänger:</dt>
        <dd>{{segmentName}} <span>({{audienceSize}} Kunden)</span></dd>
      </ng-container>

      <ng-container *ngIf="isAdvancedMode()">
        <dt>Bisherige Besuche zwischen:</dt>
        <dd>{{frequencyRange}}</dd>

        <dt>Bisheriger Umsatz:</dt>
        <dd>{{volumeRange}}</dd>

        <dt>Angemeldet zwischen:</dt>
        <dd>{{membershipCreatedRange}}</dd>
      </ng-container>
    </dl>

  </div>
</div>
