<div [class.show]="visible" [hidden]="!visible" class="modal-backdrop fade"></div>

<div [class.show]="visible" [hidden]="!visible" aria-hidden="true" aria-labelledby="modalLabel" class="modal fade" id="modal"
     role="dialog" style="display: block;" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="modalLabel">{{modal.title}}</h4>
        <button (click)="close()" aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{modal.content}}
      </div>
      <div class="modal-footer">
        <button (click)="close()" class="btn btn-secondary" data-dismiss="modal" type="button">Abbrechen</button>
        <button (click)="ok()" class="btn {{modal.buttonClass}}" type="button">{{modal.buttonText}}</button>
      </div>
    </div>
  </div>
</div>
