import {Pipe, PipeTransform} from '@angular/core';
import {Deal} from '../../../deals/deal';

/*
 * Converts newlines into breaks
 */
@Pipe({
  name: 'dealvalue',
  pure: false
})
export class DealValuePipe implements PipeTransform {
  transform(deal: Deal): string {
    switch (deal.deal_type) {
      case 'voucher':
        return `${this.centsToEuros(deal.new_price)}€ statt ${this.centsToEuros(deal.old_price)}€`;
      case 'percent':
        return `- ${(deal.percent_amount || 0).toLocaleString() || 0}%`;
      case 'xfory':
        return `${deal.amount_x || 0} für ${deal.amount_y || 0}`;
      case 'fixed':
        return `${this.centsToEuros(deal.fixed_amount)}€`;
      case 'free':
        return `Kostenlos`;
      case 'size':
      case 'largeness':
        return `${deal.new_size || ''} statt ${deal.old_size || ''}`;
      default:
        return '';
    }
  }

  private centsToEuros(amountInCents: number) {
    return ((amountInCents || 0) / 100).toLocaleString('de-DE', {minimumFractionDigits: 2});
  }
}
