import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Campaign} from '../../../../models/campaign';

@Component({
  selector: 'app-campaign-sanity-warnings',
  templateUrl: './campaign-sanity-warnings.component.html',
  styleUrls: ['./campaign-sanity-warnings.component.scss']
})
export class CampaignSanityWarningsComponent implements OnChanges {

  @Input()
  campaign: Campaign;

  showReferralBlacklistOverlap = false;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.campaign) {
      return;
    }

    this.showReferralBlacklistOverlap = this.hasReferralBlacklistOverlap();
  }

  private hasReferralBlacklistOverlap() {
    if (this.campaign.campaign_goal !== 'referral') {
      return false;
    }
    if (this.campaign.audience_definition.segment.startsWith('partner')) {
      return true;
    }
    const referralEvent = this.campaign.referral_event;
    const businessOverlaps = this.campaign.audience_definition.business_ids.filter(bid => {
      return referralEvent.blacklisted_business_ids.includes(bid);
    });
    if (businessOverlaps.length > 0) {
      return true;
    }
  }
}
