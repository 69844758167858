import {ContentElement} from '../models/content-element';
import {Deal} from '../../deals/deal';
import {ReferralEvent} from '../../referral/referral-event';
import {SpecialPointsEventFactory} from '../../special-points-events/special-points-event-factory';

export class ContentElementFactory {
  public static buildSpacer(size: number = 30): ContentElement {
    return new ContentElement({element_type: 'spacer', data: {size}});
  }

  public static buildHeading(level: string, title = ''): ContentElement {
    return new ContentElement({element_type: `heading${level}`, data: {text: title}});
  }

  public static buildText(text = ''): ContentElement {
    return new ContentElement({element_type: 'text', data: {text}});
  }

  public static buildAsset(): ContentElement {
    return new ContentElement({element_type: 'asset', asset: {}});
  }

  public static buildButton(): ContentElement {
    return new ContentElement({element_type: 'button', data: {text: '', link: ''}});
  }

  public static buildDeal(title = '', description = '', specialPointsEvent = null): ContentElement {
    const deal = new Deal({
      starts_at: new Date(new Date().getTime() + 15 * 60 * 1000),
      ends_at: this.inTwoWeeks(),
      title,
      description
    });
    return new ContentElement({element_type: 'deal', deal: deal, special_points_event: specialPointsEvent});
  }

  public static buildSpecialPointsEvent(specialPointsEvent = null): ContentElement {
    return new ContentElement({
      element_type: 'special_points_event',
      special_points_event: specialPointsEvent ? specialPointsEvent : SpecialPointsEventFactory.buildForCampaign()
    });
  }

  public static buildReferral(): ContentElement {
    return new ContentElement({
      element_type: 'referral_event',
      data: {
        title: ReferralEvent.DEFAULT_CAMPAIGN_TITLE,
        text: ReferralEvent.DEFAULT_CAMPAIGN_TEXT
      }
    });
  }

  public static buildObtainReview() {
    return new ContentElement({
        element_type: 'obtain_review_event',
        obtain_review_event: null
      }
    );
  }

  public static addPosition(contentElements: ContentElement[]): ContentElement[] {
    return contentElements.map((ce, index) => {
      ce.position = index + 1;
      return ce;
    });
  }

  private static inTwoWeeks() {
    const now = new Date();
    const nDays = now.valueOf() + 1000 * 60 * 60 * 24 * 14;   // current date's milliseconds + 1,000 ms * 60 s * 60 mins * 24 hrs * 3 days
    return new Date(nDays);
  }
}
