import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WheelOfLuckInfoComponent} from './wheel-of-luck-info.component';
import {WheelOfLuckModule} from '../../wheel-of-luck/wheel-of-luck.module';


@NgModule({
  declarations: [WheelOfLuckInfoComponent],
  exports: [WheelOfLuckInfoComponent],
  imports: [
    CommonModule,
    WheelOfLuckModule
  ]
})
export class WheelOfLuckInfoModule { }
