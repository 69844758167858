<headcontent>
  <div class="title-with-button d-flex flex-column flex-md-row">
    <h1>Adventskalender bearbeiten</h1>
    <div class="d-flex align-items-center mt-1 mt-md-0">
      <span class="text-muted d-none d-md-block" style="margin-right: .25rem">
        Status: <b>{{isActive() ? 'aktiv' : 'deaktiviert'}}</b>
      </span>
      <ui-switch class="d-flex align-items-center" [ngModel]="isActive()" size="small" (change)="statusChanged($event)"
                 [disabled]="!posExperience?.valid()"></ui-switch>
      <div class="btn-group ml-1">
        <button class="btn btn-primary" (click)="save.emit()"
                [disabled]="!posExperience?.valid()">
          Speichern &
          Schließen
        </button>
      </div>
    </div>
  </div>
</headcontent>

<p class="mt-3 mb-2 px-2 text-center small text-muted">
  Beispiele und passendes Bildmaterial für eine Kampagne oder als Wallpaper für das Mankido Terminal haben wir hier bereitgestellt:
  <a href="https://drive.google.com/drive/folders/1JGf-znqnbK7e2-8oIkNOOpTtXJMBlF38?usp=sharing" rel="noreferrer noopener" target="_blank">Herunterladen</a>
</p>

<table class="table campaign-table">
  <tr class="border-bottom border-md-bottom-0" *ngFor="let day of posExperience.config; index as i;">
    <td style="width: 100px; text-align: center;" class="mx-auto">
      <h3 style="padding-top: 9px; height: 38px; margin-bottom: 0;">{{i+1}}.12.</h3>
      <span>{{dayFrom(i+1) | date:'EEEE'}}</span>
    </td>
    <td>
      <select class="form-control" [(ngModel)]="day.surprise_type">
        <option value="points">Extra Punkte</option>
        <option value="free_text">Freitext</option>
      </select>
      <span>Typ</span>
    </td>
    <td>
      <ng-container *ngIf="day.surprise_type === 'free_text'">
        <textarea [(ngModel)]="day.text" class="form-control" rows="2" name="text"></textarea>
        <span>Beschreibung</span>
      </ng-container>
      <ng-container *ngIf="day.surprise_type === 'points'">
        <input type="number" [(ngModel)]="day.points" min="1" step="1" name="points" class="form-control">
        <span>Punkte</span>
      </ng-container>
    </td>
  </tr>
</table>
