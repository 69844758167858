import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SepaMandatesRoutingModule} from './sepa-mandates-routing.module';
import {SepaMandateListComponent} from './sepa-mandate-list/sepa-mandate-list.component';
import {NgxPaginationModule} from 'ngx-pagination';


@NgModule({
  declarations: [SepaMandateListComponent],
  exports: [SepaMandateListComponent],
  imports: [
    CommonModule,
    SepaMandatesRoutingModule,
    NgxPaginationModule
  ]
})
export class SepaMandatesModule {
}
