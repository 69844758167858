export class Asset {
  id: number;
  url: string;
  url_email: string;
  status: number;
  statusText: string;
  progress: Object;
  originalName: string;
  size: number;
  response: string;
  done: boolean;
  error: boolean;
  abort: boolean;

  // constructor(id: number, originalName: string, size: number) {
    // this.id = id;
    // this.originalName = originalName;
    // this.size = size;
    // this.progress = {
    //   loaded: 0,
    //   total: 0,
    //   percent: 0
    // };
    // this.done = false;
    // this.error = false;
    // this.abort = false;
  // }

  constructor(v: Partial<Asset> = {}) {
    Object.assign(this, v);
  }

  setProgres(progress: Object): void {
    this.progress = progress;
  }

  setError(): void {
    this.error = true;
    this.done = true;
  }

  setAbort(): void {
    this.abort = true;
    this.done = true;
  }

  onFinished(status: number, statusText: string, response: string): void {
    this.status = status;
    this.statusText = statusText;
    this.response = response;
    this.done = true;
  }
}
