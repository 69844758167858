import {Observable, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {SepaMandate} from './sepa-mandate';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SepaMandateService {
  SEPA_MANDATES_URL: string = environment.api_base_url + 'sepa_mandates';

  constructor(private http: HttpClient) {
  }

  fetchAll(): Observable<SepaMandate[]> {
    return this.http.get(`${this.SEPA_MANDATES_URL}`)
        .pipe(
            map((res: SepaMandate[]) => res.map(x => new SepaMandate(x))),
            catchError(this.handleError)
        );
  }

  fetchPage(page: number, per: number, order_field: string, order_direction: string): Observable<SepaMandate[]> {
    return this.http.get(`${this.SEPA_MANDATES_URL}?page=${page}&per=${per}&order_field=${order_field}&order_direction=${order_direction}`)
        .pipe(
            map((res: SepaMandate[]) => res.map(x => new SepaMandate(x))),
            catchError(this.handleError)
        );
  }

  fetch(id: number): Observable<SepaMandate> {
    return this.http.get(`${this.SEPA_MANDATES_URL}/${id}`)
        .pipe(
            map(res => new SepaMandate(res)),
            catchError(this.handleError)
        );
  }

  downloadPDF(sepaMandate: SepaMandate): Observable<Blob> {
    return this.http.get(`${this.SEPA_MANDATES_URL}/${sepaMandate.id}/download`, {
      responseType: 'blob'
    }).pipe(
        map(res => new Blob([res], {type: 'image/pdf'})),
        catchError(this.handleError)
    );
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return observableThrowError(errMsg);
  }
}
