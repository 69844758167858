import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DealFormComponent} from './deal-form.component';
import {FormsModule} from '@angular/forms';
import {CurrencyInputModule} from '../../shared/directives/currency-input/currency-input.module';
import {DateTimePickerModule} from '../../shared/date-time-picker/date-time-picker.module';
import {UploaderPlusModule} from '../../assets/uploader-plus/uploader-plus.module';
import {NouisliderModule} from '../../shared/nouislider/nouislider.module';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {TrixModule} from '../../trix/trix.module';


@NgModule({
  declarations: [DealFormComponent],
  exports: [DealFormComponent],
    imports: [
        CommonModule,
        FormsModule,
        CurrencyInputModule,
        DateTimePickerModule,
        UploaderPlusModule,
        NouisliderModule,
        NgxSliderModule,
        TrixModule
    ]
})
export class DealFormModule { }
