import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {Partner} from '../../../partner/partner';
import {PartnerService} from '../../../partner/partner.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-content-bundle-preview-container',
  templateUrl: './content-bundle-preview-container.component.html',
  styleUrls: ['./content-bundle-preview-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentBundlePreviewContainerComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject();

  @Input()
  previewType: 'mail' | 'app' = 'mail';

  @Input()
  previewTypeSwitchable = false;

  partner: Partner;

  constructor(private partnerService: PartnerService) { }

  ngOnInit(): void {
    this.partnerService.currentPartner.pipe(takeUntil(this.ngUnsubscribe)).subscribe(partner => {
      this.partner = partner;
    });
  }

  ngOnDestroy(): void {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
  }

}
