import {Component, Input, OnInit} from '@angular/core';
import {Prize} from '../models/prize';
import {PosExperienceRelation} from '../../pos-experiences/pos_experience';
import {SpecialPointsEvent} from '../../special-points-events/special-points-event';

@Component({
  selector: '[app-slice]',
  templateUrl: './slice.component.html',
  styleUrls: ['./slice.component.scss']
})
export class SliceComponent implements OnInit {

  @Input()
  prize: Prize;

  @Input()
  relations: PosExperienceRelation[];

  // [topLeft, bottomLeft, topRight, bottomRight, topMiddle, bottomMiddle]
  outer: [string, string, string, string, string, string];
  outerDivider: [string, string, string, string, string, string];
  inner: [string, string, string, string, string, string];
  innerDivider: [string, string, string, string, string, string];

  bonusTextHeightMin = '140';
  bonusTextHeightMax = '210';
  bonusTextWidthMin = '490';
  bonuxTextWidthMax = '610';

  constructor() {
    this.outer = this.createCoords(430, 500);
    this.outerDivider = this.createCoords(418, 430);
    this.inner = this.createCoords(151, 418);
    this.innerDivider = this.createCoords(126, 151);
  }

  private createCoords(bottom: number, top: number): [string, string, string, string, string, string] {
    return [this.getLeftPoint(top), this.getLeftPoint(bottom), this.getRightPoint(top), this.getRightPoint(bottom), `${top} ${top}`, `${bottom} ${bottom}`];
  }

  ngOnInit(): void {
  }

  get extraPointsEvent(): SpecialPointsEvent {
    return this.relations.find(x => x.relation_hint === this.prize.level
      && x.object_type === 'SpecialPointsEvent'
      && (x.object as SpecialPointsEvent).type === 'extra_points_event').object as SpecialPointsEvent;
  }

  get pointsMultiplierEvent(): SpecialPointsEvent {
    return this.relations.find(x => x.relation_hint === this.prize.level
      && x.object_type === 'SpecialPointsEvent'
      && (x.object as SpecialPointsEvent).type === 'points_multiplier_event').object as SpecialPointsEvent;
  }

  getLeftPoint(radius = 500) {
    const angle = (270 - 45 / 2) * (Math.PI / 180);
    const point = [
      radius * Math.cos(angle) + 500,
      radius * Math.sin(angle) + 500
    ];
    return `${point[0]} ${point[1]}`;
  }

  getRightPoint(radius = 500) {
    const angle = (270 + 45 / 2) * (Math.PI / 180);
    const point = [
      radius * Math.cos(angle) + 500,
      radius * Math.sin(angle) + 500
    ];
    return `${point[0]} ${point[1]}`;
  }

}
