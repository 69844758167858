<div class="wheel-wrap">
  <app-wheel-of-luck [posExperience]="posExperience" [logo]="currentPartner.logo" [animations]="animations" #wheel></app-wheel-of-luck>
</div>

<h1>Glücksrad</h1>
<p class="lead" style="margin: 30px 50px;">
  Lass jeden Kunden mit einem Glücksgefühl nach Hause gehen!
  Einmal am Glücksrad drehen und mit einem kleinen Sofortgewinn die Kundschaft glücklich machen.
</p>

<p class="mb-4">
  <button type="button" class="btn btn-primary" (click)="start.emit()">Direkt loslegen</button>
  <br>
</p>

<h2 class="mb-2" style="margin-top: 150px">So funktioniert das Glücksrad</h2>

<iframe width="700" height="457" src="https://player.vimeo.com/video/454412444?background=1&autoplay=1&loop=1&quality=1080p" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>

<p class="mt-4">Das Glücksrad erscheint, falls aktiviert, pro Nutzer nur einmal pro Tag.</p>

<div style="background: #fafafa; padding: 100px 3000px;margin: 150px -3000px 0;">
  <h2 class="mb-4">Wähle aus zwei Designvarianten für dein Glücksrad:</h2>

  <div class="row mb-3">
    <div class="col">
      <p class="mb-1 lead">Light Mode / Helles Design</p>
      <div class="wheel-wrap-light" (click)="wheel2.animations = GameAnimation.createTurnAnimations(0, wheel2.getWheelAngle())">
        <app-wheel-of-luck [posExperience]="posExperience" [logo]="currentPartner.logo" [animations]="animations" #wheel2></app-wheel-of-luck>
        <button class="btn btn-info mt-3">Play!</button>
      </div>
    </div>
    <div class="col">
      <p class="mb-1 lead">Dark Mode / Dunkles Design</p>
      <div class="wheel-wrap-dark" (click)="wheel3.animations = GameAnimation.createTurnAnimations(0, wheel3.getWheelAngle())">
        <app-wheel-of-luck [posExperience]="posExperience2" [logo]="currentPartner.logo" [animations]="animations" #wheel3></app-wheel-of-luck>
        <button class="btn btn-secondary mt-3">Play!</button>
      </div>
    </div>
  </div>
</div>
