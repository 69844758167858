export class NeedleAnimator {
  logEnabled = false;
  private HOOK_RADIUS = 15;
  private NEEDLE_THICKNESS = 8;
  private NEEDLE_LENGTH = 150;

  private TENSION_RELEASE_DURATION = 300;
  private TENSION_RELEASE_DURATION_SLOW = 1000;
  // the maximum speed at which the needle gets forced to 0 (in case the animation is either finished or canceld)
  private TENSION_RESET_THRESHOLD = 2;

  private rotationState: null | 'CW' | 'CCW' = null;
  private wheelAngleOfLastFrame = null;

  private tensionReleaseNeedleStart = null;
  private tensionReleaseInProgress = false;
  private tensionReleaseStart = null;

  needleAngle = 0;

  getNeedleAngle() {
    return this.needleAngle;
  }

  public handleWheelAngle(wheelAngle: number): number {
    if (this.wheelAngleOfLastFrame == null) {
      this.log('Initial Call for NeedleAnimation');
      this.rotationState = 'CW';
      this.wheelAngleOfLastFrame = wheelAngle;
    }
    const rotationSpeed = wheelAngle - this.wheelAngleOfLastFrame;
    this.wheelAngleOfLastFrame = wheelAngle;
    const currentSign = Math.sign(rotationSpeed); // is 1 when CW, -1 when CCW
    let animationSign = currentSign;
    if (this.rotationState != null) {
      animationSign = (this.rotationState == 'CW' ? 1 : -1);
    }

    const normalizedWheelAngle = animationSign * this.normalizeWheelAngle(wheelAngle);

    if (normalizedWheelAngle > -2 && normalizedWheelAngle < 8.5) {
      if (this.tensionReleaseInProgress) {
        this.log('Cancel TensionReleaseAnimation!');
        this.rotationState = null;
        this.tensionReleaseInProgress = false;
      }

      if (this.rotationState == null) {
        if ((normalizedWheelAngle > -2 && normalizedWheelAngle < 0) || rotationSpeed > 2 || rotationSpeed < -2) {
          this.rotationState = (currentSign > 0 ? 'CW' : 'CCW');
          this.log('Set rotationState to', this.rotationState, normalizedWheelAngle);
        } else {
          return this.needleAngle;
        }
      }

      const deviationOfHook = this.calcDeviationOfHook(normalizedWheelAngle);
      this.needleAngle = (animationSign * -1) * this.radianToDegree(Math.atan(deviationOfHook / this.NEEDLE_LENGTH));
    } else if (this.rotationState != null) {
      // animate tension release

      // prevent animation and rotationState reset when needle is relaxed
      // without preventing the reset of rotationState, the needle would immediately enter the opposite rotation state
      if (normalizedWheelAngle < 0 && !this.tensionReleaseInProgress) {
        if (rotationSpeed < this.TENSION_RESET_THRESHOLD) { // set the needleAngle to 0 if the speed is low enough
          this.needleAngle = 0;
        }
        if (normalizedWheelAngle < -8.5) { // now its safe to reset rotationChange
          this.log('Reset rotationState after relaxed release!');
          this.rotationState = null;
          this.tensionReleaseInProgress = false;
        }
        return this.needleAngle;
      }

      if (!this.tensionReleaseInProgress) { // init tension release animation
        this.log('TensionReleaseOn', normalizedWheelAngle, rotationSpeed);
        this.tensionReleaseNeedleStart = this.needleAngle;
        this.tensionReleaseInProgress = true;
        this.tensionReleaseStart = new Date().getTime();
      }

      const elapsedTime = new Date().getTime() - this.tensionReleaseStart;
      const tensionReleaseDuration = Math.max(this.TENSION_RELEASE_DURATION, Math.min(rotationSpeed / 4, 1) * this.TENSION_RELEASE_DURATION_SLOW);
      const progress = elapsedTime / tensionReleaseDuration;

      if (progress <= 1) {
        this.needleAngle = (animationSign * -1) * (this.easeOutElastic(progress) - 1) * this.tensionReleaseNeedleStart;
      } else {
        this.log('Tension Release Animation is finished');
        this.rotationState = null;
        if (rotationSpeed < this.TENSION_RESET_THRESHOLD) this.needleAngle = 0;
        this.tensionReleaseInProgress = false;
      }
    }
    return this.needleAngle;
  }

  // calculates the distance between the "default" position to the current position of the hook
  // not including the radius of the hook!
  private calcDeviationOfHook(wheelAngle: number) {
    // let sign = Math.sign(this.directionOfRotation);
    const sign = 1;
    // TODO: the distance between the center of the wheel is not 475, only when the wheel angle is 0
    // calculate vertical distance from hook to middle: cos(wheelAngle) * height
    const height = Math.cos(this.degreeToRadian(wheelAngle)) * 475;

    // calculates the additional angle that is needed to match the outside of the hook circle + needle thickness
    // atan( (radius + thickness) / height))
    const additionalAngle = this.radianToDegree(Math.atan((this.HOOK_RADIUS + this.NEEDLE_THICKNESS) / height)) * sign;

    // finally calculate the distance from 'default' position of the hook, to the needle
    return Math.abs(Math.sin(this.degreeToRadian(wheelAngle + additionalAngle)) * height) + Math.sin(this.degreeToRadian(wheelAngle + additionalAngle)) * this.HOOK_RADIUS * sign;
  }


  private easeOutElastic(x) {
    // https://easings.net/#easeOutElastic
    const c4 = (2 * Math.PI) / 3;
    return x === 0 ? 0 : (x === 1 ? 1 : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1);
  }

  private normalizeWheelAngle(angle: number) {
    return Math.abs(angle + 22.5) % 45 - 22.5;
  }

  private radianToDegree(radian: number): number {
    if (radian === 0) return 0;
    return radian * 180 / Math.PI;
  }

  private degreeToRadian(degree: number): number {
    if (degree === 0) return 0;
    return degree * Math.PI / 180;
  }

  private log(arg1, arg2 = null, arg3 = null) {
    if (!this.logEnabled) return;

    if (arg3 != null) console.log(arg1, arg2, arg3);
    else if (arg2 != null) console.log(arg1, arg2);
    else console.log(arg1);
  }
}
