import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IpadPreviewContainerComponent} from './ipad-preview-container.component';


@NgModule({
  declarations: [IpadPreviewContainerComponent],
  exports: [IpadPreviewContainerComponent],
  imports: [
    CommonModule
  ]
})
export class IpadPreviewContainerModule {
}
