<headcontent>
  <h2>Welche Art von Kampagne möchtest du versenden?</h2>
</headcontent>

<table class="table w-100 campaign-table">
  <tr class="d-flex flex-column align-items-center d-md-table-row" (click)="createDeal()">
    <td>
      <h5>
        Angebots-Kampagne
      </h5>
      <span>Deine Kampagne enthält ein Angebot, welches von deinen Kunden eingelöst werden kann (z.B. 50% Rabatt auf Sneakers)</span>
    </td>
    <td style="vertical-align: middle;">
      <a class="btn btn-secondary pull-right">Wählen</a>
    </td>
  </tr>
  <tr class="d-flex flex-column align-items-center d-md-table-row" (click)="createInformation()">
    <td>
      <h5>
        Nachricht an Kunden ohne Angebot
      </h5>
      <span>Informiere deine Kunden z.B. über neue Produkte, geänderte Öffnungszeiten oder neue Sitzmöglichkeiten</span>
    </td>
    <td style="vertical-align: middle;">
      <a class="btn btn-secondary pull-right">Wählen</a>
    </td>
  </tr>
  <tr class="d-flex flex-column align-items-center d-md-table-row" (click)="createSpecialPointsEvent()" *ngIf="env.features.special_points_events">
    <td>
      <h5>
        Spezial-Punkte Kampagne
      </h5>
      <span>Schaffe Kaufanreize bei deinen Kunden durch Mehrfach- und Extrapunkte bei ihrem Einkauf </span>
    </td>
    <td style="vertical-align: middle;">
      <a class="btn btn-secondary pull-right">Wählen</a>
    </td>
  </tr>
  <tr class="d-flex flex-column align-items-center d-md-table-row">
    <td>
      <h5>
        Google Bewertung Kampagne<span class="badge badge-info badge-new-feature">Neu</span>
      </h5>
      <span>Erinnere deine Kunde daran, dir eine Google Bewertung zu hinterlassen. </span>
      <div>
        <span *ngIf="!hasThirdPartyBusinesses() && this.hasActiveObtainReviewSubscription">
          <i class="fa fa-warning"></i> Für diese Kampagne musst du zunächst deinen Google Account verbinden. Klicke dafür auf <i>Konfigurieren</i>.
        </span>
      </div>
    </td>
    <td style="vertical-align: middle;" *ngIf="hasThirdPartyBusinesses() && this.hasActiveObtainReviewSubscription">
      <button class="btn btn-secondary pull-right" (click)="hasThirdPartyBusinesses() && createObtainReviewEvent()">Wählen</button>
    </td>
    <td style="vertical-align: middle;" *ngIf="!hasThirdPartyBusinesses() || !this.hasActiveObtainReviewSubscription">
      <button class="btn btn-secondary pull-right" [routerLink]="['/third-party-access']">Konfigurieren</button>
    </td>
  </tr>
  <tr class="d-flex flex-column align-items-center d-md-table-row">
    <td>
      <h5>
        Kunden werben Kunden
        <span class="badge badge-info badge-new-feature">Neu</span>
      </h5>
      <span>Vergebe Bonuspunkte für jede erfolgreiche Weiterempfehlung deines Geschäfts</span>
      <div>
        <span *ngIf="!referralFeatureIsConfigured">
          <i class="fa fa-warning"></i> Für diese Kampagne musst du zunächst das Kunden werben Kunden Feature konfigurieren. Klicke dafür auf <i>Konfigurieren</i>.
        </span>
      </div>
    </td>
    <td style="vertical-align: middle" >
      <button class="btn btn-secondary pull-right" *ngIf="!hasActiveReferralSubscription || !referralFeatureIsConfigured" [routerLink]="['/referral-settings']">Konfigurieren</button>
      <button class="btn btn-secondary pull-right" *ngIf="hasActiveReferralSubscription && referralFeatureIsConfigured" (click)="createReferralEventCampaign()">Wählen</button>
    </td>
  </tr>
</table>
