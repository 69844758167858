import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CampaignNewComponent} from './campaign-new/campaign-new.component';
import {HeadcontentModule} from '../../shared/head-content/headcontent.module';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [CampaignNewComponent],
  exports: [CampaignNewComponent],
  imports: [
    CommonModule,
    HeadcontentModule,
    RouterModule
  ]
})
export class CampaignNewModule {
}
