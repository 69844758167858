import {NgModule} from '@angular/core';
import {WheelOfLuckComponent} from './wheel-of-luck/wheel-of-luck.component';
import {SliceComponent} from './slice/slice.component';
import {FormsModule} from '@angular/forms';
import {WheelImageComponent} from './wheel-image/wheel-image.component';
import {CommonModule} from '@angular/common';
import {WheelOfLuckGameComponent} from './wheel-of-luck-game/wheel-of-luck-game.component';
import {WheelOfLuckOverlayComponent} from './wheel-of-luck-overlay/wheel-of-luck-overlay.component';
import {PrizeDescriptionComponent} from './prize-description/prize-description';


@NgModule({
  declarations: [
    WheelOfLuckComponent,
    SliceComponent,
    WheelImageComponent,
    WheelOfLuckGameComponent,
    WheelOfLuckOverlayComponent,
    PrizeDescriptionComponent
  ],
  imports: [
    FormsModule,
    CommonModule
  ],
  exports: [
    WheelOfLuckComponent,
    SliceComponent,
    WheelImageComponent,
    WheelOfLuckGameComponent,
    WheelOfLuckOverlayComponent
  ]
})
export class WheelOfLuckModule {
}
