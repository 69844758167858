import {combineLatest, Observable, ReplaySubject, Subject, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {Merchant} from './merchant';
import {environment} from '../../environments/environment';
import {catchError, concatMap, map, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import * as Sentry from '@sentry/browser';

@Injectable({
  providedIn: 'root'
})
export class MerchantService {
  public MERCHANTS_URL: string = environment.api_base_url + 'merchants';

  currentMerchant: Subject<Merchant> = new ReplaySubject<Merchant>(1);
  currentContext: Subject<any> = new ReplaySubject<any>(1);

  constructor(private http: HttpClient) {
  }

  public setCurrentMerchant(newMerchant: Merchant): void {
    this.currentMerchant.next(newMerchant);
    Sentry.setUser({username: newMerchant.username, id: newMerchant.id});
  }

  public async setCurrentContext(newContext: any): Promise<void> {
    await Promise.resolve().then(() => localStorage.setItem('context', JSON.stringify(newContext)));
    Sentry.setContext('context', newContext);
    this.currentContext.next(newContext);
  }

  fetch(id: number): Observable<Merchant> {
    // console.log('fetch Merchant', id);
    return this.http.get(`${this.MERCHANTS_URL}/${id}`)
      .pipe(
        map(res => {
          // console.log('fetched Merchant', res);
          return new Merchant(res);
        }),
        catchError(err => {
          // console.log('fetch Merchant error', err);
          return this.handleError(err);
        })
      );
  }

  update(id: number, merchant: Merchant) {
    const param: { merchant: Merchant } = {merchant: merchant};
    return this.http.put(`${this.MERCHANTS_URL}/${id}`, param)
      .pipe(
        map(res => new Merchant(res)),
        catchError(this.handleError)
      );
  }

  refreshCurrent() {
    combineLatest([
      this.currentMerchant.pipe(concatMap(cm => this.fetch(cm.id))),
      this.currentContext
    ])
      .pipe(take(1))
      .subscribe(([refreshedMerchant, currentContext]) => {
        this.currentMerchant.next(refreshedMerchant);
        this.setCurrentContext(refreshedMerchant.available_contexts.find(ct => ct.id === currentContext.id));
      });
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(errMsg);
  }


}
