import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CampaignsRoutingModule} from './campaigns-routing.module';
import {CampaignListModule} from './campaign-list/campaign-list.module';
import {CampaignNewModule} from './campaign-new/campaign-new.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CampaignsRoutingModule,
    CampaignListModule,
    CampaignNewModule
  ]
})
export class CampaignsModule {
}
