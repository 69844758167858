import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {WallpaperComponent} from './wallpaper.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {CustomHammerConfig} from '../../../shared/custom-hammer-config';
import {NgxGalleryModule} from '@kolkov/ngx-gallery';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgxGalleryModule
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig }
  ],
  declarations: [WallpaperComponent],
  exports: [WallpaperComponent]
})
export class WallpaperModule {
}
