import {Asset} from '../../assets/asset';
import {Deal} from '../../deals/deal';
import {SpecialPointsEvent} from '../../special-points-events/special-points-event';
import {ObtainReviewEvent} from '../../obtain-review/obtain-review-event';
import {UrlValidators} from '../campaign-editor/url-validators.service';
import {ReferralEvent} from '../../referral/referral-event';
import * as uuid from 'uuid';

export class ContentElement {
  static element_types: Object = {
    heading1: 'Überschrift 1',
    heading2: 'Überschrift 2',
    heading3: 'Überschrift 3',
    text: 'Text',
    asset: 'Bild',
    button: 'Button',
    deal: 'Deal',
    special_points_event: 'Extra-Punkte Event',
    referral_event: 'Kunden werben Kunden'
  };

  id: number;
  local_uuid: string;
  position: number;
  element_type: string;
  _destroy: boolean;

  asset: Asset;
  data: ContentElementData;
  deal: Deal;
  special_points_event: SpecialPointsEvent;
  obtain_review_event: ObtainReviewEvent;
  referral_event: ReferralEvent;

  constructor(values: any = {}) {
    this.id = values.id;
    this.local_uuid = values.local_uuid;
    this.position = values.position;
    this.element_type = values.element_type;
    this._destroy = values._destroy;

    this.asset = values.asset ? new Asset(values.asset) : null;
    this.data = values.data ? new ContentElementData(values.data) : null;
    this.deal = values.deal ? new Deal(values.deal) : null;
    this.special_points_event = values.special_points_event ? new SpecialPointsEvent(values.special_points_event) : null;
    this.obtain_review_event = values.obtain_review_event ? new ObtainReviewEvent(values.obtain_review_event) : null;
    this.referral_event = values.referral_event ? new ReferralEvent(values.referral_event) : null;

    if (!this.id && !this.local_uuid) {
      this.local_uuid = uuid.v4();
    }
  }

  valid(): boolean {
    switch (this.element_type) {
      case 'heading1':
      case 'heading2':
      case 'heading3':
      case 'text':
        return (this.data && this.data.text && this.data.text.length > 0);
      case 'asset':
        return (this.asset && this.asset.id > 0);
      case 'button':
        if (!UrlValidators.IsUrl(this.data.link) || UrlValidators.IsGoogleUrl(this.data.link) || UrlValidators.IsUrlShortener(this.data.link)) {
          return false;
        }
        return (this.data && this.data.text && this.data.text.length > 0 && this.data.link && this.data.link.length > 5);
      case 'deal':
        return this.deal.valid();
      default:
        return true;
    }
  }

  public asRequest(): Partial<ContentElement> | any {
    return {
      id: this.id,
      element_type: this.element_type,
      position: this.position,
      asset_id: this.asset ? this.asset.id : null,
      data: this.data,
      _destroy: this._destroy,
      deal_attributes: this.deal ? this.deal.asRequest() : null,
      special_points_event_attributes: this.special_points_event ? this.special_points_event.asRequest() : null,
    };
  }
}

export class ContentElementData {
  text?: string;
  title?: string;
  link?: string;
  deal_id?: number;
  methodReference?: string;
  size?: number;

  constructor(values: Partial<ContentElementData> = {}) {
    this.text = values.text;
    this.title = values.title;
    this.link = values.link;
    this.deal_id = values.deal_id;
    this.methodReference = values.methodReference;
    this.size = values.size;
  }
}
