import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImageContainerComponent} from './image-container.component';
import {ProgressRingModule} from '../progress-ring/progress-ring.module';


@NgModule({
  declarations: [ImageContainerComponent],
  exports: [ImageContainerComponent],
  imports: [
    CommonModule,
    ProgressRingModule
  ]
})
export class ImageContainerModule {
}
