import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {ReferralEvent} from '../referral/referral-event';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReferralEventService {
  public REFERRAL_EVENT_URL: string = environment.api_base_url + 'referral_event';

  constructor(private http: HttpClient) {
  }

  public fetch(): Observable<ReferralEvent> {
    return this.http.get<{ referral_event: ReferralEvent }>(this.REFERRAL_EVENT_URL, {}).pipe(
      catchError(this.handleError),
      map(response => new ReferralEvent(response.referral_event))
    );
  }

  public create(referralEvent: ReferralEvent): Observable<ReferralEvent> {
    const referral_event = this.prepareAsParam(referralEvent);
    return this.http.post<{ referral_event: ReferralEvent }>(this.REFERRAL_EVENT_URL, {referral_event}).pipe(
      catchError(this.handleError),
      map(response => new ReferralEvent(response.referral_event))
    );
  }

  public update(referralEvent: ReferralEvent): Observable<ReferralEvent> {
    const referral_event = this.prepareAsParam(referralEvent);
    return this.http.put<{ referral_event: ReferralEvent }>(this.REFERRAL_EVENT_URL, {referral_event}).pipe(
      catchError(this.handleError),
      map(response => new ReferralEvent(response.referral_event))
    );
  }

  private prepareAsParam(referralEvent: ReferralEvent): any {
    return {
      id: referralEvent.id,
      blacklisted_business_ids: referralEvent.blacklisted_business_ids,
      inviter_special_points_event_attributes: {
        id: referralEvent.inviter_special_points_event.id,
        extra_points: referralEvent.inviter_special_points_event.extra_points,
        deal_attributes: {
          id: referralEvent.inviter_special_points_event.deal.id,
          title: referralEvent.inviter_special_points_event.deal.title,
          description: referralEvent.inviter_special_points_event.deal.description
        }
      },
      invitee_special_points_event_attributes: {
        id: referralEvent.invitee_special_points_event.id,
        extra_points: referralEvent.invitee_special_points_event.extra_points
      }
    };
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return observableThrowError(errMsg);
  }
}
