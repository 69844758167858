export class MessageStat {
  emails_count: number;
  email_events_count: number;
  email_processed_count: number;
  email_dropped_count: number;
  email_delivered_count: number;
  email_deferred_count: number;
  email_bounce_count: number;
  email_open_count: number;
  email_click_count: number;
  email_spamreport_count: number;
  email_unsubscribe_count: number;
  email_group_unsubscribe_count: number;
  email_group_resubscribe_count: number;
  visiting_users_after_open: {email: number, mobile: number, total: number};
  mobile_messages_count: number;
  messages_successful_count: number;
  messages_read_count: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
